import { StyleSheet } from 'aphrodite';
import { AppTheme } from '../styles/AppTheme';

// Styles to be reused between more than one component
export const CommonTheme = {
  textColor: '#222222',
  subTextColor:'#7D7D7D',
  containerBorderColor: '1px solid #E2EAFC',
  outerPaddingWeb: 80,
  outerPaddingMob: 20
}

export const CommonStyles = StyleSheet.create({
  textfulPageContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    textAlign: 'left',
    margin: 20,
    maxWidth: AppTheme.viewPort,
    paddingBottom: 100,
  },
  textfulPageTitle: {
    fontSize: 36,
    color: CommonTheme.textColor,
    fontWeight: 600,
    textAlign: 'center'
  },
  textfulPageSubtitle: {
    fontSize: 25,
    color: CommonTheme.textColor,
    fontWeight: 500
  },
  textfulPagePara: {
    fontSize: 20,
    color: CommonTheme.textColor,
  },
  logoImage: {
    height: 37
  },
  linkTextColor: {
    color: 'black'
  },
  buttonStyle: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    minWidth: 150,
    width: '100%',
    padding: 20,
    height: 75
  },
  buttonTextStyle: {
    fontSize: 20,
    fontWeight: 500,
    color: AppTheme.buttonTextColor,
    textTransform: 'none',
  },
  progressIconSizeStyle: {
    size: 30
  },
  pagePadding: {
    paddingLeft: CommonTheme.outerPaddingWeb,
    paddingRight: CommonTheme.outerPaddingWeb,
    '@media(max-width: 768px)': {
      paddingLeft: CommonTheme.outerPaddingMob,
      paddingRight: CommonTheme.outerPaddingMob,
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      paddingLeft: CommonTheme.outerPaddingMob,
      paddingRight: CommonTheme.outerPaddingMob,
    },
  },
  textfulPageContentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  outerContainer: {
    flex: 1
  },
  mainContainer: {
    width: '100%',
    padding: '50px 80px'
  },
  text: {
    color: CommonTheme.subTextColor,
    fontSize: 26,
    margin: 0,
    marginTop: 24
  },
  errImageOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  errImageContainer: {
    marginTop: 200,
    width: 250 / 2,
    height: 162 / 2
  },
  statContainer: {
    maxWidth: AppTheme.viewPort,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'left'
  }
});
