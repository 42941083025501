import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppTheme } from '../../../styles/AppTheme'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CommonTheme } from '../../../styles/Styles';

class TableCard extends Component {
  constructor(props) {
    super(props);
    this.defaultErrMsg = 'An error occured.'
    this.state = {
      inProcess: false
    };
  }


  render() {
    const {headers, rows} = this.props.item.data
    if(!headers || headers.length === 0 || !rows || rows.length === 0 ) {
      return ''
    }

    return (
      <div className={css(Styles.outerContainer)}>
        <div className={css(Styles.paperTitle)} elevation={0}>
          <p className={css(Styles.paragraphMargin)}>{this.props.item.title}</p>
        </div>
        <div>
          {this.state.inProcess &&
            <div className={css(Styles.loader)}>
              <CircularProgress size={48} className={css(Styles.progress)} />
            </div>
          }
        </div>
        <div className={css(Styles.tableContainer)}>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index}  className={css(Styles.cellHead)}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex} className={css(Styles.cellBody)}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

export default TableCard;

const Styles = StyleSheet.create({
  outerContainer: {
    border: '1px solid ' + AppTheme.borderColor,
    padding: 20,
    borderRadius: AppTheme.borderRadius,
    flex: 1,
    '@media (max-width: 767px)': {
      padding: '20px 10px',
      borderRadius: 8,
    },
  },
  tableContainer: {
    position: 'relative',
  },
  paperFooter: {
    marginBottom: 0,
    display: 'flex',
    verticalAlign: 'top',
    justifyContent: 'flex-end'
  },
  paperTitle: {
    fontWeight: 'bold',
    color: AppTheme.white,
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center'
  },
  subText: {
    display: 'inline-block',
    fontSize: 24,
    fontWeight: 'bold',
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'default',
    '@media(max-width: 768px)': {
      fontSize: 12
    }
  },
  arrowUp: {
    color: 'green'
  },
  arrowDown: {
    color: 'red'
  },
  videoContainerTile: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paragraphMargin: {
    margin: 0,
    flex: 1,
    fontSize: 20,
    fontWeight: 500,
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
  progress: {
    color: AppTheme.white
  },
  loader: {
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },


  cellHead: {
    backgroundColor: 'white',
    color: '#9B9B9B',
    height: 38,
    fontSize: 16,
    fontWeight: 'bold',
    padding: '8px 8px 8px 8px',
    textAlign: 'center',
  },
  cellBody: {
    backgroundColor: 'white',
    color: CommonTheme.textColor,
    // minHeight: 72,
    borderBottom: '1px solid ' + AppTheme.borderColor,
    fontSize: 16,
    textAlign: 'center',
    padding: '16px 5px',
    verticalAlign: 'middle',
    wordBreak: 'break-all'
  },
});
