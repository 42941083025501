import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import * as FileSaver from 'file-saver';

import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles } from '../../../styles/AppTheme';

function copyTextToClipboard(text) {
  const clipboardItem = new window.ClipboardItem({
    'text/plain': new window.Blob(
      [text],
      { type: 'text/plain' }
    ),
  });

  window.navigator.clipboard.write([clipboardItem]);
}

function downloadJSON(jsonStr, orgId) {
  const data = new Blob([jsonStr], {
    type: 'application/json;charset=UTF-8'
  });
  FileSaver.saveAs(data, `gtm-${orgId}.json`);
}

export class EmbedTags extends BaseEntityPage {
  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = Helper.getString('embed-tags')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('embed-tags') },
    ]
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['miscs', 'get-embed-tag-scripts'], queryParams: { orgId: this.orgId } },
    ]
    this.apiData = {
      orgId: this.orgId
    }
    this.pageKey = this.pageTitle
    this.tableHeadersMap = {
      Tag: (current) => <div className={css(Styles.script)}>{current.script}</div>,
      Type: (current) => current.type,
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.headerActions = []
    this.noAdd = true
  }

  onFetchSuccess(results) {
    const items = []
    Object.keys(results[0]).forEach((k, index) => {
      if (k.toLowerCase().includes('version')) return
      items.push({
        id: `${index + 1}`,
        type: k.toUpperCase(),
        script: results[0][k],
      })
    })

    this.setState({
      items,
    })
  }

  onActions = (current) => {
    const actions = []
    if (current.type.includes('JSON')) {
      actions.push(
        <Tooltip title='Download' placement='top-start' key={current.id + '-download'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => {
              downloadJSON(current.script, this.orgId)
            }}>
            download
            </i>
          </span>
        </Tooltip>
      )
    } else {
      actions.push(
        <Tooltip title='Copy' placement='top-start' key={current.id + '-copy'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => {
              copyTextToClipboard(current.script)
            }}>
            copy
            </i>
          </span>
        </Tooltip>
      )
    }

    return actions
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }
}

const Styles = StyleSheet.create({
  script: {
    fontSize: 12,
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})