/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
export const AppConfigGen = {
  "ADMIN_DRAWER_MENU_ENABLED": true,
  "APPAK": "web-dab6e2f1-d327-4040-9f44-f09cab189c3c",
  "CLOUD_REGION": "ap-south-1",
  // "COGNITO_USER_POOL": "ap-south-1_PisbASvKw",
  // "COGNITO_USER_POOL_CLIENT_ID": "3v7rq5e8kguh86tevmhkgj12f8",
  "COGNITO_USER_POOL": "ap-south-1_rsyyK8C7m",
  "COGNITO_USER_POOL_CLIENT_ID": "1smg28n5fekccff493kcqosnk7",
  "COMPANY_NAME": "Azympto Technologies Private Limited",
  "DEFAULT_COUNTRY": "IN",
  "DEFAULT_CURRENCY": "INR",
  "DEFAULT_LANG": "en",
  "DEFAULT_ZONEINFO": "Asia/Kolkata",
  "DESCR": "eCommerce with speed, scale, seo, flexibility & delightful user experience",
  "ECOM_MAX_VARIANT_OPTIONS": 1,
  "ENDPOINT": "https://8jqq56bp0b.execute-api.ap-south-1.amazonaws.com/Prod",
  // "ENDPOINT_AUTH_MICROSERVICE": "https://ku1559lsbj.execute-api.ap-south-1.amazonaws.com/Prod",
  "NAME": "Slixta Commerce",
  "ORGSU_ACCESS_ALLOWED": true,
  "PASSWORD_POLICY": "Password should be minumum 6 characters with numbers and letters.",
  "SUPPORT_EMAIL": "contact@slixta.com",
  "TINT_COLOUR": "#0246E5",
  "TITLE": "Supercharge your Digital | Slixta Console",
  "USER_PRIMARY": "email",
  "WEBAPP_LINK": "https://manage.slixta.com"
}
