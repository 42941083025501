import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../helpers/Helper';
import MenuItem from '@material-ui/core/MenuItem'
import { Controller } from '../Controller';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import { AppConfig } from '../../AppConfig';
import { CustomOrgSwitcherStyles } from '../../styles/CustomStyles';
import { AppButton } from './AppButton';
import { AppTheme } from '../../styles/AppTheme';

export class OrgSwitcher extends Component {
  constructor(props) {
    super(props)
  }

  renderOrgList = () => {
    const options = Controller.get().userMgr().getAppUser()
    const fieldParams = {
      fullWidth: true,
      value: this.props.selectedOrgId,
      name: 'selectedOrgId'
    };
    return (
      <FormControl fullWidth margin='normal' >
        <p className={css(Styles.label)}>{Helper.getString('selectOrg')}</p>
        <Select
          {...fieldParams}
          onChange={(e) => this.props.onOrgChange(e)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            getContentAnchorEl: null,
            className: css(Styles.menuProps),
            classes: {
              paper: css(Styles.paper)
            }
          }}
          input={<Input disableUnderline={true} />}
          inputProps={{
            classes: {
              root: css(Styles.textInputStyles)
            }
          }}
          className={css(Styles.formControl)}>
          {options && options.orgs && options.orgs.map(item => {
            return (
              <MenuItem value={item.orgId} key={item.id} className={css(Styles.selectMenuItemContainer)}>
                <p className={css(Styles.menuItem)}>{item.title || item.orgId}</p>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  render() {
    return (
      <Dialog
        open={this.props.orgSwitcherDialog}
        fullWidth={true}
        classes={{
          root: css(Styles.customRoot),
          paper: css(Styles.customPaper),
        }}
      >
        <div className={css(Styles.modalHeader)}>
          <div className={css(Styles.modalTitle)}>{Helper.getString('orgSwitcher')}</div>
          <button className={css(Styles.modalCloseButton)} onClick={() => this.props.orgSwitcherDialogClose()} disabled={this.props.inProcess}>
            <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
              <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
            </svg>
          </button>
        </div>
        <div className={css(Styles.modalContent)}>
          {this.renderOrgList()}
          <div className={css(Styles.actions)}>
            <AppButton
              onClick={() => this.props.doActionSave()}
              buttonText={'Select'}
              inProcess={this.props.inProcess}
              disabled={this.props.inProcess}
            />
          </div>
        </div>
      </Dialog>
    )
  }
}


const Styles = AppConfig.CUSTOM_STYLE ? CustomOrgSwitcherStyles : StyleSheet.create({
  customRoot: {
    zIndex: 13000
  },
  customPaper: {
    borderRadius: AppTheme.borderRadius,
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  modalHeader: {
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalTitle: {
    color: '#222222',
    fontSize: 26,
    fontWeight: 600
  },
  modalContent: {
    paddingLeft: 30,
    paddingRight: 30,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalCloseButton: {
    position: 'absolute',
    top: 23,
    right: 30,
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    border: 'none'
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: 8
  },
  label: {
    fontSize: 14,
    color: '#7D7D7D',
    margin: 0,
    marginBottom: 8
  },
  menuProps: {
    zIndex: 130000,
    marginTop: 5
  },
  paper: {
    zIndex: 130000,
    fontSize: 16,
    color: '#222222',
    borderRadius: AppTheme.borderRadius,
    padding: '0px 10px'
  },
  formControl: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16,
    margin: 0,
    borderRadius: AppTheme.borderRadius,
    ':focus': {
      backgroundColor: '#ffffff'
    }
  },
  selectMenuItemContainer: {
    padding: '5px 10xp',
    borderRadius: AppTheme.borderRadius,
  },
  menuItem: {
    fontSize: 16,
    color: '#222222',
    margin: '6px 0px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 30
  },
  saveButton: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    height: 54,
    minWidth: 150,
    padding: 13,
    fontSize: 16,
    textTransform: 'capitalize',
    color: AppTheme.secondaryBg
  },
  marginLeft: {
    color: 'white'
  },
})
