import React, { Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppStyles } from '../../../styles/AppTheme';
import { AppConfig } from '../../../AppConfig';
import { CustomCbDefsStyles } from '../../../styles/CustomStyles';
import { BaseEntityPageSlixta } from '../../../components/BaseEntityPageSlixta';
import { Controller } from '../../../common/Controller';
import { SearchBar } from '../../../common/components/SearchBar';

function extractAttrs(crmContactAttrs, attrKey) {
  return ((crmContactAttrs && crmContactAttrs[attrKey]) || '').split(',').map(t => t.trim()).filter(a => !!a)
}
function loadAttrOptions(fieldDef, crmContactAttrs, attrKey) {
  const extractedAttrs = extractAttrs(crmContactAttrs, attrKey)
  const field = fieldDef.steps[0].fields.find(f => f.key === attrKey)
  if (field) field.options = extractedAttrs.map(t => ({key: t, label: t}))
}
export class CrmContacts extends BaseEntityPageSlixta {

  constructor(props) {
    super(props);
    // this.noAdd = true
    this.emptyText = 'Type Email/Phone to Search'
    this.pageKey = 'crm-contacts-legacy'
    this.pageTitle = Helper.getString('crm-contacts-legacy')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.apiData = {
      orgId: this.orgId
    }
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.attribsGroupId = 'settingscrmcontactattrs' + this.orgId
    this.toFetch = [
      { method: 'GET', paths: ['miscs', 'crm-contacts'], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['items', `${this.attribsGroupId}-all`], queryParams: { groupId: this.attribsGroupId } },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Joined: (current) => { return Helper.formatTimestamp(current.createdTs) },
      Info: (current) => {
        const name = Helper.formatName(current)
        return (
          <div>
            {name &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Name: {name}</span>
                <br />
              </Fragment>
            }
            {current.email &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Email: {current.email}</span>
                <br />
              </Fragment>
            }
            {current.phone &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Phone: {current.phone}</span>
                <br />
              </Fragment>
            }
            {current.tags && current.tags.length > 0 &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Tags: {current.tags.join(', ')}</span>
                <br />
              </Fragment>
            }
            {current.subscriptions && current.subscriptions.length > 0 &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Subscriptions: {current.subscriptions.join(', ')}</span>
                <br />
              </Fragment>
            }
            <span className={css(Styles.itemValue, Styles.line2)}>ID: {current.id}</span>
            <br />
            {current.displayLabels &&
              <span className={css(Styles.itemValue, Styles.line3)}>{current.displayLabels.join(',')}</span>
            }
          </div>
        )
      },
      // Profile: (current) => { return Helper.formatName(current) }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
  }

  onFetchSuccess(results, options) {
    this.fieldDef = this.formDefinition = results[0].fieldDef
    this.crmContactAttrs = results[1]
    loadAttrOptions(this.fieldDef, this.crmContactAttrs, 'tags')
    const { fetchNext } = options || {}
    this.transform(results[0]);
    this.handleNextpage(results[0])
    this.setState({
      items: fetchNext ? this.state.items.concat(results[0].items) : results[0].items
    })
  }

  transform(result) {
    result.items.forEach(i => {
      const displayLabels = [];
      if (i.cognitoVerified) displayLabels.push('OTP VERIFIED');
      if (i.createdOnFormSubmission) displayLabels.push('CREATED BY FORM');
      if (i.createdByImport) displayLabels.push('CREATED BY IMPORT');
      if (displayLabels.length <= 0 && (i.emailAdminVerified || i.phoneAdminVerified)) {
        displayLabels.push('CREATED ON CONSOLE');
      }
      if (displayLabels.length > 0) {
        i.displayLabels = displayLabels;
      }
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  renderAboveTable() {
    return (
      <SearchBar
        hint={'Type Email or Phone'}
        onSearch={this.onSearch.bind(this)}
        onList={this.onList.bind(this)}
        searchText={this.state.searchText}>
      </SearchBar>
    )
  }

  onSearch = (searchText) => {
    console.log('onSearch:', searchText)
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      searchText: searchText,
    })
    const queryParams = { orgId: this.orgId }
    if(searchText.includes('@')) {
      queryParams.email = searchText
    } else {
      queryParams.phone = searchText
    }
    ApiHelper.call({
      method: 'get', paths: ['miscs', 'crm-contacts'],
      queryParams
    }, (err, result) => {
      if (err) {
        console.log('Search Error:', err)
      }
      this.transform(result)
      this.setState({
        items: (result && result.items) || [],
        fetchState: ApiHelper.State.READY,
        nextPage: ''
      })
    })
  }

  onList = () => {
    this.fetchItems()
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['crmcontacts'], { orgId: this.orgId }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['crmcontacts', current.id], { orgId: this.orgId }),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 14
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
  line3: {
    fontSize: 12,
    // color: 'charcoal'
  }
})