import React, { Fragment, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';

import { AppStyles, AppTheme } from '../styles/AppTheme';
import { CustomImageCollectionStyles } from '../styles/CustomStyles';
import { AppConfig } from '../AppConfig';
import { Helper } from '../common/helpers/Helper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { TextField } from '@material-ui/core';
import { UiHelper } from '../common/helpers/UiHelper';

export const ImageCollection = (props) => {
  const [state, setState] = useState({ items: [], fetchState: ApiHelper.State.LOADING })
  async function fetchData() {
    try {
      const response = await ApiHelper.callAwait({
        method: 'GET',
        paths: ['assets'],
        queryParams: { orgId: props.orgId, limit: 1000 }
      })
      setState({
        fetchState: ApiHelper.State.READY,
        items: response.items,
        itemsOrginal: response.items
      })
    } catch (e) {
      setState({
        fetchState: ApiHelper.State.ERROR,
        errMsg: Helper.getErrorMsg(e),
        items: [],
        itemsOrginal: []
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onCloseModal = () => {
    props.onClosed();
    setState({
      ...state,
      items: state.itemsOrginal,
    })
  }

  const selectedTile = (item) => {
    props.onUploadComplete({
      link: item.image,
      fields: {
        key: item.imageName
      }
    }, { fromAssetPicker: true });
    props.onClosed();
    setState({
      ...state,
      items: state.itemsOrginal,
    })
  }

  const handleInputChange = (event) => {
    const userInput = event.target.value;
    const items = state.itemsOrginal.filter((item) => {
      return (item.title.toLowerCase()).includes(userInput.toLowerCase())
    })
    setState({
      ...state,
      items
    })
  }

  const dialogHeading = Helper.getString('assetsPickerTitle')
  return (
    <Dialog
      open={props.isOpen}
      aria-labelledby={Helper.getString('assetsPickerTitle')}
      fullWidth={true}
      classes={{
        root: css(Styles.customRoot),
        paper: css(Styles.customPaper),
      }}
      maxWidth='md'
    >
      <div className={css([Styles.modalHeader, AppStyles.modalHeader])}>
        <div className={css(Styles.modalHeaderContent)}>
          <div className={css(Styles.modalTitle)}>{dialogHeading}</div>
        </div>
        <TextField
          variant={'standard'}
          placeholder='Search'
          className={css(Styles.formControl)}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: css(Styles.textInputStyles),
              input: css(Styles.formInputStyle),
            }
          }}
          autoComplete='off'
          onChange={handleInputChange} />
        <button className={css(Styles.modalCloseButton)} onClick={() => onCloseModal()}>
          <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
            <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
          </svg>
        </button>
      </div>

      <div className={css(Styles.modalContent)}>
        {state.fetchState === ApiHelper.State.ERROR && UiHelper.errorView({ state })}
        {state.fetchState === ApiHelper.State.LOADING && <div className={css(Styles.emptyContainer)}>{UiHelper.componentLoadingView()}</div>}
        {state.fetchState === ApiHelper.State.READY &&
          <Fragment>
            {state.items.length === 0 ? UiHelper.emptyView(Helper.getString('assetsEmpty')) :
              <Grid container spacing={3}>
                {state.items && state.items.map((item, index) => {
                  return (
                    <Grid key={item.id} lg={2} xs={12} sm={6} md={2} item onClick={() => selectedTile(item)} style={{ cursor: 'pointer' }}>
                      <div className={css(Styles.productTileContainer)}>
                        <div className={css(Styles.imageOuterContainer)}>
                          <div className={css(Styles.imageContainer)}>
                            <img
                              alt={Helper.getString('assetImageAlt')}
                              width={512} height={512}
                              src={item.image}
                              className={css(Styles.img)}
                            />
                          </div>
                        </div>

                        <div className={css(Styles.titlesContainer)}>
                          {item.title &&
                            <p className={css(Styles.itemTitle)}>{item.title}</p>
                          }
                        </div>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
            }
          </Fragment>
        }
      </div>
    </Dialog >
  );
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomImageCollectionStyles : StyleSheet.create({
  modalHeader: {
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14,
    display: 'flex',
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalHeaderContent: {
    display: 'flex',
    flex: 1,
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  modalTitle: {
    color: '#222222',
    fontSize: 26,
    fontWeight: 600,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  modalContent: {
    display: 'flex',
    overflowY: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalCloseButton: {
    position: 'absolute',
    top: 33,
    right: 30,
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    '@media (max-width: 767px)': {
      top: 23,
      right: 15,
    },
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius / 2
  },
  customRoot: {
    zIndex: 13000
  },
  customPaper: {
    borderRadius: AppTheme.borderRadius,
    height: '100%',
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  title: {
    color: '#222222',
    fontSize: 22,
    fontWeight: 600,
    margin: 0,
    padding: '15px 15px 0px'
  },
  paper: {
    zIndex: 130000,
    fontSize: 16,
    color: '#222222',
    borderRadius: AppTheme.borderRadius,
    padding: '0px 10px'
  },
  formControl: {
    marginRight: 40,
    borderRadius: AppTheme.borderRadius,
    border: '1px solid ' + AppTheme.borderColor,
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16,
    margin: 0,
    borderRadius: AppTheme.borderRadius,
    ':focus': {
      backgroundColor: '#ffffff'
    }
  },
  imageOuterContainer: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    display: 'block',
    overflow: 'hidden',
    inset: '0px',
    borderRadius: 8,
    boxSizing: 'border-box',
    margin: '0px',
    border: '1px solid ' + AppTheme.borderColor
  },
  img: {
    inset: '0px',
    position: 'absolute',
    boxSizing: 'border-box',
    padding: '0px',
    border: 'none',
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
    objectFit: 'containe'
  },
  titlesContainer: {
    flex: 1,
    display: 'grid'
  },
  itemTitle: {
    margin: 0,
    color: '#222222',
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 5,
    wordBreak: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    paddingTop: 0,
  }
})
