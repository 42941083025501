/* eslint-disable camelcase */
import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { Controller } from '../../../common/Controller';

export class CrmEmailTemplates extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('crm-email-templates')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.staffUserId = Controller.get().getUserId()
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('crm-email-templates') },
    ]
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['crmemailtemplates'], queryParams: { orgId: this.orgId, orgIdUserId: `${this.orgId}-${this.staffUserId}`, pb: 'fieldDef', includeShared: 'true', limit: 100 } },
    ]
    this.apiData = {
      orgId: this.orgId,
      userId: this.staffUserId,
    }
    this.pageKey = 'crm-email-templates'
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Template: (current) => {
        return (
          <div>
            <span className={css(Styles.line1)}>{current.title}</span>
            <br/>
            <span className={css(Styles.line2)}>{`Sub: ${current.subject}`}</span>
            <br/>
            <span className={css(Styles.line2)} dangerouslySetInnerHTML={{ __html: Helper.renderHtmlText(current.bodyRichText) }}></span>
          </div>
        )
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.uploadLocation = `${this.orgId}/__FILENAME__`
    this.uploadMs = AppConfig.CDP_MS.ENDPOINT
  }

  onFetchSuccess(results) {
    this.crmEmailTemplates = results[0].items
    this.fieldDef = this.formDefinition = results[0].fieldDef
    this.setState({
      items: this.crmEmailTemplates,
    })
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiData: { orgId: this.orgId },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['crmemailtemplates'], queryParams: { orgId: this.orgId } }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { orgId: this.orgId },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['crmemailtemplates', current.id], queryParams: { orgId: this.orgId } }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiData: { orgId: this.orgId },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['crmemailtemplates', current.id], queryParams: { orgId: this.orgId } }),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }
}

const Styles = StyleSheet.create({
  line1: {
    fontSize: 16,
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
})