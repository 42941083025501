import { CampaignsBase } from './CampaignsBase';
import { Helper } from '../../../common/helpers/Helper';

export class Broadcasts extends CampaignsBase {
  constructor(props, options) {
    super(props, {
      ...options,
      pageTitle: Helper.getString('broadcasts'),
      campaignType: 'BROADCAST',
    });
  }
}
