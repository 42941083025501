import { ApiHelper } from '../helpers/ApiHelper';
import { Helper } from './Helper';

export class UploadHelper {
  static fetchUploadUrl(pickerResult, options, callback) {
    const jsonBody = options
    if (pickerResult && pickerResult.type) {
      // This was happening in iOS
      jsonBody.contentType = pickerResult.type
    } else if (options.ext) {
      jsonBody.ext = options.ext;
    } else if (pickerResult) {
      jsonBody.ext = pickerResult.uri.split('.').pop();
    }
    ApiHelper.call(
      {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath2({ ms: options.ms, paths: ['miscs', 'upload-signed-form'], queryParams: {} }),
        jsonBody: jsonBody
      },
      callback);
  }

  static async fetchUploadUrlAwait(pickerResult, options) {
    return await Helper.promisify(UploadHelper.fetchUploadUrl)(pickerResult, options)
  }

  static uploadFormData(options, callback) {
    const formData = UploadHelper._makeSignedFormData(
      options.pickerResult, options.signedUploadInfo)

    fetch(options.signedUploadInfo.formUrl, {
      method: 'POST',
      body: formData
    }).then(res => {
      console.log('uploadFormData::fetch res:', res)
      callback(null, {})
    }).catch((err) => {
      console.error('uploadFormData::fetch error:', err);
      callback(err)
    });
  }

  static async uploadFormDataAwait(options) {
    return await Helper.promisify(UploadHelper.uploadFormData)(options)
  }

  static uploadFormDataWithProgress(options) {
    const formData = UploadHelper._makeSignedFormData(
      options.pickerResult, options.signedUploadInfo)

    UploadHelper._fetchWithProgress(options.signedUploadInfo.formUrl,
      {
        method: 'POST',
        body: formData
      },
      options.onEvent
    )
  }

  static _makeSignedFormData(filePickerResult, signedUploadInfo) {
    const formData = new FormData();
    for (const f in signedUploadInfo.fields) {
      formData.append(f, signedUploadInfo.fields[f]);
    }
    formData.append('file', filePickerResult);
    return formData
  }

  static _fetchWithProgress = (url, opts = {}, onEvent) => {
    //https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/upload
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    for (const k in opts.headers || {})
      xhr.setRequestHeader(k, opts.headers[k]);
    const onXhrEvent = function (event) {
      if (event.type === 'progress') {
        let percent = 0
        if (event.loaded && event.total) {
          percent = event.loaded / event.total
        }
        onEvent(event.type, percent)
      } else {
        onEvent(event.type)
      }
    }
    xhr.onload = onXhrEvent;
    xhr.onerror = onXhrEvent;
    xhr.onabort = onXhrEvent;
    if (xhr.upload && onEvent) {
      xhr.upload.onprogress = onXhrEvent;
    }

    xhr.send(opts.body);
  }
}