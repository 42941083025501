import React, { Component } from 'react';

import { ApiHelper } from '../../helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { SearchBar } from '../SearchBar';
import { UiHelper } from '../../helpers/UiHelper';
import { CustomProductsStyles } from '../../../styles/CustomStyles';

export class Products extends BaseEntityPage {
  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('products')
    this.pageKey = 'products'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['products'], queryParams: { orgId: this.orgId, pb: 'fieldDef', limit: 20 } },
      // { method: 'GET', endPoint: ApiHelper.makeUrlPath(['brands'], { orgId: this.orgId }) },
      // { method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs', 'cats'], { orgId: this.orgId }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return <span className={css(Styles.valueText)}>{current.__index + 1}</span> },
      'Slug / ID': (current) => {
        return UiHelper.itemDetails(current)
      },
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.title}</span>
            <br />
            <span className={css(Styles.spanValue)}>{current.createdTs && Helper.formatTimestamp(current.createdTs)}</span>
          </div>
        )
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
  }

  onFetchSuccess(results, options) {
    const { fetchNext } = options || {}
    const prodData = results[0]
    this.fieldDef = prodData.fieldDef
    this.formDefinition = prodData.fieldDef
    // UiHelper.populateOptions(this.formDefinition, results[1], 'brandId')

    // const leafCats = Helper.processCats(results[2].items)
    // UiHelper.populateOptions(this.formDefinition, { items: leafCats}, 'catIds')

    this.handleNextpage(results[0])
    this.setState({
      items: fetchNext ? this.state.items.concat(results[0].items) : results[0].items
    })
  }

  renderAboveTable() {
    return (
      <SearchBar
        onSearch={this.onSearch.bind(this)}
        onList={this.onList.bind(this)}
        searchText={this.state.searchText}>
      </SearchBar>
    )
  }

  onSearch = (searchText) => {
    console.log('onSearch:', searchText)
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      searchText: searchText,
    })
    ApiHelper.call({
      method: 'get', paths: ['miscs', 'search'],
      queryParams: { orgId: this.orgId, query: searchText, allvisible: true, facets: JSON.stringify([]) }
    }, (err, result) => {
      if (err) {
        console.log('Search Error', err)
      }
      this.setState({
        items: result.items,
        fetchState: ApiHelper.State.READY,
        nextPage: ''
      })
    })
  }

  onList = () => {
    this.fetchItems()
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['products'], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['products', current.id], {}),
    });
  }

  openModalForAdd(fieldDef) {
    const fields = []
    this.fieldDef.steps[0].fields.forEach(i => { if (i.key === 'title') fields.push(i) })
    super.openModalForAdd({ steps: [{ fields }] })
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonOpenProduct(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomProductsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})
