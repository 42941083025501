import React from 'react';
import { Grid, Paper, Card, CardContent } from '@material-ui/core';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';

export const StatCard = function(props) {
  const { title, text, subText, cardIndex, arrow, cardFooter, xs, sm, linkTo, padding } = props;
  const cardColor = 'c' + String((cardIndex % 4) + 1)

  const cardContentStyles = css(Styles.cardcontent, padding === 'small' ? Styles.lesshpadding : '')
  const card =
    <Card className={css(Styles.card, Styles[cardColor])} elevation={0}>
      <CardContent className={cardContentStyles}>
        <div className={css(Styles.text)}>{text}</div>
        <div className={css(Styles.subText)}>&nbsp;&nbsp;{subText}</div>
      </CardContent>
    </Card>

  return (
    <Grid item xs={xs || 6} sm={sm || 3} className={css(Styles.container)}>
      <div className={css(Styles.paperTitle)} elevation={0}>
        {title}
      </div>

      {linkTo && (
        <Link className={css(Styles.statLink)} to={linkTo}>
          {card}
        </Link>
      )}

      {!linkTo && (
        card
      )}

      {cardFooter && (
        <div className={css(Styles.paperFooter)} elevation={0}>
          {arrow === 'down' && (
            <i class={'material-icons-outlined ' + css(Styles.arrow, Styles.arrowDown)}>keyboard_arrow_down</i>
          )}
          {arrow === 'up' && <i class={'material-icons-outlined ' + css(Styles.arrow, Styles.arrowUp)}>keyboard_arrow_up</i>}
          {cardFooter}
        </div>
      )}
    </Grid>
  );
};

const Styles = StyleSheet.create({
  container: {
    color: '#474747',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (max-width: 767px)': {
      alignItems: 'center',
    },
  },
  paperFooter: {
    margin: '10px',
    display: 'flex',
    verticalAlign: 'top'
  },
  paperTitle: {
    marginTop: '10px',
    fontWeight: 'bold'
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    '@media (max-width: 767px)': {
      height: '100%'
    },
  },
  cardcontent: {
    padding: '32px 24px'
  },
  lesshpadding: {
    padding: '12px 24px'
  },
  text: {
    color: 'black',
    display: 'inline-block',
    fontSize: 36,
    lineHeight: '48px',
    fontWeight: 'bold'
  },
  subText: {
    color: 'black',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'bold',
  },
  arrowUp: {
    color: 'green'
  },
  arrowDown: {
    color: 'red'
  },
  c1: {
    background: '#FFD7BB'
  },
  c2: {
    background: '#FFE1EE'
  },
  c3: {
    background: '#A9E3FF'
  },
  c4: {
    background: '#3BDE86'
  },
  statLink: {
    textDecoration: 'none'
  }
});
