import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { UiHelper } from '../../common/helpers/UiHelper';
import { AppStyles } from '../../styles/AppTheme';
import { AppConfig } from '../../AppConfig';
import { CustomCbDefsStyles } from '../../styles/CustomStyles';
import { BaseEntityPageSlixta } from '../../components/BaseEntityPageSlixta';

export class EmailPages extends BaseEntityPageSlixta {

  constructor(props) {
    super(props, { assetPicker: true });
    this.pageTitle = Helper.getString('emailpages')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['emailpages'], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { method: 'GET', paths: ['emailcbdefs'], queryParams: { orgId: this.orgId } },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Info: (current) => {
        const v = {...current}
        return <div>
          <span className={css(Styles.spanValue)}>{current.title}</span>
          <br/>
          <span className={css(Styles.line2)}>{Helper.formatTimestamp(current.createdTs)}</span>
          <br/>
        </div>
      },
      Status: (current) => UiHelper.itemStatusContainer(current)
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
    this.pageKey = 'emailpages'
  }

  onFetchSuccess(results) {
    this.fieldDef = results[0].fieldDef
    const cbdefData = results[1]
    // UiHelper.populateOptions(this.fieldDef, cbdefData, 'entities')
    this.setState({
      items: results[0].items
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['emailpages'], {}),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['emailpages', current.id], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['emailpages', current.id], {}),
    });
  }

  openModalForDuplicatePage(current) {
    console.log('edit:', current);
    this.formDefinition = pageActionsFieldDef
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['miscs', 'emailpage-duplication'], {orgId: this.orgId}),
      apiData: {
        pageId: current.id,
      },
      modalOpen: true
    });
  }

  async openModalForPreview(current) {
    window.open('/admin/orgs/' + current.orgId + '/preview?templateId=' + current.id, 'slixtaPreivew');
  }

  onActions = (current) => {
    const actions = []
    actions.push(
      <Tooltip title='Edit' placement='top-start' key={current.id + 'editTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => {this.openModalForEdit(current)}}>edit</i>
        </span>
      </Tooltip>
    )
    actions.push(
      <Tooltip title='Clone Page' placement='top-start' key={current.id + 'clone'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => { this.openModalForDuplicatePage(current)}}>copy_all</i>
        </span>
      </Tooltip>
    )
    actions.push(
      <Link to={this.baseRoute() + '/emailpages/' + current.id + '/blocks'} key={current.id + '-cbdefs'}>
        <Tooltip title={Helper.getString('blocks-widgets')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>view_agenda</i>
          </span>
        </Tooltip>
      </Link>
    )
    actions.push(
      <Tooltip title='Preview' placement='top-start' key={current.id + 'preview'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => this.openModalForPreview(current)}>desktop_windows</i>
        </span>
      </Tooltip>
    )
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDefsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  },
  line2: {
    fontSize: 12,
    color: 'gray'
  },
})

const pageActionsFieldDef = {
  formType: 'edit',
  steps: [
    {
      fields: [
        {
          hint: 'Action',
          key: 'action',
          label: 'Action',
          type: 'radio',
          options: [
            { key: 'CLONE_PAGE', label: 'CLONE_PAGE' },
          ],
          defaultValue: 'CLONE_PAGE',
          placement: 'left',
          // eslint-disable-next-line camelcase
          v_required: { value: true, err: 'Action required' }
        }
      ]
    }
  ]
}