import React, { Component } from 'react';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../../common/Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';

export class ProdLists extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('lists')
    this.pageKey = 'prodLists'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['lists'], { orgId: this.orgId, pb: 'fieldDefProdList,fieldDefSEO' }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Order: (current) => { return current.order },
      'Slug / ID': (current) => {
        return UiHelper.itemDetails(current)
      },
      Title: (current) => current.title,
      Status: (current) => {
        if(AppConfig.CUSTOM_STYLE) {
          return UiHelper.itemStatusContainer(current)
        } else {
          return <span>{UiHelper.chipHome(current.itype)}</span> //TODO handle the itype from UiHelper
        }
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId,
    }
  }

  onFetchSuccess(results) {
    this.fieldDefSEO = results[0].fieldDefSEO
    this.fieldDefProdList = results[0].fieldDefProdList
    this.fieldDef = this.fieldDefProdList
    this.setState({
      items: Helper.sortDictArray(results[0].items),
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['lists'], { orgId: this.orgId }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['lists', current.id], { orgId: this.orgId }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['lists', current.id], { orgId: this.orgId }),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonSEO(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    actions.push(
      <Link to={this.baseRoute() + '/lists/' + current.id} key={current.id + '-lists'} >
        <Tooltip title='Items in list' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>layers</i>
          </span>
        </Tooltip>
      </Link>
    )
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})
