import React, {  } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { Grid } from '@material-ui/core';

import { BaseEntityPage } from './BaseEntityPage';
import { ApiHelper } from '../helpers/ApiHelper';
import { AppTheme } from '../../styles/AppTheme';
import { Helper } from '../helpers/Helper';
import { SubEntityCard } from './SubEntity/SubEntityCard';

export class SettingsMaster extends BaseEntityPage {
  constructor(props, options) {
    super(props)
    this.pageTitle = Helper.getString('settingsMaster')
    this.pageKey = 'settingsMaster'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.emptyText = ' ' // space
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.groupId = 'settings' + this.orgId
    this.toFetch = [
      {
        key: 'item',
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['items', this.groupId + '-all'], {
          groupId: this.groupId,
          pb: 'fieldDefConfig,fieldDefStyleConfig,fieldDefCustomCss'
        })
      },
    ]
    this.noAdd = true
    this.apiData = {}
  }

  onFetchSuccess(results) {
    console.log('onFetchSuccess:', results)
    const item = this.getResult(results, 'item')

    this.fieldDefConfig = item.fieldDefConfig
    this.fieldDefStyleConfig = item.fieldDefStyleConfig
    this.fieldDefCustomCss = item.fieldDefCustomCss
    this.setState({
      item
    })
  }

  renderAboveTable() {
    return (
      <div className={css(Styles.containerNarrow)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Config'} formDefinition={this.fieldDefConfig}
              item={this.state.item.config || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefConfig, 'config')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Style'} formDefinition={this.fieldDefStyleConfig}
              item={this.state.item.styleConfig || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefStyleConfig, 'styleConfig')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Custom CSS'} formDefinition={this.fieldDefCustomCss}
              item={this.state.item.customCss || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefCustomCss, 'customCss')}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  openModalForEdit(current, formDefinition, attrName) {
    this.attrName = attrName
    this.formDefinition = formDefinition
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.setState({ modalOpen: true })
  }

  customSubmit(options) {
    const { formType, formData } = options
    console.log('customSubmit:', formData)
    ApiHelper.call({
      method: 'PUT', paths: ['items', this.groupId + '-all'], queryParams: { groupId: this.groupId },
      jsonBody: { [this.attrName]: { ...formData } }
    },
    (err, data) => {
      if (err) {
        const errorMessage = Helper.getErrorMsg(err)
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
        return
      } else {
        this.setState({ modalOpen: false })
        this.fetchItems()
      }
    })
  }
}

const Styles = StyleSheet.create({
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
  container: {
    textAlign: 'center',
    maxWidth: AppTheme.viewPort,
    marginBottom: 20,
    width: '100%',
  }
})