import { StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPageSlixta } from '../../../components/BaseEntityPageSlixta';
import { UiHelper } from '../../../common/helpers/UiHelper';
const queryString = require('query-string');

export class CoNestedItems extends BaseEntityPageSlixta {

  constructor(props) {
    super(props, { assetPicker: true });
    this.pageTitle = Helper.getString('cosl2')
    this.orgId = this.props.match.params.orgId
    this.typeId = this.props.match.params.id
    this.itemId = this.props.match.params.itemId
    this.entityAttrName = queryString.parse(this.props.location.search).nestedattr
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('cotypes'), to: this.baseRoute() + '/cos' },
      { title: Helper.getString('cos'), to: this.baseRoute() + '/cos/' + this.typeId + '/items' },
      { title: Helper.getString('cosl2') },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['cos', this.itemId], { pb: 'fieldDef' }) },
    ]
    this.tableHeadersMap = {
      Order: (current) => current.order,
      Info: (current) => current.title
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId,
      typeId: this.typeId
    }
  }

  onFetchSuccess(results) {
    console.log('CoItems:onFetchSuccess:', results)
    if(results[0].title) {
      this.pageTitle = results[0].title
    }
    this.fieldDef = results[0].fieldDef
    this.fieldDef.steps[0].fields = this.fieldDef.steps[0].fields.find(i => i.key === this.entityAttrName).fields
    this.formDefinition = this.fieldDef
    this.setState({
      items: Helper.sortDictArray(Helper.dictToArray(results[0][this.entityAttrName] || {})),
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName,
        orgId: this.orgId, typeId: this.typeId },
      apiEndPoint: ApiHelper.makeUrlPath(['cos', this.itemId]),
    })
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName,
        orgId: this.orgId, typeId: this.typeId, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['cos', this.itemId]),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName,
        orgId: this.orgId, typeId: this.typeId, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['cos', this.itemId]),
    });
  }

  onActions(current) {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 16
  }
})