/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppStyles } from '../../../styles/AppTheme';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { StyleSheet, css } from 'aphrodite';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { bulletsToList, bulletsToTree, generateText, removeField, treeToBullets } from './ContentUtils';

function generateId(existingIds) {
  let id = 0;
  do {
    id = (new Date()).getTime();
  } while(existingIds.includes(id));
  return id;
}

export class ContentPlanner extends BaseEntityPage {
  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CONTENT_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('content-planner')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('content-planner') },
    ]
    this.toFetch = [
      {
        method: 'GET',
        paths: ['contents', this.orgId],
        queryParams: { groupId: this.orgId, pb: 'fieldDefContentTopicsEntity', limit: 1 },
        ms: this.ms,
      },
    ]
    this.pageKey = 'content-planner'
    this.tableHeadersMap = {
      'Topic Pillars': (current) => {
        return <span className={css(Styles.spanValue)}>{current.pillar}</span>
      },
      'Cluster Titles': (current) => {
        return <div className={css(Styles.clusters)}>
          {current.clusters.map(c => <p>{c}</p>)}
        </div>
      },
      'Generated Content': (current) => {
        return <div><span>Blogs:&nbsp;{current.blogs.length}</span></div>
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.entityAttrName = 'topics';
    this.headerActions = [{
      label: this.addNewLabel || 'Add title',
      onClick: () => this.openModalForAdd()
    }];

    this.personas = this.personas || [];
    this.topics = this.topics || [];
    this.topicClusters = this.topicClusters || [];
    this.state.selectedPersonaId = 'none';
    this.onPersonaChange = this.onPersonaChange.bind(this);
    this.state.seedKeywordsInput = '';
    this.onSeedKeywordsInputChange = this.onSeedKeywordsInputChange.bind(this);
    this.onGenerateContentPlanClick = this.onGenerateContentPlanClick.bind(this);
    this.onAddToPlanClick = this.onAddToPlanClick.bind(this);
    this.state.contentPlanInput = '';
    this.state.contentPlanSuggestion = '';
    this.state.contentPlanSuggestionsList = [];
    this.onContentPlanInputChange = this.onContentPlanInputChange.bind(this);
  }

  onFetchSuccess(results) {
    this.personas = Object.values(results[0].personas);
    this.topics = Object.values(results[0].topics || {});
    this.topics.sort((a, b) => {
      return (a.topic === b.topic) ? (a.title < b.title ? -1 : 1) : (a.topic < b.topic ? -1 : 1);
    });
    this.topicClusters = Object.values(this.topics.reduce((p, c) => {
      const parent = p[c.topic];
      if (parent) {
        parent.clusters.push(c.title);
        parent.blogs = parent.blogs.concat(c.blogs);
      } else {
        p[c.topic] = {
          pillar: c.topic,
          clusters: [c.title],
          blogs: [...c.blogs],
        };
      }
      return p;
    }, {}));

    const fieldDefContentTopicsEntity = JSON.parse(JSON.stringify((results[0].fieldDefContentTopicsEntity)));
    fieldDefContentTopicsEntity.steps[0].fields.find(i => i.key === 'persona').options = this.personas.map(p => ({ key: p.id, label: p.title }));
    this.fieldDef = this.formDefinition = fieldDefContentTopicsEntity;

    this.setState({
      items: this.topicClusters,
    })
  }

  onAdd() {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', this.orgId], queryParams: {} }),
    });
  }

  onEdit(current) {
    this.formDefinition = JSON.parse(JSON.stringify(this.formDefinition));
    this.formDefinition.steps[0].fields.find(i => i.key === 'topic').value = current.pillar;
    removeField(this.formDefinition.steps[0].fields, 'title');
    removeField(this.formDefinition.steps[0].fields, 'persona');

    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, pillar: current.pillar },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', this.orgId], queryParams: {} }),
    });
  }

  customSubmit(options) {
    const { formType, formData } = options;

    const apiMethod = this.state.apiMethod;
    const apiEndPoint = this.state.apiEndPoint;
    const jsonBody = formData;
    jsonBody.blogs = jsonBody.blogs || [];

    const apiRequests = [];

    if (formType === 'add') {
      apiRequests.push({
        method: apiMethod,
        endPoint: apiEndPoint,
        jsonBody,
      });
    } else if (formType === 'edit') {
      const pillar = jsonBody.pillar;
      delete jsonBody.pillar;

      this.topics.filter(t => t.topic === pillar).map(t => {
        jsonBody.id = t.id;
        apiRequests.push({
          method: apiMethod,
          endPoint: apiEndPoint,
          jsonBody: JSON.parse(JSON.stringify(jsonBody)),
        });
      });
    }

    const requestPromises = [];
    apiRequests.forEach(r => {
      requestPromises.push(ApiHelper.callAwait({
        method: r.method,
        endPoint: r.endPoint,
        jsonBody: r.jsonBody,
      }));
    })
    Promise.all(requestPromises)
      .then(() => {
        this.setState({ modalOpen: false })
        this.fetchItems();
      })
      .catch((err) => {
        const errorMessage = Helper.getErrorMsg(err)
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
      })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []

    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(
      <Link to={this.baseRoute() + '/content/pillars/' + current.pillar} key={current.pillar}>
        <Tooltip title='Manage Cluster' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>view_agenda</i>
          </span>
        </Tooltip>
      </Link>
    )

    return actions
  }

  onAddToPlanClick() {
    if (!this.state.contentPlanInput) {
      this.showMessage('Please enter or generate a plan in the text box, and make sure it is in the correct format.');
      return;
    }
    const newTopics = {};
    this.topics.forEach(t => {
      newTopics[t.id] = {
        id: t.id,
        title: t.title,
        topic: t.topic,
        persona: t.persona,
        blogs: t.blogs || [],
      };
    });
    const existingIds = Object.keys(newTopics).map(t => Number(t));
    let topicsToAdd = bulletsToList(this.state.contentPlanInput).map(i => {
      const id = generateId(existingIds);
      existingIds.push(id);
      return {
        id,
        title: i.child,
        topic: i.parent,
        persona: this.state.selectedPersonaId,
        blogs: [],
      };
    });
    topicsToAdd = topicsToAdd.filter(t => {
      return this.topics.find(e => e.title === t.title && e.topic === t.topic) ? false : true;
    });
    if (topicsToAdd.length === 0) {
      this.showMessage('Nothing new to add to the content plan.');
      return;
    }
    topicsToAdd.forEach(t => {
      newTopics[t.id] = t;
    });
    this.setState({
      fetchState: ApiHelper.State.LOADING
    });
    return ApiHelper.callAwait({
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', this.orgId], queryParams: {} }),
      jsonBody: {
        topics: newTopics,
      },
    }).finally(() => {
      this.fetchItems();
    });
  }

  onPersonaChange(event) {
    const newPersona = event.target.value;
    this.setState(prevState => ({...prevState, selectedPersonaId: newPersona}));
  }

  onSeedKeywordsInputChange(event) {
    const seedKeywordsInputValue = event.target.value;
    const newSeedKeywords = seedKeywordsInputValue.split(',').map(k => k.toLowerCase().trim());
    this.setState(prevState => ({
      ...prevState,
      seedKeywordsInput: seedKeywordsInputValue,
      seedKeywords: newSeedKeywords,
    }));
  }

  onContentPlanInputChange(event) {
    const contentPlanInput = event.target.value;
    const contentPlanSuggestionsList = bulletsToList(this.state.contentPlanSuggestion, contentPlanInput);
    this.setState(prevState => ({...prevState, contentPlanInput, contentPlanSuggestionsList}));
  }

  onContentPlanSuggestionClick() {
    const contentPlanInput = this.state.contentPlanSuggestion;
    const contentPlanSuggestionsList = bulletsToList(this.state.contentPlanSuggestion, contentPlanInput);
    this.setState(prevState => ({
      ...prevState,
      contentPlanInput,
      contentPlanSuggestionsList,
    }));
  }

  onContentPlanSuggestionListItemClick(clicked) {
    clicked.selected = !clicked.selected;
    const newContentPlanSuggestionsList = JSON.parse(JSON.stringify(this.state.contentPlanSuggestionsList));
    const contentPlanTree = bulletsToTree(this.state.contentPlanInput);
    if (clicked.parent in contentPlanTree) {
      if (clicked.selected) {
        contentPlanTree[clicked.parent].push(clicked.child);
      } else {
        contentPlanTree[clicked.parent] = contentPlanTree[clicked.parent].filter(s => s !== clicked.child);
      }
    } else if (clicked.selected) {
      contentPlanTree[clicked.parent] = [clicked.child];
    }
    const newInputValue = treeToBullets(contentPlanTree);
    this.setState(prevState => ({
      ...prevState,
      contentPlanInput: newInputValue,
      contentPlanSuggestionsList: newContentPlanSuggestionsList,
    }));
  }

  async onGenerateContentPlanClick() {
    const selectedPersona = this.personas.find(p => p.id === this.state.selectedPersonaId);
    const userPersona = selectedPersona ? selectedPersona.title : '';

    if (!userPersona) {
      this.showMessage('Please select a user persona to generate a content plan. You can add a new user persona under AI Content > Personas');
      return;
    }

    function normalizeContentPlanInput(input) {
      return input.split('\n').map(i => {
        i = i.trim();
        if (i.length === 0) {
          return i;
        }
        if (i.startsWith('- ') || i.startsWith('-- ')) { return i; }
        else if (i.startsWith('--')) { return `-- ${i.substring(2)}`; }
        else if (i.startsWith('-')) { return `- ${i.substring(1)}`; }
        else { return `- ${i}`; }
      }).filter(i => i.length > 0).join('\n');
    }

    const contentPlanInput = normalizeContentPlanInput(this.state.contentPlanInput);
    const contentPlanTree = bulletsToTree(contentPlanInput);
    const contentPlanTopics = Object.keys(contentPlanTree).map(t => `"${t}"`).join(', ');
    const promptSequence = 'planner.topicCluster';
    const replacements = {
      '${userPersona}': userPersona,
      '${contentPlanTopics}': contentPlanTopics,
    }

    const generatedResult = await generateText(this.orgId, this.ms, promptSequence, replacements, this.updateLoaderUi.bind(this));
    const contentPlanSuggestion = treeToBullets(bulletsToTree(generatedResult.text));
    const contentPlanSuggestionsList = bulletsToList(contentPlanSuggestion, contentPlanInput);
    this.setState(prevState => ({ ...prevState, contentPlanInput, contentPlanSuggestion, contentPlanSuggestionsList }));
  }

  renderAboveTable() {
    return(
      <div className='tw-ctr w-full mx-auto'>
        <div className='w-full p-4 border-2 border-gray-100 rounded-xl'>
          <p className='text-lg font-bold'>Planner</p>
          <br/>
          <select name='personas' onChange={this.onPersonaChange} value={this.state.selectedPersonaId} className='w-full block p-2.5 grow text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-gray-300'>
            <option key='none' value='none'>Select persona</option>
            {this.personas.map(p => <option key={p.id} value={p.id}>{p.title}</option>)}
          </select>
          <br/>
          <div className='relative w-full'>
            <textarea value={this.state.contentPlanInput} onChange={this.onContentPlanInputChange} className='block p-2.5 w-full h-40 z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-gray-100 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500' placeholder='Enter or generate a content plan'></textarea>
          </div>
          <br/>
          {
            this.state.contentPlanInput === '' && this.state.contentPlanSuggestion !== '' &&
              <div className='w-full flex flex-col'>
                <div className='w-full m-1 p-2 rounded bg-blue-100 break-normal cursor-pointer whitespace-pre-wrap' onClick={() => this.onContentPlanSuggestionClick()}>{this.state.contentPlanSuggestion}</div>
              </div>
          }
          {
            this.state.contentPlanInput !== '' && this.state.contentPlanSuggestionsList.length > 0 &&
              <div className='flex flex-wrap'>
                {this.state.contentPlanSuggestionsList.map(t => <div className={`w-full m-1 p-2 rounded bg-${t.selected ? 'yellow' : 'blue'}-100 break-normal cursor-pointer`} key={t.text} onClick={() => this.onContentPlanSuggestionListItemClick(t)}>{t.text}</div>)}
              </div>
          }
          <br/>
          <div className='flex justify-center'>
            <button type='button' className='p-2.5 mr-2.5 w-36 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 disabled:opacity-50 disabled:bg-blue-700' onClick={this.onGenerateContentPlanClick}>Generate</button>
            <button type='button' className='p-2.5 mr-2.5 w-36 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 disabled:opacity-50 disabled:bg-blue-700' onClick={this.onAddToPlanClick}>Add</button>
          </div>
        </div>
        <br/>
      </div>
    )
  }

  updateLoaderUi(isLoading) {
    this.setState({
      fetchState: isLoading ? ApiHelper.State.LOADING : ApiHelper.State.READY
    });
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 16
  },
  clusters: {
    fontSize: 12
  },
});