import { StyleSheet } from 'aphrodite';
import { AppTheme, AppStyles } from '../../../styles/AppTheme';

export const Styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: AppTheme.primaryBg,
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    marginTop: AppTheme.toolbarHeight,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: AppTheme.viewPort,
  },
  tile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //maxWidth: 300,
    // minWidth: 200,

    minHeight: 80,
    boxShadow: 'none',
    backgroundColor: AppTheme.secondaryBg,
    '@media (max-width: 767px)': {
      minHeight: 60,
    }
  },
  smallTile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    //maxWidth: 300,
    minHeight: 60,
    boxShadow: 'none',
    backgroundColor: AppTheme.secondaryBg,
    '@media (max-width: 767px)': {
      minHeight: 40,
    }
  },
  title: {
    color: AppTheme.primaryColor,
    fontSize: 16,
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  }
})
