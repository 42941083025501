import React, { Component, Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite';
import Dialog from '@material-ui/core/Dialog';
import { Tooltip } from '@material-ui/core';

import { Helper } from '../../common/helpers/Helper';
import { CustomDynamicFormDialogStyles, CustomDynamicFormStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { JcropHelper } from '../../helpers/JcropHelper';
import { AppStyles, AppTheme } from '../../styles/AppTheme';
import { imageSpotFields1, imageSpotFields2 } from '../../data/data';
import { AppButton } from '../../common/components/AppButton';
import { Link } from 'react-router-dom';

const uuid = require('uuid');

class SvgWidget extends window.Jcrop.Widget {
  init() {
    super.init();
    const iDiv = document.createElement('div');
    iDiv.id = 'marker';
    iDiv.className = 'marker';
    // iDiv.onclick = function (e) { console.log(e, 'div clicked'); };
    // iDiv.onmouseover = function (e) { console.log(e, 'div hover'); };
    this.el.appendChild(iDiv);
  }
}

export class CbImageSpots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.getImage()
    }
    this.modalOpen = this.props.modalOpen
  }

  componentDidMount() {
    window.addEventListener('resize', () => { this.resetJcrop(); this.onActualImgLoad() });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => { this.resetJcrop(); this.onActualImgLoad() });
  }

  getRois = () => {
    const hotspots = this.props.items[0].data.hotspots || []
    const pH = []
    for (const h of hotspots) {
      pH.push({
        _id: h.id,
        bottomLeftCoords: h.bottomLeftCoordinates,
        topRightCoords: h.topRightCoordinates
      })
    }
    return pH
  }

  getImage() {
    if (this.props.items && this.props.items.length > 0 && this.props.items[0].data.imageLink) {
      return {
        imageLink: this.props.items[0].data.imageLink,
        id: this.props.items[0].data.image,
        rois: this.getRois()
      }
    } else {
      return null
    }
  }

  onActualImgLoad = async () => {
    if (!this.state.image || !document.getElementById(this.state.image.id)) {
      return
    }
    this.targetImageHeight = document.getElementById(this.state.image.id).height;
    this.targetImageWidth = document.getElementById(this.state.image.id).width;
    this.actualImageHeight = this.props.items[0].data.imageHeight;
    this.actualImageWidth = this.props.items[0].data.imageWidth;
    this.attachJcrop();
  }

  attachJcrop = () => {
    if (!document.getElementById(this.state.image.id) || this.jcrop) {
      return
    }

    this.jcrop = window.Jcrop && window.Jcrop.attach(this.state.image.id, {
      multi: true,
      shadeColor: 'transparent',
      widgetConstructor: SvgWidget,
    });

    JcropHelper.loadMarkers(
      this.jcrop,
      this.state.image.rois,
      this.targetImageHeight,
      this.targetImageWidth,
      this.actualImageHeight,
      this.actualImageWidth
    );

    this.jcrop.listen('crop.active', (widget, e) => {
      //console.log('crop.active')
      this.cropActive(widget, e);
    });
    this.jcrop.listen('crop.update', (widget, e) => {
      //console.log('crop.update')
      this.cropActive(widget, e);
    });
    this.jcrop.listen('crop.change', (widget, e) => {
      //console.log('crop.change', e)
      this.cropChange(widget, e);
    });
  }

  getDelta = () => {
    const rF = Math.round((this.targetImageHeight / this.targetImageWidth) * 14)
    //return rF
    return 28
  }

  cropActive = (widget, e) => {
    widget.pos.w = this.getDelta()
    widget.pos.h = this.getDelta()
    this.setState({
      widget: widget,
    })
  }

  cropChange = (widget, e) => {
    this.openRoiDialog(widget)
  }

  onCloseCustomModal() {
    this.setState({
      isEditOrDeleteModalOpen: false
    })
    this.resetJcrop()
    this.onActualImgLoad()
  }

  handleEdit(item) {
    const { widget, blc } = item
    this.props.setCurrentHotpot(item)
    this.setState({ isEditOrDeleteModalOpen: false })
    const fieldsCopy = Helper.makeFieldsCopy(imageSpotFields2, { formType: 'edit' })
    if (item.edit === true) {
      const hotspots = this.props.items[0].data.hotspots || []
      for (const h of hotspots) {
        if (h.id === widget.options.id) {
          const values = {}
          for (const f of fieldsCopy.steps[0].fields) {
            values[f.key] = h[f.key] || ''
          }
          this.props.openModalForEdit(values, fieldsCopy)
          return
        }
      }
    }
    if (item.delete === true) {
      const hotspots = (this.props.items[0].data && this.props.items[0].data.hotspots) || []
      for (const h of hotspots) {
        if (h.id === widget.options.id) {
          const values = {}
          for (const f of fieldsCopy.steps[0].fields) {
            values[f.key] = h[f.key] || ''
          }
          this.props.openModalForDelete(values, fieldsCopy)
          return
        }
      }
    }
    if (item.add === true) {
      this.props.openModalForEdit({}, fieldsCopy)
    }
  }

  getMarketPlace = (url) => {
    const loc = new URL(url)
    let marketPlace = null
    if (loc.hostname.includes('amazon')) {
      marketPlace = 'Amazon'
    } else if (loc.hostname.includes('flipkart')) {
      marketPlace = 'Flipkart'
    } else if (loc.hostname.includes('myntra')) {
      marketPlace = 'Myntra'
    }
    return (
      <Link target='_blank' to={{pathname: url}} className={css(Styles.link)}>
        {marketPlace}
      </Link>
    )
  }

  actionDialog() {
    const hotspots = this.props.items[0].data.hotspots || []
    let pH = {}
    for (const h of hotspots) {
      if (h.id === this.state.widget.options.id) {
        pH = h
        break
      }
    }
    return (
      <Dialog
        open={this.state.isEditOrDeleteModalOpen}
        aria-labelledby={Helper.getString('editOrDelete')}
        onClose={() => this.onCloseCustomModal()}
        fullWidth={true}
        classes={{
          root: css(CustomDynamicFormDialogCustomStyles.customRoot),
          paper: css(CustomDynamicFormDialogCustomStyles.customPaper),
        }}
        maxWidth='md'
      >
        <div className={css([CustomDynamicFormDialogCustomStyles.modalHeader])}>
          <div className={css(CustomDynamicFormDialogCustomStyles.modalTitle)}>{Helper.getString('editOrDelete')}</div>
          <button className={css(CustomDynamicFormDialogCustomStyles.modalCloseButton)} onClick={() => this.onCloseCustomModal()} disabled={this.state.inProcess}>
            <svg className={css(CustomDynamicFormDialogCustomStyles.modalCloseIcon)} viewBox='0 0 36 36'>
              <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
            </svg>
          </button>
        </div>
        <div className={css(CustomDynamicFormDialogCustomStyles.modalContent, Styles.modalContent)}>
          {pH.title &&
            <p className={css(Styles.title)}>Title: {pH.title}</p>
          }
          {pH.subTitle &&
            <p className={css(Styles.title)}>Subtitle: {pH.subTitle}</p>
          }
          {pH.price &&
            <p className={css(Styles.title)}>Price: {pH.price}</p>
          }
          {pH.priceDiscounted &&
            <p className={css(Styles.title)}>Price Discounted: {pH.priceDiscounted}</p>
          }
          {/* {pH.navLink &&
            <p className={css(Styles.title)}>Navigation link: <span><Link target='_blank' to={{pathname: pH.navLink}} className={css(Styles.link)}>
            Link
          </Link></span></p>
          } */}
          {pH.navLink &&
            <p className={css(Styles.title)}>Navigation link: {pH.navLink}</p>
          }
          {/* {pH.addToCartLink &&
            <p className={css(Styles.title)}>Product link: <span><Link target='_blank' to={{pathname: pH.addToCartLink}} className={css(Styles.link)}>
            Link
          </Link></span></p>
          } */}
          {pH.addToCartLink &&
            <p className={css(Styles.title)}>Product/Buy link: {pH.addToCartLink}</p>
          }
          {(pH.marketPlace1 || pH.marketPlace2 || pH.marketPlace3) &&
            <div className={css(Styles.marketPlacesContainer)}>
              <p className={css(Styles.titleMarketPlace)}>Market places:</p>
              <div className={css(Styles.marketPlaces)}>
                {pH.marketPlace1 &&
                  this.getMarketPlace(pH.marketPlace1)
                }
                {pH.marketPlace2 &&
                  this.getMarketPlace(pH.marketPlace2)
                }
                {pH.marketPlace3 &&
                  this.getMarketPlace(pH.marketPlace3)
                }
              </div>
            </div>
          }
          <div className={css(CustomDynamicFormCustomStyles.actions)}>
            <AppButton
              buttonStyle={[CustomDynamicFormCustomStyles.button]}
              disabled={this.state.inProcess}
              onClick={() => this.handleEdit({ widget: this.state.widget, blc: this.state.blc, trc: this.state.trc, edit: true })}
              buttonText={Helper.getString('save')}
            />
            <div className={css(Styles.divider)}></div>
            <AppButton
              buttonStyle={[CustomDynamicFormCustomStyles.button]}
              disabled={this.state.inProcess}
              onClick={() => this.handleEdit({ widget: this.state.widget, blc: this.state.blc, trc: this.state.trc, delete: true })}
              buttonText={Helper.getString('delete')}
            />
          </div>
        </div>
      </Dialog >
    );
  }

  openRoiDialog = (widget) => {
    widget.pos.w = this.getDelta()
    widget.pos.h = this.getDelta()
    const blc = JcropHelper.getBottomLeftCoorindates(widget, this.targetImageHeight, this.targetImageWidth, this.actualImageHeight, this.actualImageWidth)
    const trc = JcropHelper.getTopRightCoorindates(widget, this.targetImageHeight, this.targetImageWidth, this.actualImageHeight, this.actualImageWidth)
    if (widget.options.id) {
      this.setState({
        widget: widget,
        blc: blc,
        trc: trc
      }, () => {
        this.setState({ isEditOrDeleteModalOpen: true })
      })
    } else {
      this.setState({
        widget: widget,
        blc: blc,
        trc: trc,
      }, () => {
        this.handleEdit({ widget, blc, trc, add: true, id: uuid() })
      })
    }
  }

  resetJcrop = () => {
    JcropHelper.dettachJcrop(this.jcrop);
    this.jcrop = null;
  }

  removeWidget = () => {
    this.jcrop.removeWidget(this.jcrop.active);
  }

  handleImageUpload() {
    const fieldsCopy = Helper.makeFieldsCopy(imageSpotFields1, { formType: 'add' })
    this.props.openModalForAdd(fieldsCopy)
  }

  handleImageEdit() {
    this.props.setCurrentHotpot(null)
    const fieldsCopy = Helper.makeFieldsCopy(imageSpotFields1, { formType: 'edit' })
    if (this.props.items && this.props.items.length > 0) {
      fieldsCopy.steps[0].fields[0].imageHeight = this.props.items[0].data.imageHeight
      fieldsCopy.steps[0].fields[0].imageWidth = this.props.items[0].data.imageWidth
      fieldsCopy.steps[0].fields[0].disableDeletion = true
      fieldsCopy.steps[0].fields[0].mode = 'exact'
    }
    this.props.openModalForEdit({ imageLink: this.props.items[0].data.imageLink, image: this.props.items[0].data.image, theme: this.props.items[0].data.theme || '' }, fieldsCopy)
  }

  getEditIcon = () => {
    return (
      <Tooltip title='Replace Image' placement='top-start'>
        <span onClick={() => this.handleImageEdit()} className={css(AppStyles.entityTableLinkIcon, Styles.editIcon)}>
          <i className='material-icons-outlined'>edit</i>
        </span>
      </Tooltip>
    )
  }

  render() {
    if (this.modalOpen !== this.props.modalOpen) {
      this.modalOpen = this.props.modalOpen
      if (this.state.image && this.state.image.id) {
        this.resetJcrop()
        this.onActualImgLoad()
      }
    }

    return (
      <div className={css(Styles.container)}>
        {(!this.props.items || this.props.items.length === 0) ?
          <div onClick={() => this.handleImageUpload()} className={css(Styles.uploadButtonContainer)}>
            <p className={css(Styles.uploadText)}>{Helper.getString('uploadHotspotImage')}</p>
            <AppButton
              buttonText={Helper.getString('upload')}
            />
          </div>
          :
          <Fragment>
            {this.getEditIcon()}
            <div className={css(Styles.bannerContainer)}>
              {this.state.image &&
                <img
                  id={this.state.image.id}
                  alt={Helper.getString('bannerImageAlt')}
                  onLoad={() => this.onActualImgLoad()}
                  src={this.state.image.imageLink}
                  className={css(Styles.img)}
                />
              }
            </div>
          </Fragment>
        }
        {this.state.isEditOrDeleteModalOpen && this.actionDialog()}
      </div >
    )
  }
}

const Styles = StyleSheet.create({
  divider: {
    width: 10
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: AppTheme.viewPort,
    flex: 1
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30
  },
  modalContent: {
    '@media(max-width: 767px)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1
    },
  },
  bannerContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 50,
    '@media(max-width: 767px)': {
    },
  },
  img: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  uploadButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    border: '1px dashed #E5E5E5',
    borderRadius: AppTheme.borderRadius,
    minHeight: 500,
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden'
  },
  uploadText: {
    margin: 0,
    marginBottom: 20,
    fontSize: 20,
    color: '#000000',
    fontWeight: 600,
    textAlign: 'center'
  },
  editIcon: {
    width: 'fit-content',
    alignSelf: 'flex-end'
  },
  marketPlacesContainer: {
    display: 'flex',
    margin: '10px 0px'
  },
  marketPlaceImage: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain'
  },
  marketPlaces: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5
  },
  link: {
    color: '#000000',
    marginRight: 5,
    textDecoration: 'none',
    borderBottom: '0.5px solid #000000'
  },
  title: {
    margin: '10px 0px'
  },
  titleMarketPlace: {
    margin: 0
  }
})

const CustomDynamicFormDialogCustomStyles = AppConfig.CUSTOM_STYLE ? CustomDynamicFormDialogStyles : StyleSheet.create({
  modalHeader: {
    backgroundColor: 'whitesmoke',
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15
  },
  modalTitle: {
    color: 'black',
    fontSize: 20,
  },
  modalContent: {
    paddingLeft: 10,
    paddingRight: 10
  },
  modalCloseButton: {
    position: 'absolute',
    top: 15,
    right: 15,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalCloseIcon: {
    width: 18,
    height: 18
  },
  customRoot: {
  },
  customPaper: {
  }
})

const CustomDynamicFormCustomStyles = AppConfig.CUSTOM_STYLE ? CustomDynamicFormStyles : StyleSheet.create({
  button: {
    color: AppTheme.buttonTextColor,
    boxShadow: 'none',
  },
})


