import React, { Component, Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { Grid, Card, CardContent, CardActionArea, Tooltip, Paper, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { BaseEntityPage } from '../BaseEntityPage';
import { ApiHelper } from '../../helpers/ApiHelper';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Helper } from '../../helpers/Helper';
import { SubEntityCard } from '../SubEntity/SubEntityCard';
import { SubEntityList } from '../SubEntity/SubEntityList';
import { MultiImageUploaderDialog } from '../MultiImageUploaderDialog';
import { UiHelper } from '../../helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';

export class Product extends BaseEntityPage {
  constructor(props, options) {
    super(props)
    this.creatorPurposeOne = options.creatorPurposeOne
    this.catalogParams = AppConfig.CATALOG_PARAMS ? AppConfig.CATALOG_PARAMS.split(',').map(i => i.trim()) : []
    this.pageTitle = Helper.getString('product')
    this.noAdd = true
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.prodId = this.props.match.params.id
    this.emptyText = ' ' // space
    this.apiSpecForImageUploader = {}
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: 'Products', to: this.baseRoute() + '/products' },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      {
        key: 'item',
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['products', this.prodId], {
          orgId: this.orgId,
          pb: 'fieldDef,fieldDefSEO,fieldDefImages,fieldDefDescr,fieldDefVariants,fieldDefVariantOptions'
            + ',fieldDefProdVariantSpecsCustom'
        })
      },
      { key: 'cats', method: 'GET', endPoint: ApiHelper.makeUrlPath(['cats'], { orgId: this.orgId, maxPages: 5 }) },
      { key: 'lists', method: 'GET', endPoint: ApiHelper.makeUrlPath(['lists'], { orgId: this.orgId }) },
    ]
    if (this.creatorPurposeOne) {
      this.toFetch.push(
        {
          key: 'creators', method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'],
            { purposeOne: this.creatorPurposeOne })
        }
      )
    }
    if (!this.catalogParams.includes('noTags')) {
      this.toFetch.push({ key: 'tags', method: 'GET', endPoint: ApiHelper.makeUrlPath(['tags'], { orgId: this.orgId }) })
    }
    if (!this.catalogParams.includes('noBrands')) {
      this.toFetch.push({ key: 'brands', method: 'GET', endPoint: ApiHelper.makeUrlPath(['brands'], { orgId: this.orgId }) })
    }
    this.apiData = {
    }
  }

  onFetchSuccess(results) {
    console.log('onFetchSuccess:', results)
    const item = this.getResult(results, 'item')

    const fields = []
    item.fieldDef.steps[0].fields.forEach(f => {
      if (f.key === 'tagIds' && this.catalogParams.includes('noTags')) return
      if (f.key === 'brandId' && this.catalogParams.includes('noBrands')) return
      fields.push(f)
    })
    item.fieldDef.steps[0].fields = fields

    this.pageTitle = item.title

    this.fieldDef = item.fieldDef
    this.fieldDefSEO = item.fieldDefSEO
    this.fieldDefDescr = item.fieldDefDescr
    this.fieldDefVariants = item.fieldDefVariants
    this.fieldDefVariantOptions = item.fieldDefVariantOptions
    this.fieldDefProdVariantSpecsCustom = item.fieldDefProdVariantSpecsCustom

    this.formDefinition = this.fieldDef

    this.cats = this.getResult(results, 'cats')
    const catsToChoose = Helper.processCats(this.cats.items, true)
    UiHelper.populateOptions(this.fieldDef, { items: catsToChoose }, 'catIds')

    this.lists = this.getResult(results, 'lists')
    if (this.lists) {
      UiHelper.populateOptions(this.fieldDef, this.lists, 'listIds')
    }

    this.brands = this.getResult(results, 'brands')
    if (this.brands) {
      UiHelper.populateOptions(this.fieldDef, this.brands, 'brandId')
    }

    this.tags = this.getResult(results, 'tags')
    if (this.tags) {
      UiHelper.populateOptions(this.fieldDef, { items: this.tags }, 'tagIds')
    }

    this.creators = this.getResult(results, 'creators')
    if (this.creators) {
      UiHelper.populateOptions(this.fieldDef, this.creators, 'creatorId')
    }

    this.setState({
      item: item,
      variants: Helper.dictToArray(item.variants),
      variantOptions: Helper.dictToArray(item.variantOptions),
      images: Helper.dictToArray(item.images)
    })

    this.fieldDefImages = item.fieldDefImages
    this.imagesTableHeadersMap = {
      order: (current) => current.order,
      imageName: (current) => current.imageName,
    }

    this.variantsTableHeadersMap = {}
    const fieldsVariant = []
    for (const id in item.variantOptions) {
      const variantOption = item.variantOptions[id]
      this.variantsTableHeadersMap[variantOption.title] = (variant) => variant['variantOption' + variantOption.id]
      fieldsVariant.push({
        key: 'variantOption' + variantOption.id,
        type: 'radio',
        label: variantOption.title,
        hint: 'Select ' + variantOption.title,
        options: variantOption.options.split(',').map(i => {
          return { key: i.trim(), label: i.trim(), value: false }
        }),
        v_required: { value: true, err: 'Select ' + variantOption.title }, // eslint-disable-line camelcase
        placement: 'left'
      })
    }
    item.fieldDefVariants.steps[0].fields.forEach(i => {
      fieldsVariant.push(i)
    })
    const priceField = fieldsVariant.find(o => o.key === 'price')
    this.variantsTableHeadersMap.price = (variant) => {
      return variant.price && priceField && priceField.valueDisplayScale ?
        variant.price / priceField.valueDisplayScale : variant.price
    }
    const priceDiscounted = fieldsVariant.find(o => o.key === 'priceDiscounted')
    this.variantsTableHeadersMap.priceDiscounted = (variant) => {
      return variant.priceDiscounted && priceDiscounted && priceDiscounted.valueDisplayScale ?
        variant.priceDiscounted / priceField.valueDisplayScale : variant.priceDiscounted
    }
    this.variantsTableHeadersMap.state = (variant) => variant.state
    this.variantsTableHeadersMap.images = (variant) => {
      return variant.images ? Object.keys(variant.images).length : 0
    }

    if (this.catalogParams.includes('noVariants')) {
      // console.log('fieldsVariant:', fieldsVariant.map(f => f.key))
      // fieldsVariant = fieldsVariant.filter(f => ['price','priceDiscounted'].includes(f.key))
      // console.log('fieldsVariant:', fieldsVariant.map(f => f.key))
      this.variantsTableHeadersMap = Helper.selectDict('images,price,priceDiscounted,state', this.variantsTableHeadersMap)
    }

    this.fieldDefVariants = { steps: [{ fields: fieldsVariant }] }

    this.variantsOptionsTableHeadersMap = {
      Title: (current) => current.title,
      Options: (current) => current.options
    }
    // this.fieldDefParams = this.makeParamsFormDef(item)
  }

  closeImageUploader = () => {
    this.setState({
      imageModelOpen: false,
      currentItem: {},
    })
    this.fetchItems()
  }

  handleUploadSuccess = () => {
    this.apiSpecForImageUploader = {}
    this.closeImageUploader()
  }

  renderAboveTable() {
    const { item, docs, images, variants, variantOptions } = this.state
    return (
      <div className={css(Styles.containerNarrow)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Main'} formDefinition={this.formDefinition}
              item={this.state.item}
              openModalForEdit={this.openModalForEdit.bind(this)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Description'} formDefinition={this.fieldDefDescr}
              item={this.state.item}
              openModalForEdit={(current) => this.openModalForEdit(current, this.fieldDefDescr)}
            />
          </Grid>
          <Grid item xs={12}>
            <SubEntityList
              title={Helper.getString('vaiantsTitle')}
              tableHeadersMap={this.variantsTableHeadersMap}
              items={variants}
              subEntityActions={this.variantSubEntityActions.bind(this)}
              noAdd={this.catalogParams.includes('noVariants')}
              openModalForEdit={(current) => this.openModalForSE({ current, entityAttrName: 'variants', entityAction: 'edit' })}
              openModalForDelete={(current) => this.openModalForSE({ current, entityAttrName: 'variants', entityAction: 'del' })}
              openModalForAdd={(current) => this.openModalForSE({ current, entityAttrName: 'variants', entityAction: 'add' })}
            />
          </Grid>
          {!this.catalogParams.includes('noVariants') &&
            <Grid item xs={12} sm={6}>
              <SubEntityList
                title={Helper.getString('vaiantsOptionsTitle')}
                tableHeadersMap={this.variantsOptionsTableHeadersMap}
                items={variantOptions}
                openModalForEdit={(current) => this.openModalForSE({ current, entityAttrName: 'variantOptions', entityAction: 'edit' })}
                openModalForDelete={(current) => this.openModalForSE({ current, entityAttrName: 'variantOptions', entityAction: 'del' })}
                openModalForAdd={(current) => this.openModalForSE({ current, entityAttrName: 'variantOptions', entityAction: 'add' })}
              />
            </Grid>
          }
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'SEO'} formDefinition={this.fieldDefSEO}
              item={this.state.item}
              openModalForEdit={(current) => this.openModalForEdit(current, this.fieldDefSEO)}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Specifications'} formDefinition={this.fieldDefParams}
              item={this.state.item.params || {}}
              openModalForEdit={(current) => this.openModalForParamsEdit(current, this.fieldDefParams)}
            />
          </Grid> */}
        </Grid>
        {this.state.imageModelOpen &&
          <MultiImageUploaderDialog
            title={Helper.getString('imageUploaderTitle')}
            formDefinition={this.fieldDefImages}
            uploadLocation={this.orgId}
            handleUploadSuccess={this.handleUploadSuccess}
            imageModelOpen={this.state.imageModelOpen}
            closeImageUploader={this.closeImageUploader}
            apiSpecForImageUploader={this.apiSpecForImageUploader}
          />
        }
      </div>
    );
  }

  variantSubEntityActions = (current) => {
    const actions = []
    actions.push(
      <Tooltip title='Edit' placement='top-start' key={current.id + 'editTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => this.openModalForSE({ current, entityAttrName: 'variants', entityAction: 'edit' })}>edit</i>
        </span>
      </Tooltip>
    )
    if (!this.catalogParams.includes('noVariants')) {
      actions.push(
        <Tooltip title='Delete' placement='top-start' key={current.id + 'deleteTT'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => this.openModalForSE({ current, entityAttrName: 'variants', entityAction: 'del' })}>delete</i>
          </span>
        </Tooltip>
      )
    }

    actions.push(
      <Tooltip title='Images' placement='top-start' key={current.id + 'uploadTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => this.openModalForSE({ current, entityAttrName: 'variants', entityAction: 'edit', type: 'uploadImage' })}>insert_photo</i>
        </span>
      </Tooltip>
    )
    if (this.fieldDefProdVariantSpecsCustom && this.fieldDefProdVariantSpecsCustom.steps[0].fields.length > 0) {
      actions.push(
        <Tooltip title={Helper.getString('variantSpecs')} placement='top-start' key={current.id + 'Specss'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => this.openModalForSE({ current, entityAttrName: 'variants', entityAction: 'edit', type: 'variantSpecsCustom' })}>line_style</i>
          </span>
        </Tooltip>
      )
    }
    return actions
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: {},
      apiEndPoint: ApiHelper.makeUrlPath(['products', current.id]),
    });
  }

  openModalForSE(options) {
    const { current, entityAttrName, entityAction } = options
    if (entityAttrName === 'images') {
      this.formDefinition = this.fieldDefImages
    } else if (entityAttrName === 'docs') {
      this.formDefinition = this.fieldDefDocs
    } else if (entityAttrName === 'variants') {
      this.formDefinition = this.fieldDefVariants
      if (options.type === 'variantSpecsCustom') {
        this.formDefinition = this.fieldDefProdVariantSpecsCustom
      }
    } else if (entityAttrName === 'variantOptions') {
      if (this.state.item.state !== 'DISABLED') {
        this.showMessage(Helper.getString('productEditWhileLiveErr'))
        return
      }
      this.formDefinition = this.fieldDefVariantOptions
    }
    this.formDefinition.formType = entityAction
    if (current) {
      Helper.populateFormDefinitionValues(this.formDefinition, current)
    }

    if (entityAttrName === 'variants' && options.type === 'uploadImage') {
      const apiData = { entityAction, entityAttrName, id: current ? current.id : undefined, images: current.images }
      this.apiSpecForImageUploader = {
        apiMethod: 'PUT',
        apiData: apiData,
        apiEndPoint: ApiHelper.makeUrlPath(['products', this.prodId]),
      }
      this.setState({
        imageModelOpen: true,
        currentItem: current,
      })
    } else {
      this.setState({
        apiMethod: 'PUT',
        apiData: { entityAction, entityAttrName, id: current ? current.id : undefined },
        apiEndPoint: ApiHelper.makeUrlPath(['products', this.prodId]),
        modalOpen: true
      })
    }
  }

  // openModalForParamsEdit(current, fieldDef) {
  //   this.formDefinition = fieldDef
  //   this.formDefinition.formType = 'edit'
  //   Helper.populateFormDefinitionValues(this.formDefinition, current || {})
  //   this.setState({
  //     apiMethod: 'PUT',
  //     apiData: { updateAction: 'setParams' },
  //     apiEndPoint: ApiHelper.makeUrlPath(['products', this.prodId], {}),
  //     modalOpen: true
  //   })
  // }

  //   makeParamsFormDef(item) {
  //     const params = []
  //     if (!item.catIds) {
  //       item.catIds = []
  //     }
  //     const paramTitles = {}
  //     item.catIds.forEach(i => {
  //       const cat = this.catsById[i]
  //       for (const pId in cat.params) {
  //         const param = cat.params[pId]

  //         if (!param.title) continue
  //         const title = param.title.toLowerCase()
  //         if (title in paramTitles) continue // Allow only one name

  //         paramTitles[title] = param
  //         params.push(param)
  //       }
  //     })

  //     const fields = []
  //     let index = 0
  //     params.forEach(i => {
  //       const field = {
  //         key: 'key_' + i.id,
  //         type: 'edit_text',
  //         label: i.title,
  //         hint: 'Provide ' + i.title,
  //         v_required: { value: false, err: 'Provide ' + i.title }, // eslint-disable-line camelcase
  //         placement: index % 2 === 0 ? 'left' : 'right'
  //       }
  //       if (i.paramType === 'TEXT-SHORT') {
  //         //
  //       } else if(i.paramType === 'TEXT-PARAGRAPH') {
  //         field.multiline = true
  //       }
  //       index += 1
  //       fields.push(field)
  //     })
  //     return { steps: [ { fields: fields } ]}
  //   }
}

const Styles = StyleSheet.create({
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
  container: {
    textAlign: 'center',
    maxWidth: AppTheme.viewPort,
    marginBottom: 20,
    width: '100%',
  }
})