import Embed from '@editorjs/embed'
import Image from '@editorjs/image'
import Quote from '@editorjs/quote'
import Table from '@editorjs/table'
import LinkTool from '@editorjs/link'
import SimpleImage from '@editorjs/simple-image'
import { UploadHelper } from '../../helpers/UploadHelper'
//import Warning from '@editorjs/warning'
//import Code from '@editorjs/code'
//import Raw from '@editorjs/raw'
import Marker from '@editorjs/marker'
//import CheckList from '@editorjs/checklist'
import { AIHeader, AIList, AIParagraph } from './AITools';
import YoutubeEmbed from '../../../components/RichContentEditor/YoutubeEmbed'
//import Delimiter from '@editorjs/delimiter'
//import InlineCode from '@editorjs/inline-code'

class ImageGif extends Image {
  static get toolbox() {
    return {
      title: 'Image/Gif',
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150.242V79c0-18.778-15.222-34-34-34H79c-18.778 0-34 15.222-34 34v42.264l67.179-44.192 80.398 71.614 56.686-29.14L291 150.242zm-.345 51.622l-42.3-30.246-56.3 29.884-80.773-66.925L45 174.187V197c0 18.778 15.222 34 34 34h178c17.126 0 31.295-12.663 33.655-29.136zM79 0h178c43.63 0 79 35.37 79 79v118c0 43.63-35.37 79-79 79H79c-43.63 0-79-35.37-79-79V79C0 35.37 35.37 0 79 0z"></path></svg>'
    }
  }
}

export const EDITOR_JS_TOOLS = {
  youtubeEmbed: YoutubeEmbed,
  // embed: Embed,
  list: {
    class: AIList,
    inlineToolbar: true,
    config: {},
  },
  linkTool: LinkTool,
  header: {
    class: AIHeader,
    inlineToolbar: true,
    config: {
      levels: [2, 3, 4, 5, 6],
      defaultLevel: 2
    }
  },
  image: {
    class: ImageGif,
    config: {
      captionPlaceholder: 'Alternate text for image',
      buttonContent: 'Select Image/Gif',
      uploader: {
        uploadByFile(file) {
          const type = file.type === 'image/gif' ? 'gif' : 'jpg'
          const options = {
            type: 'media',
            ext: type
          }
          return new Promise(function (resolve, reject) {
            UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo) => {
              if (err) {
                return console.log('error', err)
              }
              const uploadOptions = {
                pickerResult: file,
                signedUploadInfo: signedUploadInfo,
                onEvent: (type, percent) => {
                  if (type === 'progress') {
                    console.log('in progress', percent)
                  } else if (type === 'load') {
                    resolve(signedUploadInfo)
                  } else if (type === 'error') {
                    console.log('some error')
                  }
                }
              }
              UploadHelper.uploadFormDataWithProgress(uploadOptions)
            })
          }).then((signedUploadInfo) => {
            return {
              success: 1,
              file: {
                url: signedUploadInfo.link,
              }
            }
          })
        }
      }
    }
  },
  paragraph: {
    class: AIParagraph,
    config: {
      placeholder: 'Let`s write an awesome story!',
    }
  },
  //table: Table,
  //raw: Raw,
  quote: {
    class: Quote,
    inlineToolbar: true,
  },
  marker: Marker,
  //checklist: CheckList,
  //delimiter: Delimiter,
  //inlineCode: InlineCode,
  //warning: Warning,
  //code: Code,
}