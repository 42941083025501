export class MockHelper {

  call(options, callback) {
    setTimeout(() => {
      return this._call(options, callback)
    }, 100)
  }

  hasMock(options) {
    const Url = require('url-parse')
    const url = new Url(options.endPoint)
    let data
    // if(url.pathname.endsWith('/miscs/cats')) {
    //   data = testdata.categories
    // } else if(url.pathname.endsWith('/users/me')) {
    //   data = testdata.me
    // } else if(url.pathname.includes('/categories/')) {
    //   data = testdata.categories.items[0]
    // } else if(url.pathname.includes('/products/')) {
    //   data = testdata.products.items[0]
    // } else if(url.pathname.includes('/miscs/products')) {
    //   data = testdata.products.items[0]
    // } else if(url.pathname.includes('/miscs/categories')) {
    //   data = testdata.categories.items[0]
    // } else if(url.pathname.endsWith('/miscs/homeelements')) {
    //   data = testdata.homeelements
    // } else if(url.pathname.endsWith('/miscs/homelists')) {
    //   data = testdata.homelists
    // }
    return data
  }

  _call(options, callback) {
    const data = this.hasMock(options)
    if(!data) return callback({ message: 'mock not found!' })
    console.log('mock:', data)
    return callback(null, data)
  }

}