import { Helper } from '../common/helpers/Helper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { UiHelper } from '../common/helpers/UiHelper';

export class AppUiHelper {

  static async limitEmailImages(fieldDef) {
    fieldDef.steps[0].fields.forEach(i => {
      if(i.imageUpload) {
        i.uploadAccept = 'image/png, image/jpeg, image/gif' // allow only these for emails
      }
    })
  }

  static async handleSingletonWidgetData(caller, current) {
    console.log('onSingletonWidgetData:current:', current)
    let response
    try {
      // TODO: Ideally show a progress here; which should display the error if any
      // Autoclose the progress on success
      response = await ApiHelper.callAwait({
        method: 'GET',
        paths: ['cbdats'],
        queryParams: { cbId: current._cbScopeId, pb: 'fieldDef' }
      })
    } catch (e) {
      console.error('onSingletonWidgetData:error:', e)
      return
    }
    console.log('onSingletonWidgetData:response:', response)
    UiHelper.populateOptions(response.fieldDef, caller.coTypes, 'customObject')
    UiHelper.populateOptions(response.fieldDef, caller.forms, 'form')
    // console.log('onSingletonWidgetData:fieldDef:', response.fieldDef)
    response.fieldDef.steps[0].fields =
      response.fieldDef.steps[0].fields.filter(i => !Helper.inArray(['order','state'], i.key))

    if(caller.page && caller.page.coTypeId && caller.entityAttrName === 'cotypeblocks') {
      response.fieldDef.steps[0].fields.forEach(field => {
        if(field.subType === 'image' || field.imageUpload) {
          //make it a text entry for allowing replacement tags
          delete field.subType
          delete field.imageUpload
        }
      })
    }
    console.log('onSingletonWidgetData:fieldDef:', response.fieldDef)
    caller.formDefinition = response.fieldDef
    if(response.items.length <= 0) {
      caller.formDefinition.formType = 'add'
      Helper.deleteFormDefinitionValues(caller.formDefinition)
      caller.setState({
        apiMethod: 'POST',
        apiData: {
          cbId: current._cbScopeId,
          state: 'LIVE' // only one object and it should be live
        },
        apiEndPoint: ApiHelper.makeUrlPath(['cbdats'], {})
      })
    } else {
      caller.formDefinition.formType = 'edit'
      Helper.populateFormDefinitionValues(caller.formDefinition, response.items[0])
      caller.setState({
        apiMethod: 'PUT',
        state: 'LIVE', // only one object and it should be live
        apiEndPoint: ApiHelper.makeUrlPath(['cbdats', response.items[0].id], {})
      });
    }
    caller.setState({
      modalOpen: true
    })
  }

  static async handleEmailSingletonWidgetData(caller, current) {
    console.log('handleEmailSingletonWidgetData:current:', current)
    let response
    try {
      // TODO: Ideally show a progress here; which should display the error if any
      // Autoclose the progress on success
      response = await ApiHelper.callAwait({
        method: 'GET',
        paths: ['emailcbdats'],
        queryParams: { cbId: current._cbScopeId, pb: 'fieldDef' }
      })
    } catch (e) {
      console.error('onSingletonWidgetData:error:', e)
      return
    }
    console.log('onSingletonWidgetData:response:', response)
    // console.log('onSingletonWidgetData:fieldDef:', response.fieldDef)
    response.fieldDef.steps[0].fields =
      response.fieldDef.steps[0].fields.filter(i => !Helper.inArray(['order','state', 'navLinkMode'], i.key))
    AppUiHelper.limitEmailImages(response.fieldDef)

    console.log('onSingletonWidgetData:fieldDef:', response.fieldDef)
    caller.formDefinition = response.fieldDef
    if(response.items.length <= 0) {
      caller.formDefinition.formType = 'add'
      Helper.deleteFormDefinitionValues(caller.formDefinition)
      caller.setState({
        apiMethod: 'POST',
        apiData: {
          cbId: current._cbScopeId,
          state: 'LIVE' // only one object and it should be live
        },
        apiEndPoint: ApiHelper.makeUrlPath(['emailcbdats'], {})
      })
    } else {
      caller.formDefinition.formType = 'edit'
      Helper.populateFormDefinitionValues(caller.formDefinition, response.items[0])
      caller.setState({
        apiMethod: 'PUT',
        state: 'LIVE', // only one object and it should be live
        apiEndPoint: ApiHelper.makeUrlPath(['emailcbdats', response.items[0].id], {})
      });
    }
    caller.setState({
      modalOpen: true
    })
  }

}
