import React, { Component } from 'react';

import { ApiHelper } from '../../helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';

export class CatsL2 extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('level2')
    this.pageKey = 'catsL2'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.idl1 = this.parentId = this.props.match.params.idl1
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('categories'), to: this.baseRoute() + '/cats' },
      { title: Helper.getString('level2') },
    ]
    this.toFetch = [
      {
        method: 'GET', paths: ['cats'],
        queryParams: { orgId: this.orgId, parentId: this.parentId, pb: 'fieldDef,fieldDefSEO', limit: 200 }
      },
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['cats', this.parentId], { orgId: this.orgId }) },
    ]
    this.tableHeadersMap = {
      'Slug / ID': (current) => {
        return UiHelper.itemDetails(current)
      },
      Title: (current) => current.title,
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId,
      parentId: this.parentId
    }
  }

  onFetchSuccess(results) {
    this.fieldDefSEO = results[0].fieldDefSEO
    this.fieldDef = results[0].fieldDef
    this.pageTitle = results[1].title
    const fields = []
    for (const field of this.fieldDef.steps[0].fields) {
      if (field.key !== 'parentId') {
        fields.push(field)
      }
    }
    this.fieldDef.steps[0].fields = fields
    this.handleNextpage(results[0])
    this.setState({
      items: Helper.sortDictArray(this.state.items.concat(results[0].items), 'title')
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['cats'], { orgId: this.orgId }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['cats', current.id], { orgId: this.orgId }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['cats', current.id], { orgId: this.orgId }),
    });
  }

  openModalForAdd() {
    const fields = []
    this.fieldDef.steps[0].fields.forEach(i => { if (i.key !== 'slug') fields.push(i) })
    this.fieldDef = { steps: [{ fields }] }
    super.openModalForAdd(this.fieldDef)
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonSEO(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    actions.push(UiHelper.buttonOpenItemsInCat(this, current))
    actions.push(
      <Link to={this.baseRoute() + '/cats/' + this.parentId + '/' + current.id} key={current.id + '-cats'} >
        <Tooltip title='Drill Down' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>subdirectory_arrow_right</i>
          </span>
        </Tooltip>
      </Link>
    )
    // actions.push(
    //   <Link to={'/admin/orgs/' + this.orgId + '/cats/' + current.id + '/products'} key={current.id + '-prods'}>
    //     <Tooltip title='Products' placement='top-start'>
    //       <span className={css(AppStyles.entityTableLinkIcon)}>
    //         <i className='material-icons-outlined'>layers</i>
    //       </span>
    //     </Tooltip>
    //   </Link>
    // )
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})