import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { BaseEntityPage } from '../../common/components/BaseEntityPage';
import { UiHelper } from '../../common/helpers/UiHelper';
import { CustomCbDefFieldsStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { AppHelper } from '../../helpers/AppHelper';

export class FooternavsL2 extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('footernavl2')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.parentId = this.props.match.params.id
    this.groupId = 'footernav' + this.orgId
    this.itemId = this.groupId + '-all'
    this.entityAttrName = 'items'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('footernav'), to: this.baseRoute() + '/footernavs' },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['items', this.itemId], queryParams: { groupId: this.groupId, pb: 'fieldDefFooternavItem' } },
    ]
    this.tableHeadersMap = {
      Order: (current) => current.order ,
      Title: (current) => current.title,
      Link: (current) => current.link,
      Status: (current) => UiHelper.itemStatusContainer(current)
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
    this.pageKey = 'footernav'
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDefFooternavItem
    const items = Helper.dictToArray(results[0].items)
    this.pageTitle = results[0].items[this.parentId].title
    this.setState({
      items: Helper.sortDictArray(items.filter(i => i.parentId === this.parentId))
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName, parentId: this.parentId },
      apiEndPoint: ApiHelper.makeUrlPath(['items', this.itemId], { groupId: this.groupId }),
    })
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['items', this.itemId], { groupId: this.groupId }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['items', this.itemId], { groupId: this.groupId }),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDefFieldsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})