/* eslint-disable camelcase */
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { AppStyles } from '../../../styles/AppTheme';
import { Controller } from '../../../common/Controller';

export class CrmInboxes extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('crm-inboxes')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.staffUserId = Controller.get().getUserId()
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('crm-inboxes') },
    ]
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['crminboxs'], queryParams: { orgId: this.orgId, orgIdUserId: `${this.orgId}-${this.staffUserId}`, pb: 'fieldDef', limit: 100 } },
    ]
    this.apiData = {
      orgId: this.orgId,
      userId: this.staffUserId,
    }
    this.pageKey = 'crm-inboxes'
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Email: (current) => <><span>{current.email}</span>{current.emailProvider && <span className={css(Styles.line2)}> [{current.emailProvider}]</span>}</>,
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
  }

  onFetchSuccess(results) {
    this.crmInboxes = results[0].items || []
    this.fieldDef = this.formDefinition = results[0].fieldDef
    this.setState({
      items: this.crmInboxes,
    })
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['crminboxs'], queryParams: { orgId: this.orgId } }),
    })
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['crminboxs', current.id], queryParams: { orgId: this.orgId } }),
    })
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['crminboxs', current.id], queryParams: { orgId: this.orgId } }),
    })
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(<Tooltip title={'Resync'} placement='top-start' key={current.id + 'resync'}>
      <span className={css(AppStyles.entityTableLinkIcon)}>
        <i className='material-icons-outlined' onClick={() => this.openModalForResync(current)}>refresh</i>
      </span>
    </Tooltip>)
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }

  openModalForResync(current) {
    this.formDefinition = {
      steps: [{
        fields: [
          {
            key: 'inboxId',
            type: 'radio',
            label: 'Email Inbox',
            options: [{key: current.id, label: current.email}],
            v_required: { value: true, err: 'Provide email inbox' },
            placement: 'left',
            //defaultValue: current.id,
          },
          {
            key: 'syncStartTime',
            type: 'dateTime',
            label: 'Resync From',
            hint: 'Provide Resync Start Time',
            tip: 'The date and time from which the inbox should be resynced',
            v_required: { value: false },
            placement: 'right'
          },
        ]
      }],
      disableAlwaysFormFields: ['inboxId'],
      formType: 'edit',
      title: 'Resync',
    }
    Helper.populateFormDefinitionValues(this.formDefinition, {inboxId: current.id})
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({
        ms: AppConfig.CDP_MS.ENDPOINT,
        paths: ['miscs', 'resync-inbox'],
        queryParams: { orgId: this.orgId, inboxId: current.id },
      }),
      modalOpen: true,
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 14
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
})