import { ApiHelper } from '../helpers/ApiHelper';
import { Helper } from '../helpers/Helper';
import { AppHelper } from '../../helpers/AppHelper';
import { BaseEntityPage } from './BaseEntityPage';
import { Controller } from '../Controller';
import { UiHelper } from '../helpers/UiHelper';

export class UsersOfOrg extends BaseEntityPage {

  constructor(props) {
    super(props)
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.pageTitle = this.orgId === 'PRODUCT' ?
      Helper.getString('productusers') : Helper.getString('users')
    this.pageKey = 'usersOfOrg'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], { orgId: this.orgId, pb: 'fieldDef' }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      'Email/Phone': (current) => { return current.email || current.phone },
      Roles: (current) => { return current.roles.join(', ') },
      Name: (current) => { return Helper.formatName(current) },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.emptyText = 'No users in this org yet!'
    this.apiData = {}
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onFetchSuccess(results) {
    this.fieldDef = results[0].fieldDef
    if(this.orgId === 'PRODUCT') {
      this.fieldDef.steps[0].fields.find(i => i.key === 'roles').options = [
        {key: 'SUPERUSER', label: 'SUPERUSER'},
      ]
    } else {
      this.fieldDef.steps[0].fields.find(i => i.key === 'roles').options = [
        {key: 'SUPERUSER', label: 'SUPERUSER'},
        {key: 'BLOGGER', label: 'BLOGGER'},
        {key: 'CRM', label: 'CRM'}
      ]
    }
    this.formDefinition = this.fieldDef
    results[0].items.forEach(i => i.id = i.userId)
    this.setState({
      items: results[0].items
    })
  }

  onAdd() {
    this.setState({
      apiMethod: 'PUT',
      apiData: {
        updateAction: 'addMembers',
      },
      apiEndPoint: ApiHelper.makeUrlPath(['orgs', this.orgId]),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: {
        updateAction: 'delMembers',
      },
      apiEndPoint: ApiHelper.makeUrlPath(['orgs', this.orgId]),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: {
        updateAction: 'addMembers',
      },
      apiEndPoint: ApiHelper.makeUrlPath(['orgs', this.orgId]),
    });
  }

  onActions(current) {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }

}