import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../helpers/ApiHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../../components/BaseEntityPage';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { AppConfig } from '../../../AppConfig';
import { AppHelper } from '../../../helpers/AppHelper';
import { CustomOrdersStyles } from '../../../styles/CustomStyles';
import { UiHelper } from '../../helpers/UiHelper';

export class Orders extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('orders')
    this.pageKey = 'orders'
    this.orgId = this.props.match.params.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs', 'order-history-org'],
        { orgId: this.orgId, state: 'PAID', limit: 300, pb: 'fieldDefOrderActions' }) },
    ]
    this.noAdd = true
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.id}</span>
            {current.pgOrderId &&
              <Fragment>
                <br />
                <span className={css(Styles.spanValue)}>{current.pgOrderId}</span>
              </Fragment>
            }
            <br />
            <span className={css(Styles.spanValue)}>{Helper.formatTimestamp(current.createdTs)}</span>
          </div>
        )
      },
      Status: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.state}</span>
            {current._estatus &&
              <Fragment>
                <br />
                <span className={css(Styles.spanValue)}>{'eStatus: ' + current._estatus}</span>
              </Fragment>
            }
          </div>
        )
      },
      Cart: (current) => {
        const calculation = current.cartInfo.calculation
        return (
          <div>
            {/* {current.items && current.items.map(item => {
              return (
                <div>
                  <span className={css(Styles.spanValue)}>{item.id} | {item.title}</span>
                  <br />
                  <span className={css(Styles.spanValue)}>Quantiy: {item.quantity}</span>
                  <br />
                  <span className={css(Styles.spanValue)}>Variant: {this._getVariantInfo(item)}</span>
                  <Divider className={css(Styles.divider)} />
                </div>
              )
            })} */}
            <span className={css(Styles.spanValue)}>Total: {Helper.formatCurrency(calculation.total, calculation.currency)}</span>
            <br />
            <span className={css(Styles.spanValue)}>Paid: {Helper.formatCurrency(calculation.amountToPay, calculation.currency)}</span>
          </div>
        )
      },
      Customer: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>#{current.payerId}</span>
            <br />
            {current.userInfo &&
              <div>
                {current.userInfo.name &&
                  <span className={css(Styles.spanValue)}>{current.userInfo.name}</span>
                }
                {current.userInfo.name && current.userInfo.phone &&
                  <span className={css(Styles.spanValue)}> | </span>
                }
                {current.userInfo.phone &&
                  <span className={css(Styles.spanValue)}>{current.userInfo.phone}</span>
                }
              </div>
            }
            {current.userInfo && current.userInfo.address &&
              <div>
                <span className={css(Styles.spanValue)}>Address</span>
                <br />
                <span className={css(Styles.spanValue)}>{current.userInfo.address.line1}, {current.userInfo.address.line2}</span>
                <br />
                <span className={css(Styles.spanValue)}>{current.userInfo.address.city}, {current.userInfo.address.state}</span>
                <br />
                <span className={css(Styles.spanValue)}>{current.userInfo.address.PIN}, {current.userInfo.address.country}</span>
              </div>
            }
          </div>
        )
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
    }
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDefOrderActions;
    this.setState({
      items: results[0].items,
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'POST',
      apiData: { updationPathId: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['miscs', 'order-org'], { orgId: this.orgId }),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    return actions
  }

  _getVariantInfo(item) {
    if (!item || !item.variants) return

    const variantId = (item.id).split('-')[1]
    const variantOptionId = Object.keys(item.variantOptions)[0]

    return item.variants[variantId]['variantOption' + variantOptionId] + ' | Amount: ' + Helper.formatCurrency(item.variants[variantId].price)
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomOrdersStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  }
})