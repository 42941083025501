import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { css } from 'aphrodite';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { AppStyles } from '../../../styles/AppTheme';

export class ContentType extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CONTENT_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('content-fragments')
    this.pageId = this.props.match.params.id
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('content-type') },
    ]
    this.toFetch = [
      {
        method: 'GET',
        paths: ['contents', 'PRODUCT'],
        queryParams: { groupId: this.orgId, pb: 'fieldDefContentFragmentsEntity', limit: 100 },
        ms: this.ms,
      },
    ]
    this.pageKey = 'content-type'
    this.tableHeadersMap = {
      Name: (current) => UiHelper.itemTitleId(current),
      Prompt: (current) => current.prompt,
      Key: (current) => current.key,
      Tag: (current) => current.tag,
      Type: (current) => current.type
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.entityAttrName = 'fragments';
  }

  onFetchSuccess(results) {
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('content-types'), to: `${this.baseRoute()}/content/types` },
      { title: results[0].types[this.pageId].title },
    ]

    this.contentType = Object.values(results[0].types).find(t => t.id === this.pageId);
    this.fragments = this.contentType.fragments.length > 0 ? this.contentType.fragments.map(f => results[0].fragments[f]) : [];

    const fieldDefContentFragmentsEntity = JSON.parse(JSON.stringify((results[0].fieldDefContentFragmentsEntity)));
    this.fieldDef = this.formDefinition = fieldDefContentFragmentsEntity;

    this.setState({
      items: this.fragments,
    })
  }

  onAdd() {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName, contentTypeId: this.pageId },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', 'PRODUCT'], queryParams: {} }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', 'PRODUCT'], queryParams: {} }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName, id: current.id, contentTypeId: this.pageId },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', 'PRODUCT'], queryParams: {} }),
    });
  }

  customSubmit(options) {
    const { formType, formData } = options
    console.log('customSubmit:', formData)

    ApiHelper.call({
      method: this.state.apiMethod,
      endPoint: this.state.apiEndPoint,
      jsonBody: Object.assign({}, this.state.apiData, formData),
    },
    async (err, data) => {
      if (err) {
        const errorMessage = Helper.getErrorMsg(err)
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
        return
      } else {
        if (['add', 'delete'].includes(formType)) {
          let fragments = [...this.contentType.fragments];
          if (formType === 'add') {
            fragments.push(data.id);
          } else if (formType === 'delete') {
            fragments = fragments.filter(f => f !== formData.id);
          }
          await ApiHelper.callAwait({
            method: 'PUT',
            endPoint: this.state.apiEndPoint,
            jsonBody: {
              entityAction: 'edit',
              entityAttrName: 'types',
              id: formData.contentTypeId,
              fragments,
            },
          });
        }
        this.setState({ modalOpen: false });
        this.fetchItems();
      }
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []

    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))

    return actions
  }
}
