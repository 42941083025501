import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { BaseEntityPage } from '../../common/components/BaseEntityPage';
import { UiHelper } from '../../common/helpers/UiHelper';
import { AppConfig } from '../../AppConfig';
import { CustomAssetsStyles } from '../../styles/CustomStyles';
import { AppTheme } from '../../styles/AppTheme';
import { ImageUploaderWithAssetPicker } from '../../components/ImageUploaderWithAssetPicker';

export class Assets extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.pageTitle = options.pageTitle || Helper.getString('assets')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('assets') },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['assets'], queryParams: { orgId: this.orgId, pb: 'fieldDef', limit: 1000 } },
    ]
    this.apiData = {
      orgId: this.orgId
    }
    this.pageKey = 'assets'
    this.cardViewMap = {
      top: (current) => {
        return (
          <div className={css(Styles.imageOuterContainer)}>
            <div className={css(Styles.imageContainer)}>
              <img
                alt={Helper.getString('assetImageAlt')}
                width={512}
                height={512}
                src={current.image}
                className={css(Styles.img)}
              />
            </div>
          </div>
        )
      },
      bottom: (current) => {
        return (
          <div className={css(Styles.titlesContainer)}>
            <p className={css(Styles.itemTitle)}>{current.title}</p>
          </div>
        )
      },
    }
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDef
    this.setState({
      items: results[0].items
    })
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['assets'], {}),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['assets', current.id], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['assets', current.id], {}),
    });
  }

  onCardValue(current, key) {
    if (!this.cardViewMap[key]) return ''
    return this.cardViewMap[key](current)
  }

  onMenuItems = (current) => {
    const actions = []
    const menuItems = [
      {
        current: current,
        key: current.id + 'editTT',
        action: () => {
          const fieldDef =  {...this.formDefinition}
          fieldDef.steps[0].fields.find(i => i.key === 'imageName').disabled = true
          this.openModalForEdit(current, fieldDef)
        },
        title: Helper.getString('edit'),
        icon:'edit',
      },
      {
        current: current,
        key: current.id + 'deleteTT',
        action: () => this.openModalForDelete(current),
        title: Helper.getString('delete'),
        icon:'delete'
      },
    ]
    menuItems.map(item => {
      actions.push(UiHelper.actionButton(item))
    })
    return actions
  }

  onActions = (current) => {
    const actions = []
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomAssetsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  },
  imageOuterContainer: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    display: 'block',
    overflow: 'hidden',
    inset: '0px',
    borderRadius: 8,
    boxSizing: 'border-box',
    margin: '0px',
    border: '1px solid ' + AppTheme.borderColor
  },
  img: {
    inset: '0px',
    position: 'absolute',
    boxSizing: 'border-box',
    padding: '0px',
    border: 'none',
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
    objectFit: 'containe'
  },
  titlesContainer: {
    flex: 1,
    display: 'grid'
  },
  itemTitle: {
    margin: 0,
    color: '#222222',
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 5,
    wordBreak: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    paddingTop: 0,
  },
})