import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { BaseEntityPage } from '../../common/components/BaseEntityPage';

export class Tester extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = 'Tester'
    this.orgId = this.props.match.params.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: 'Tester' },
    ]
    this.toFetch = [
      // { method: 'GET', endPoint: ApiHelper.makeUrlPath(['jobs'], { groupId: this.orgId, pb: 'fieldDefJob' }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      groupId: this.orgId
    }
    this.headerActions = [
      {
        label: 'Refresh',
        icon: 'refresh',
        onClick: () => this.onRefresh()
      }
    ]
  }

  onRefresh() {
    console.log('onRefresh')
    Helper.amplifyEvent({name: 'refresh', attributes: { attr1: 'now' }})
  }

  onFetchSuccess(results) {
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['jobs']),
    });
  }

  onActions(current) {
    const actions = []
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})