import React, { Component, Fragment } from 'react'
import { ApiHelper } from '../../helpers/ApiHelper';
import { css, StyleSheet } from 'aphrodite';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { SEStyles } from './SEStyles';
import { Grid, Card, CardContent, CardActionArea, Tooltip, Paper, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Helper } from '../../helpers/Helper';
import { AppHelper } from '../../../helpers/AppHelper';
import { CustomSEStyles } from '../../../styles/CustomStyles';
import { AppConfig } from '../../../AppConfig';
import { AppButton } from '../AppButton';
import { UiHelper } from '../../helpers/UiHelper';

export class SubEntityCard extends Component {
  constructor(props) {
    super(props)
    this.fields = this.props.formDefinition.steps[0].fields
  }

  render() {
    const fields = this.generateFields()
    return (
      <div className={css(Styles.container)}>
        <div className={css(Styles.heading)}>
          <div className={css(Styles.headingContent)}>
            <h2 className={css(Styles.stepFormTitle)}>{this.props.title}</h2>
            {!this.props.noEdit &&
              <AppButton
                onClick={() => this.props.openModalForEdit(this.props.item)}
                buttonText={'Edit'}
                buttonStyle={Styles.editButton}
                buttonTextStyle={Styles.buttonText} />
            }
          </div>
        </div>
        <div className={this.props.renderFieldsRowwise ? css([Styles.content, Styles.contentForRow]) : css(Styles.content)}>
          {fields.map((item, index) => {
            return (
              <Fragment>
                <div key={item.label} className={this.props.renderFieldsRowwise ? css([Styles.labelContainer, Styles.labelContainerForRow]) : css(Styles.labelContainer)}>
                  <p className={css(Styles.label)}>{item.label}</p>
                  {this.valueDisplay(item, fields)}
                </div>
                {this.props.renderFieldsRowwise && index % 2 === 0 && <div className={css(Styles.dividerForRow)} />}
              </Fragment>
            )
          })}
        </div>
      </div>
    )
  }

  valueDisplay(item, fields) {
    // const valueMultiDisplayStyle = [Styles.value, Styles.valueDisplayMultiline]
    // const valueDisplayStyle = fields.length === 1 ? [Styles.value, Styles.valueDisplayMultiline] : Styles.value
    const valueMultiDisplayStyle = [Styles.value]
    const valueDisplayStyle = [Styles.value]

    const value = item.valueDisplay || item.resolvedValue || item.value

    if (item.html) {
      return <div className='content'
        dangerouslySetInnerHTML={{ __html: value }} />
    }
    if (item.multiline) {
      return <div className={css(Styles.valueContainer)}><p className={css(valueMultiDisplayStyle)}>{value}</p></div>
    }
    if (item.subType === 'image' && item.link) {
      return <div className={css(Styles.imageContainer)}><img src={item.link} className={css(Styles.imageContent)} /></div>
    }
    if (item.subType === 'document' && item.link) {
      return <div className={css(Styles.valueContainer)}><span><a href={item.link} target='_blank' className={css([valueDisplayStyle, Styles.link, Styles.openFileText])}>{Helper.getString('openFile')}</a></span></div>
    }
    if (item.subType === 'colour') {
      return <div className={css(Styles.colourPickerContainer)} style={{ backgroundColor: value }}>{UiHelper.renderTransparentTileBycolorCode(value)}</div>
    }
    else {
      return <div className={css(Styles.valueContainer)}><p className={css(valueDisplayStyle)}>{value || '[Not set]'}</p></div>
    }

  }
  generateFields() {
    const fields = []
    for (const current of this.fields) {
      const field = {
        id: current.key,
        label: current.label,
        value: this.props.item[current.key],
      }
      if (field.value && current.valueDisplayScale) {
        field.value = (field.value / current.valueDisplayScale).toFixed(2)
      }
      if (current.html) {
        field.html = true
      }
      if (current.multiline) {
        field.multiline = true
      }
      if (current.imageUpload && current.linkName) { //retained for backward compatibility
        field.subType = 'image'
        field.link = this.props.item[current.linkName]
      }
      if (current.subType === 'image' && current.linkName) {
        field.subType = 'image'
        field.link = this.props.item[current.linkName]
      }
      if (current.documentUpload && current.linkName) { //retained for backward compatibility
        field.subType = 'document'
        field.link = this.props.item[current.linkName]
      }
      if (current.subType === 'document' && current.linkName) {
        field.subType = 'document'
        field.link = this.props.item[current.linkName]
      }
      if (current.colourPicker) { //retained for backward compatibility
        field.subType = 'colour'
      }
      if (current.subType === 'colour') {
        field.subType = 'colour'
      }
      switch (current.type) {
      case 'edit_text':
        break
      case 'rich_text':
        field.valueDisplay = 'YES'
        break
      case 'radio':
      case 'check_box':
      case 'select':
        console.log('radio/check_box/select:', field, current)
        if (field.value && current.options) {
          const optionsByKey = {}
          current.options.forEach(i => optionsByKey[i.key] = i)
          let valuesToResolve = field.value
          if (!Array.isArray(valuesToResolve)) {
            valuesToResolve = [valuesToResolve]
          }
          const resolvedValues = []
          valuesToResolve.forEach(i => optionsByKey[i] ? resolvedValues.push(optionsByKey[i].text || optionsByKey[i].label) : 'UNK')
          field.resolvedValue = resolvedValues.join(', ')
        }
        break
      default:
        continue
      }
      if (!current.placement) {
        current.placement = 'left' // default
      }
      if (!(current.key in this.props.item)) {
        continue
      }
      if (current.placement === 'left') {
        fields.push(field)
      } else if (current.placement === 'right') {
        fields.push(field)
      }
    }
    return fields
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomSEStyles : SEStyles
