export class PageAnalytics {
  constructor() { }

  static gaTrkEvent(cat, action, label, value) {
    if(typeof window === 'undefined') {
      return
    }
    if (!window.ga) {
      return;
    }
    if (!cat || !action) {
      return;
    }
    if (label && value) {
      window.ga('send', 'event', cat, action, label, value);
    } else if (label) {
      window.ga('send', 'event', cat, action, label);
    } else {
      window.ga('send', 'event', cat, action);
    }
  }

  static gaTrkTiming(cat, timingVar, value, label) {
    if(typeof window === 'undefined') {
      return
    }
    if (!window.ga) {
      return;
    }
    if (!cat || !timingVar || !value) {
      return;
    }
    if (label) {
      window.ga('send', 'timing', cat, timingVar, value, label);
    } else {
      window.ga('send', 'timing', cat, timingVar, value);
    }
  }

}
