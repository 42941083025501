import { countWords } from '../../../screens/admin/content/ContentUtils';

export const aggregation = (baseClass, ...mixins) => {
  class base extends baseClass {
    constructor (...args) {
      super(...args);
      mixins.forEach((mixin) => {
        copyProps(this,(new mixin(...args)));
      });
    }
  }
  const copyProps = (target, source) => {  // this function copies all properties and symbols, filtering out some special ones
    Object.getOwnPropertyNames(source)
      .concat(Object.getOwnPropertySymbols(source))
      .forEach((prop) => {
        if (!prop.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/))
          Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(source, prop));
      })
  }
  mixins.forEach((mixin) => { // outside contructor() to allow aggregation(A,B,C).staticFunction() to be called etc.
    copyProps(base.prototype, mixin.prototype);
    copyProps(base, mixin);
  });
  return base;
}

export function getBlockIndex(api, blockId) {
  const blocksCount = api.blocks.getBlocksCount();
  let i = 0;
  while (i < blocksCount) {
    const block = api.blocks.getBlockByIndex(i);
    if (block.id === blockId) {
      return i;
    }
    ++i;
  }
  return -1;
}

export function getBlockHeaderLevel(headerBlock) {
  const { blocks: extractedBlocks } = extractBlog(headerBlock.holder.innerHTML);
  if (extractedBlocks && extractedBlocks.length) {
    const headerBlock = extractedBlocks[0];
    if (headerBlock.type === 'header' && headerBlock.data && headerBlock.data.level) {
      return headerBlock.data.level;
    }
  }
  return 0;
}

export function blockToHtml(api, blockIndex) {
  const block = api.blocks.getBlockByIndex(blockIndex);
  if (block.name === 'header') {
    const level = getBlockHeaderLevel(block);
    return `<h${level}>${block.holder.innerText}</h${level}>`;
  } else if (block.name === 'paragraph') {
    return `<p>${block.holder.innerText}</p>`;
  } else if (block.name === 'list') {
    return block.holder.innerText.split('\n').filter(li => li !== '').map(li => `<li>${li}</li>`).join('\n');
  }
  return '';
}

export function getContentUptoIndex(api, blockIndex) {
  const blocksCount = api.blocks.getBlocksCount();
  let i = 0;
  const extractedContent = [];
  while (i <= blockIndex && i < blocksCount) {
    extractedContent.push(blockToHtml(api, i));
    ++i;
  }

  while (countWords(extractedContent.join(' ')) > 2000) {
    extractedContent.shift();
  }
  return extractedContent.join('\n');
}

export function getSectionHeader(api, blockIndex) {
  const blocksCount = api.blocks.getBlocksCount();
  let i = 0;
  let sectionHeader = '';
  let sectionHeaderLevel = 1;
  while (i <= blockIndex && i < blocksCount) {
    const block = api.blocks.getBlockByIndex(i);
    if (block.name === 'header') {
      sectionHeaderLevel = getBlockHeaderLevel(block);
      sectionHeader = block.holder.innerText;
    }
    ++i;
  }
  return { sectionHeader, sectionHeaderLevel };
}

export function extractBlog(htmlText) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlText, 'text/html');
  const h1 = doc.querySelector('h1');
  const title = h1 ? h1.textContent : '';

  const blocks = [];
  let listBlock = {};
  const textNodes = doc.querySelectorAll('h2, h3, h4, h5, h6, p, li');
  textNodes.forEach((node, i) => {
    if (node.tagName === 'LI') {
      if (listBlock.data && listBlock.data.items) {
        listBlock.data.items.push(node.textContent);
      } else {
        listBlock = { type: 'list', data: {style: 'unordered', items: [node.textContent]}};
      }
    }

    if (listBlock.data && listBlock.data.items && (node.tagName !== 'LI' || i === (textNodes.length - 1))) {
      blocks.push(listBlock);
      listBlock = {};
    }

    if (['H2', 'H3', 'H4', 'H5', 'H6'].includes(node.tagName)) {
      blocks.push({ type: 'header', data: {text: node.textContent, level: parseInt(node.tagName.charAt(1))}});
    } else if (node.tagName === 'P') {
      blocks.push({ type: 'paragraph', data: {text: node.textContent}});
    }
  });

  return { title, blocks };
}
