import React, { Component } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { emphasize } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { AppTheme } from '../../styles/AppTheme';
import { CustomBreadCrumbsStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';

let Styles = {}
export class BreadCrumbs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.breadCrumbs || this.props.breadCrumbs.length === 0) {
      return ''
    }

    return (
      <Breadcrumbs aria-label='Breadcrumb' className={css(Styles.breadCrumbsContainer)}>
        {this.props.breadCrumbs.map((item, index) => {
          const last = (index === this.props.breadCrumbs.length - 1);
          return (last || !('to' in item)) ?
            <Typography
              className={css(Styles.lastTextStyle)}
              key={index}>
              {item.title}
            </Typography>
            :
            <Link to={item.to} key={index} className={css(Styles.link)}>
              <Typography
                className={css(Styles.textStyle)}
                key={index}>
                {item.title}
              </Typography>
            </Link>
        })}
      </Breadcrumbs>
    );
  }

}

Styles = AppConfig.CUSTOM_STYLE ? CustomBreadCrumbsStyles : StyleSheet.create({
  textStyle: {
    //backgroundColor: AppTheme.primaryColor,
    //padding: '2px 12px',
    color: 'grey',
    fontSize: 18,
    textTransform: 'capitalize',
    borderRadius: 12,
    '&:hover, &:focus': {
      //backgroundColor: AppTheme.primaryColor,
      cursor: 'pointer'
    },
    '&:active': {
      //backgroundColor: emphasize(AppTheme.primaryColor),
    },
  },
  lastTextStyle: {
    color: 'grey',
    fontSize: 18,
    textTransform: 'capitalize'
  },
  link: {
    textDecoration: 'none'
  }
})
