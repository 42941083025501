import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { StyleSheet, css } from 'aphrodite';

import { Controller } from './common/Controller';
import { AppTheme, AppStyles } from './styles/AppTheme';

import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { NoPermission } from './common/components/NoPermission';

import { DashboardAdmin } from './screens/admin/dashboard/DashboardAdmin';
import { DashboardOrg } from './screens/admin/dashboard/DashboardOrg';

import { NotFound } from './screens/NotFound';
import { Terms } from './screens/Terms';
import { Privacy } from './screens/Privacy';
import { UiHelper } from './common/helpers/UiHelper';
import { Home } from './screens/Home';
import { PublicHeader } from './components/PublicHeader'
import { PublicFooter } from './components/PublicFooter'
import { LoginPage } from './screens/LoginPage';

import { AppConfig } from './AppConfig';
import { AppHelper } from './helpers/AppHelper';

import { UsersOfOrg } from './common/components/UsersOfOrg';
import { CatsL1 } from './common/components/catalog/CatsL1';
import { CatsL2 } from './common/components/catalog/CatsL2';
import { CatsL3 } from './common/components/catalog/CatsL3';
import { ProdLists } from './common/components/catalog/ProdLists';
import { ProductsByCat } from './common/components/catalog/ProductsByCat';
import { ProductsByTag } from './common/components/catalog/ProductsByTag';
import { ProductsByList } from './common/components/catalog/ProductsByList';
import { ProductsByBrand } from './common/components/catalog/ProductsByBrand';

import { Products } from './common/components/catalog/Products';
import { Brands } from './common/components/catalog/Brands';
import { Banners } from './common/components/catalog/Banners';
import { Orders } from './common/components/catalog/Orders';
import { Faqs } from './common/components/Faqs';

import { ProductCustom } from './screens/admin/ProductCustom';

import { Orgs } from './screens/admin/Orgs';
import { BlogTags } from './common/components/blog/BlogTags';
import { BlogsByTag } from './common/components/blog/BlogsByTag';
import { BlogLists } from './common/components/blog/BlogLists';
import { BlogsByList } from './common/components/blog/BlogsByList';
import { Blogs } from './common/components/blog/Blogs';
import { Blog } from './common/components/blog/Blog';
import { Testimonials } from './common/components/Testimonials';
import { Benefits } from './common/components/Benefits';
import { Settings } from './common/components/Settings';
import { FaqSections } from './common/components/FaqSections';
import { RichContentEditor } from './common/components/RichContentEditor/RichContentEditor';
import { SettingsMaster } from './common/components/SettingsMaster';
import { CbDefs } from './screens/admin/CbDefs';
import { CbDefFields } from './screens/admin/CbDefFields';
import { CbDats } from './screens/admin/CbDats';
import { CbDefsRead } from './screens/admin/CbDefsRead';
import { Pages } from './screens/admin/Pages';
import { Menus } from './screens/admin/Menus';
import { ItemPreview } from './common/components/Preview/ItemPreview';
import { PageCbs } from './screens/admin/PageCbs';
import { CbDefsGlobal } from './screens/admin/CbDefsGlobal';
import { Coupons } from './common/components/Coupons';
import { CbOrgs } from './screens/admin/CbOrgs';
import { CbDats2 } from './screens/admin/CbDats2';
import { SettingsBranding } from './common/components/SettingsBranding';
import { Footernavs } from './screens/admin/Footernavs';
import { MenusL2 } from './screens/admin/MenusL2';
import { Assets } from './screens/admin/Assets';
import { Jobs } from './screens/admin/Jobs';
import { FooternavsL2 } from './screens/admin/FooternavsL2';
import { CustomRichContentEditor } from './components/RichContentEditor/CustomRichContentEditor';
import { Tester } from './screens/admin/Tester';
import { AWSAmplifyHelper } from './common/helpers/AWSAmplifyHelper';
import { MenusL3 } from './screens/admin/MenusL3';
import { Forms } from './screens/admin/Forms';
import { FormFields } from './screens/admin/FormFields';
import { CoTypes } from './screens/admin/co/CoTypes';
import { CoFields } from './screens/admin/co/CoFields';
import { CoItems } from './screens/admin/co/CoItems';
import { PageCbsCoType } from './screens/admin/PageCbsCoType';
import { ContentTypes } from './screens/admin/content/ContentTypes';
import { ContentType } from './screens/admin/content/ContentType';
import { ContentPersonas } from './screens/admin/content/ContentPersonas';
import { ContentBlogs } from './screens/admin/content/ContentBlogs';
import { ContentGenerator } from './screens/admin/content/ContentGenerator';
import { ContentPlanner } from './screens/admin/content/ContentPlanner';
import { ContentCluster } from './screens/admin/content/ContentCluster';
import { CoTypesRead } from './screens/admin/co/CoTypesRead';
import { CoNestedItems } from './screens/admin/co/CoNestedItems';
import { NestedFields } from './screens/admin/co/NestedFields';
import { Carts } from './screens/admin/Carts';
import { EmbedTags } from './screens/admin/cdp/EmbedTags';
import { Ooh } from './screens/admin/cdp/Ooh';
import { Broadcasts } from './screens/admin/cdp/Broadcasts';
import { Transactional } from './screens/admin/cdp/Transactional';
import { Notifications } from './screens/admin/cdp/Notifications';
import { CrmSequences } from './screens/admin/cdp/CrmSequences';
import { CrmSequenceSteps } from './screens/admin/cdp/CrmSequenceSteps';
import { CrmSequenceEnrolments } from './screens/admin/cdp/CrmSequenceEnrolments';
import { CampaignResult } from './screens/admin/cdp/CampaignResult';
import { Segments } from './screens/admin/cdp/Segments';
import { EmailCbDefsGlobal } from './screens/admin/EmailCbDefsGlobal';
import { EmailCbDefFields } from './screens/admin/EmailCbDefFields';
import { EmailPages } from './screens/admin/EmailPages';
import { EmailPageCbs } from './screens/admin/EmailPageCbs';
import { EmailCbDats } from './screens/admin/EmailCbDats';
import { Journeys } from './screens/admin/cdp/Journeys';
import { JourneyResult } from './screens/admin/cdp/JourneyResult';
import { Popups } from './screens/admin/cdp/Popups';
import { PopupAnalytics } from './screens/admin/cdp/PopupAnalytics';
import { CrmSettings } from './screens/admin/cdp/CrmSettings';
import { CrmInboxes } from './screens/admin/cdp/CrmInboxes';
import { CrmEmailTemplates } from './screens/admin/cdp/CrmEmailTemplates';
import { CrmUserSettings } from './screens/admin/cdp/CrmUserSettings';
import { CrmContacts } from './screens/admin/cdp/CrmContacts';
import { CrmContacts2 } from './screens/admin/cdp/CrmContacts2';
import { CrmContact } from './screens/admin/cdp/CrmContact';
import { CbdefTags } from './screens/admin/CbdefTags';
import { CdpSettings } from './screens/admin/cdp/CdpSettings';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppTheme.primaryColor
    },
    secondary: {
      main: AppTheme.secondaryColor
    }
  },
  typography: {
    fontFamily: [
      AppConfig.GFONTNAME
    ].join(',')
  }
});

const PublicRoute = (props) => {
  if (Controller.get().loginMgr().getCurrentUser()) {
    if (props && props.path === '/login') {
      return <Redirect to={Controller.get().getHomeByRole()} />
    }
  }
  return <Route {...props} />
}

const ProtectedRoute = (props) => {
  if (Controller.get().loginMgr().getCurrentUser()) {
    if (props.userLoaded) {
      return <Route {...props} />
    }
    if (props.noPermission) {
      return <Redirect
        to={{
          pathname: '/no-permission',
          state: { redirectedfrom: props.path }
        }}
      />
    }
    Controller.get().loginMgr().bootstrap((err, data) => {
      if (err) {
        console.error('bootstrap err:', err)
        props.setNoPermission()
        return
      }

      if (props.path.startsWith('/admin/orgs')) {
        // Any path starts with /admin/orgs - users  has to have a role in that Org or a user of PRODUCT
        const orgId = props.computedMatch.params.orgId
        const orgBasePath = '/admin/orgs/:orgId'
        console.log('path:', props.path)
        console.log('orgBasePath:', orgBasePath)
        if (props.path === orgBasePath) {
          if (!Controller.get().userMgr().isProductSU() &&
            !Controller.get().userMgr().hasOrgRole(orgId, 'SUPERUSER') &&
            !Controller.get().userMgr().hasOrgRole(orgId, 'BLOGGER') &&
            !Controller.get().userMgr().hasOrgRole(orgId, 'CRM')
          ) {
            props.setNoPermission()
            return
          }
        } else if (props.path.startsWith(orgBasePath + '/blogs')) {
          if (!Controller.get().userMgr().isProductSU() &&
            !Controller.get().userMgr().hasOrgRole(orgId, 'SUPERUSER') &&
            !Controller.get().userMgr().hasOrgRole(orgId, 'BLOGGER')
          ) {
            props.setNoPermission()
            return
          }
        } else if (props.path.startsWith(orgBasePath + '/crm-contacts')) {
          if (!Controller.get().userMgr().isProductSU() &&
            !Controller.get().userMgr().hasOrgRole(orgId, 'SUPERUSER') &&
            !Controller.get().userMgr().hasOrgRole(orgId, 'CRM')
          ) {
            props.setNoPermission()
            return
          }
        } else if (
          props.path.startsWith(orgBasePath + '/settings-master')
          || props.path.startsWith(orgBasePath + '/cotypes')
          || props.path.startsWith(orgBasePath + '/cbdefs')
        ) {
          if (!Controller.get().userMgr().isProductSU() // only allow SU for now
          //&& !Controller.get().userMgr().hasOrgRole(orgId, 'SUPERUSER')
          ) {
            props.setNoPermission()
            return
          }
        } else {
          if (!Controller.get().userMgr().isProductSU() &&
            !Controller.get().userMgr().hasOrgRole(orgId, 'SUPERUSER')
          ) {
            props.setNoPermission()
            return
          }
        }
      } else if (props.path.startsWith('/admin')) {
        // Any path starts with Admin - users has to have a role in PRODUCT Org
        if (!Controller.get().userMgr().isInOrg('PRODUCT')) {
          props.setNoPermission()
          return
        }
      }
      props.setUserLoaded && props.setUserLoaded()
    })
    return UiHelper.loadingView()
  } else {
    return <Redirect
      to={{
        pathname: '/login',
        state: { redirectedfrom: props.path }
      }}
    />
  }
}

class App extends Component {

  constructor(props) {
    //console.log('App::constructor')
    super(props);
    this.state = {
      currentUser: Controller.get().loginMgr().getCurrentUser()
    };
    AWSAmplifyHelper.init(AppConfig.AWS_AMPLIFY_CONFIG)
  }

  isPublic = () => {
    const pName = window.location.pathname
    if (pName.startsWith('/admin')) {
      return false
    }
    return true
  }

  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <Route path={'/'} component={({ location, history }) => {
            return (
              <div className={css(Styles.page)}>
                {!this.isPublic() && <Header
                  className={css(Styles.header)}
                  currentUser={this.state.currentUser}
                  onDashboardClick={() => this.onDashboardClick()}
                  onLogoutClick={() => this.onLogoutClick()}
                />}
                {this.isPublic() && <PublicHeader
                  className={css(Styles.header)}
                  currentUser={this.state.currentUser}
                  onDashboardClick={() => this.onDashboardClick()}
                  onLogoutClick={() => this.onLogoutClick()} />}

                <div className={css(Styles.content)}>
                  {this.renderRoute(history)}
                </div>
                {this.isPublic() && <PublicFooter />}
              </div>
            )
          }}
          />
        </MuiThemeProvider>
      </Router>
    );
  }

  setUserLoaded = () => {
    this.setState({ userLoaded: true });
  };

  setNoPermission = () => {
    this.setState({ noPermission: true });
  };

  getProtectedRoute(path, component) {
    return (
      <ProtectedRoute
        userLoaded={this.state.userLoaded}
        setUserLoaded={this.setUserLoaded}
        noPermission={this.state.noPermission}
        setNoPermission={this.setNoPermission}
        exact
        path={path}
        component={component}
      />
    );
  }

  renderRoute(history) {
    Controller.get().setNavigator(history);
    return (
      <Switch>
        {/*
        {this.getProtectedRoute('/admin', Products)}
        {this.getProtectedRoute('/admin/users', UsersOfOrg)}
        {this.getProtectedRoute('/admin/faqs', Faqs)}
        {this.getProtectedRoute('/admin/cats', CatsL1)}
        {this.getProtectedRoute('/admin/cats/:catId/products', ProductsByCat)}
        {this.getProtectedRoute('/admin/cats/:idl1', CatsL2)}
        {this.getProtectedRoute('/admin/cats/:idl1/:idl2', CatsL3)}

        {this.getProtectedRoute('/admin/products', Products)}
        {this.getProtectedRoute('/admin/products/:id', ProductCustom)}
        {this.getProtectedRoute('/admin/lists', ProdLists)}
        {this.getProtectedRoute('/admin/lists/:id', ProductsByList)}
        */}

        {this.getProtectedRoute('/admin', Orgs)}
        {/* {this.getProtectedRoute('/admin/org-mgmt', Orgs)} */}
        {this.getProtectedRoute('/admin/tester', Tester)}
        {this.getProtectedRoute('/admin/users', UsersOfOrg)}
        {this.getProtectedRoute('/admin/cbdefs', CbDefsGlobal)}
        {this.getProtectedRoute('/admin/cbdefs-tags', CbdefTags)}
        {this.getProtectedRoute('/admin/cbdefs/:id', CbDefFields)}

        {this.getProtectedRoute('/admin/emailcbdefs', EmailCbDefsGlobal)}
        {this.getProtectedRoute('/admin/emailcbdefs/:id', EmailCbDefFields)}
        {this.getProtectedRoute('/admin/emailpages', EmailPages)}
        {this.getProtectedRoute('/admin/emailpages/:id/blocks', EmailPageCbs)}
        {this.getProtectedRoute('/admin/emailcbdats/:id', EmailCbDats)}

        {this.getProtectedRoute('/admin/orgs/:orgId', DashboardOrg)}
        {this.getProtectedRoute('/admin/orgs/:orgId/users', UsersOfOrg)}
        {this.getProtectedRoute('/admin/orgs/:orgId/carts', Carts)}
        {this.getProtectedRoute('/admin/orgs/:orgId/customers', CrmContacts)} // TODO: Deprecate
        {this.getProtectedRoute('/admin/orgs/:orgId/crm-contacts-legacy', CrmContacts)}
        {this.getProtectedRoute('/admin/orgs/:orgId/crm-contacts', CrmContacts2)}
        {this.getProtectedRoute('/admin/orgs/:orgId/crm-contacts/:userId', CrmContact)}
        {this.getProtectedRoute('/admin/orgs/:orgId/crm-settings', CrmSettings)}
        {this.getProtectedRoute('/admin/orgs/:orgId/crm-inboxes', CrmInboxes)}
        {this.getProtectedRoute('/admin/orgs/:orgId/crm-email-templates', CrmEmailTemplates)}
        {this.getProtectedRoute('/admin/orgs/:orgId/crm-user-settings', CrmUserSettings)}

        {this.getProtectedRoute('/admin/orgs/:orgId/faqs', Faqs)}
        {this.getProtectedRoute('/admin/orgs/:orgId/faqsections', FaqSections)}

        {this.getProtectedRoute('/admin/orgs/:orgId/coupons', Coupons)}
        {this.getProtectedRoute('/admin/orgs/:orgId/testimonials', Testimonials)}
        {this.getProtectedRoute('/admin/orgs/:orgId/benefits', Benefits)}
        {this.getProtectedRoute('/admin/orgs/:orgId/settings', Settings)}
        {this.getProtectedRoute('/admin/orgs/:orgId/branding', SettingsBranding)}
        {this.getProtectedRoute('/admin/orgs/:orgId/settings-master', SettingsMaster)}

        {this.getProtectedRoute('/admin/orgs/:orgId/cotypes', CoTypes)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cotypes/:id/fields', CoFields)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cotypes/:cotypeid/fields/:id/nestedfields', NestedFields)}
        {/* {this.getProtectedRoute('/admin/orgs/:orgId/cotypes/:id/items', CoItems)} */}

        {this.getProtectedRoute('/admin/orgs/:orgId/cos', CoTypesRead)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cos/:id/items', CoItems)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cos/:id/items/:itemId/subitems', CoNestedItems)}

        {this.getProtectedRoute('/admin/orgs/:orgId/jobs', Jobs)}
        {this.getProtectedRoute('/admin/orgs/:orgId/assets', Assets)}

        {this.getProtectedRoute('/admin/orgs/:orgId/content/types', ContentTypes)}
        {this.getProtectedRoute('/admin/orgs/:orgId/content/types/:id', ContentType)}
        {this.getProtectedRoute('/admin/orgs/:orgId/content/personas', ContentPersonas)}
        {this.getProtectedRoute('/admin/orgs/:orgId/content/blogs/:topicClusterId', ContentBlogs)}
        {this.getProtectedRoute('/admin/orgs/:orgId/content/planner', ContentPlanner)}
        {this.getProtectedRoute('/admin/orgs/:orgId/content/pillars/:pillar', ContentCluster)}
        {this.getProtectedRoute('/admin/orgs/:orgId/content/generator', ContentGenerator)}

        {this.getProtectedRoute('/admin/orgs/:orgId/forms', Forms)}
        {this.getProtectedRoute('/admin/orgs/:orgId/forms/:id/fields', FormFields)}
        {this.getProtectedRoute('/admin/orgs/:orgId/embed-tags', EmbedTags)}
        {this.getProtectedRoute('/admin/orgs/:orgId/campaigns/ooh', Ooh)}
        {this.getProtectedRoute('/admin/orgs/:orgId/campaigns/broadcasts', Broadcasts)}
        {this.getProtectedRoute('/admin/orgs/:orgId/campaigns/transactional', Transactional)}
        {this.getProtectedRoute('/admin/orgs/:orgId/campaigns/notifications', Notifications)}
        {this.getProtectedRoute('/admin/orgs/:orgId/campaigns/sequences', CrmSequences)}
        {this.getProtectedRoute('/admin/orgs/:orgId/campaigns/sequences/:id/steps', CrmSequenceSteps)}
        {this.getProtectedRoute('/admin/orgs/:orgId/campaigns/sequences/:id/enrolments', CrmSequenceEnrolments)}
        {this.getProtectedRoute('/admin/orgs/:orgId/campaigns/:id/results', CampaignResult)}
        {this.getProtectedRoute('/admin/orgs/:orgId/campaigns/popups', Popups)}
        {this.getProtectedRoute('/admin/orgs/:orgId/campaigns/popups/:id/results', PopupAnalytics)}
        {this.getProtectedRoute('/admin/orgs/:orgId/segments', Segments)}
        {this.getProtectedRoute('/admin/orgs/:orgId/journeys/messaging', Journeys)}
        {this.getProtectedRoute('/admin/orgs/:orgId/journeys/:id/results', JourneyResult)}
        {this.getProtectedRoute('/admin/orgs/:orgId/journeys/popups', Popups)}
        {this.getProtectedRoute('/admin/orgs/:orgId/journeys/popups/:id/results', PopupAnalytics)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cdp/settings', CdpSettings)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cbdefs', CbDefs)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cbdefs/:id', CbDefFields)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cbdats/:id', CbDats)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cbdats2/:id', CbDats2)}
        {this.getProtectedRoute('/admin/orgs/:orgId/content-blocks', CbDefsRead)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cborgs', CbOrgs)}
        {this.getProtectedRoute('/admin/orgs/:orgId/pages', Pages)}
        {this.getProtectedRoute('/admin/orgs/:orgId/pages/:id/blocks', PageCbs)}
        {this.getProtectedRoute('/admin/orgs/:orgId/pages/:id/cotypeblocks', PageCbsCoType)}
        {this.getProtectedRoute('/admin/orgs/:orgId/menus', Menus)}
        {this.getProtectedRoute('/admin/orgs/:orgId/menus-l2/:id', MenusL2)}
        {this.getProtectedRoute('/admin/orgs/:orgId/menus-l3/:id', MenusL3)}
        {this.getProtectedRoute('/admin/orgs/:orgId/footernavs', Footernavs)}
        {this.getProtectedRoute('/admin/orgs/:orgId/footernavs/:id', FooternavsL2)}

        {this.getProtectedRoute('/admin/orgs/:orgId/emailpages', EmailPages)}
        {this.getProtectedRoute('/admin/orgs/:orgId/emailpages/:id/blocks', EmailPageCbs)}
        {this.getProtectedRoute('/admin/orgs/:orgId/emailcbdats/:id', EmailCbDats)}

        {this.getProtectedRoute('/admin/orgs/:orgId/products', Products)}
        {this.getProtectedRoute('/admin/orgs/:orgId/products/:id', ProductCustom)}

        {this.getProtectedRoute('/admin/orgs/:orgId/cats', CatsL1)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cats/:catId/items', ProductsByCat)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cats/:idl1', CatsL2)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cats/:idl1/:idl2', CatsL3)}

        {this.getProtectedRoute('/admin/orgs/:orgId/lists', ProdLists)}
        {this.getProtectedRoute('/admin/orgs/:orgId/lists/:id', ProductsByList)}

        {this.getProtectedRoute('/admin/orgs/:orgId/brands', Brands)}
        {this.getProtectedRoute('/admin/orgs/:orgId/brands/:id', ProductsByBrand)}

        {this.getProtectedRoute('/admin/orgs/:orgId/banners', Banners)}
        {this.getProtectedRoute('/admin/orgs/:orgId/orders', Orders)}

        {this.getProtectedRoute('/admin/orgs/:orgId/btags', BlogTags)}
        {this.getProtectedRoute('/admin/orgs/:orgId/btags/:id/blogs', BlogsByTag)}
        {this.getProtectedRoute('/admin/orgs/:orgId/blists', BlogLists)}
        {this.getProtectedRoute('/admin/orgs/:orgId/blists/:id/blogs', BlogsByList)}
        {this.getProtectedRoute('/admin/orgs/:orgId/blogs', Blogs)}
        {this.getProtectedRoute('/admin/orgs/:orgId/blogs/:id', Blog)}
        {this.getProtectedRoute('/admin/orgs/:orgId/blogs/content/:id', CustomRichContentEditor)}

        {this.getProtectedRoute('/admin/orgs/:orgId/preview', ItemPreview)}

        <PublicRoute exact path={'/no-permission'} component={NoPermission} />
        <PublicRoute exact path={'/login'} component={LoginPage} />
        {/* <PublicRoute exact path={'/terms'} component={Terms} />
        <PublicRoute exact path={'/privacy'} component={Privacy} /> */}
        <PublicRoute exact path={'/'} component={Home} />
        <PublicRoute component={NotFound} />
      </Switch>
    )
  }

  onLogoutClick() {
    //console.log('onLogoutClick');
    Controller.get().loginMgr().signOut();
    this.setState({
      currentUser: null
    })
  }

  onDashboardClick() {
    this.props.history.push('/admin');
  }
}

const Styles = StyleSheet.create({
  page: {
    display: 'flex',
    position: 'relative',
    backgroundColor: AppTheme.secondaryBg,
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  header: {
    flex: '0 0 auto'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    overflowX: 'hidden',
    flex: 1
  },
  footer: {
    flex: '0 0 auto'
  },
  drawerFiller: {
    width: AppTheme.drawerWidth,
    '@media (max-width: 767px)': {
      width: 'unset'
    }
  }
})

export default App;
