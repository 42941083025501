import { CampaignsBase } from './CampaignsBase';
import { Helper } from '../../../common/helpers/Helper';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppConfig } from '../../../AppConfig';

export class CrmSequences extends CampaignsBase {
  constructor(props, options) {
    super(props, {
      ...options,
      pageTitle: Helper.getString('crm-sequences'),
      campaignType: 'DRIP',
    });
  }

  openModalForResync(current) {
    this.formDefinition = {
      steps: [{
        fields: [
          {
            key: 'campaignId',
            type: 'radio',
            label: 'Drip Sequence',
            options: [{key: current.id, label: current.title}],
            v_required: { value: true, err: 'Provide drip sequence' },
            placement: 'left',
            //defaultValue: current.id,
          },
        ]
      }],
      disableAlwaysFormFields: ['campaignId'],
      formType: 'edit',
      title: 'Resync',
    }
    Helper.populateFormDefinitionValues(this.formDefinition, {campaignId: current.id})
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({
        ms: AppConfig.CDP_MS.ENDPOINT,
        paths: ['miscs', 'resync-drip-sequence'],
        queryParams: { orgId: this.orgId, campaignId: current.id },
      }),
      modalOpen: true,
    })
  }
}
