import { StyleSheet, css } from 'aphrodite';
import { CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';

import { AppButton } from '../AppButton';
import { generateText } from '../../../screens/admin/content/ContentUtils';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';

export class AIAssistantDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      paraphraseInput: '',
      mode: 'tools',
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        aria-labelledby={this.props.title}
        fullWidth={false}
        maxWidth='md'
        classes={{
          root: css(Styles.customRoot),
          paper: css(Styles.customPaper),
          container: css(Styles.customContainer),
        }}
      >
        <div className={css([Styles.modalHeader, AppStyles.modalHeader])}>
          <div className={css(Styles.modalTitle)}>{this.props.title}</div>
          <button className={css(Styles.modalCloseButton)} onClick={this.onCloseModal.bind(this)} disabled={this.state.inProgress}>
            <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
              <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
            </svg>
          </button>
        </div>
        <div className={css(Styles.modalContent)}>
          {!this.state.inProgress && this.modalContent()}
          {this.state.inProgress && <CircularProgress size={48} className={css(Styles.loaderProgress)} />}
        </div>
      </Dialog >
    );
  }

  modalContent() {
    const tileContainerStyle = css(Styles.tileContainer);
    return <div className={css(Styles.blockContainer)}>
      {
        this.state.mode === 'tools' &&
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6} key={1}>
            <div className={tileContainerStyle} onClick={() => this.onClick('blogAssistant.writeOutline')}>
              <p className={css(Styles.title)}>Generate Outline</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} key={1}>
            <div className={tileContainerStyle} onClick={() => this.onClick('blogAssistant.writeParagraph')}>
              <p className={css(Styles.title)}>Generate Paragraph</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} key={1}>
            <div className={tileContainerStyle} onClick={() => this.onClick('blogAssistant.writeBullets')}>
              <p className={css(Styles.title)}>Generate Bullets</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} key={1}>
            <div className={tileContainerStyle} onClick={() => this.setState(prevState => ({...prevState, mode: 'paraphrase'}))}>
              <p className={css(Styles.title)}>Paraphrase</p>
            </div>
          </Grid>
        </Grid>
      }
      {
        this.state.mode === 'paraphrase' &&
        <React.Fragment>
          <input type='search' value={this.state.paraphraseInput}
            onChange={(event) => {
              const target = event.target;
              this.setState(prevState => ({...prevState, paraphraseInput: target.value}))
            }}
            className={css(Styles.paraphraseInput)}
            placeholder='Optionally specify an additional prompt for paraphrasing ...'>
          </input>
          <div className={css(Styles.actions)}>
            <div className={css(Styles.actionsContainer)}>
              <AppButton
                buttonStyle={Styles.actionButton}
                disabled={this.state.inProgress}
                onClick={() => {
                  this.onClick('blogAssistant.paraphrase', this.state.paraphraseInput);
                }}
                buttonText={'Paraphrase'}
              />
            </div>
          </div>
        </React.Fragment>
      }
    </div>
  }

  onCloseModal() {
    this.props.onClosed();
    this.setState(prevState => ({
      ...prevState,
      inProgress: false,
      paraphraseInput: '',
      mode: 'tools',
    }));
  }

  onClick(prompt, focus) {
    this.setState(prevState => ({...prevState, inProgress: true}));
    const { ms, orgId, blockIndex } = this.props.context;
    const replacements = {...this.props.context.replacements};
    if (focus) {
      replacements.focus = focus;
    }
    return generateText(orgId, ms, prompt, replacements)
      .then(generatedText => {
        console.log('generatedText')
        console.log(generatedText)
        this.props.context.onGenerateTextDone(generatedText, blockIndex);
      })
      .finally(() => {
        this.setState(prevState => ({...prevState, inProgress: false}));
        this.onCloseModal();
      });
  }
}

const Styles = StyleSheet.create({
  root: {
    zIndex: 13000,
  },
  paper: {
    borderRadius: AppTheme.borderRadius
  },
  loaderProgress: {
    color: AppTheme.primaryColor
  },
  modalHeader: {
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14
  },
  modalTitle: {
    color: '#222222',
    fontSize: 26,
  },
  modalContent: {
    maxWidth: 400,
    '@media (min-width: 768px)': {
      minWidth: 400,
      minHeight: 150,
    },
    paddingTop: 16,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalCloseButton: {
    position: 'absolute',
    top: 23,
    right: 30,
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: 8
  },
  blockContainer: {
    width: '100%'
  },
  tileContainer: {
    cursor: 'pointer',
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    border: '1px solid #A9A9A9',
    ':hover': {
      color: AppTheme.primaryColor,
      borderColor: AppTheme.primaryColor,
      boxShadow: '3px 6px 43px rgba(0, 0, 0, 0.05)',
      transition: '.3s'
    }
  },
  paraphraseInput: {
    display: 'block',
    zIndex: 20,
    padding: '0.625rem',
    borderRadius: '0.5rem',
    borderWidth: '1px',
    borderColor: '#F3F4F6',
    width: '100%',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: '#111827',
    backgroundColor: '#F9FAFB',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    //padding: '0px 30px',
    justifyContent: 'flex-end',
    '@media (max-width: 767px)': {
      //padding: '0px 15px',
    }
  },
  actionsContainer: {
    // flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionButton: {
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    height: 54,
    minWidth: 150,
    padding: 13,
    fontSize: 16,
    textTransform: 'capitalize',
    color: AppTheme.secondaryBg
  },
})
