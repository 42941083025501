import { UserMgr } from './mgrs/UserMgr';
import { LoginMgr } from './mgrs/LoginMgr';

export class Controller {
  static _me = null;
  static get() {
    if (this._me == null) {
      const { AppController } = require('../AppController')
      this._me = new AppController()
    }
    return this._me;
  }

  constructor() {
    this._roleHelper = undefined
    this._mockHelper = undefined
  }

  _loginMgrObj = null;
  loginMgr() {
    if (null == this._loginMgrObj) {
      this._loginMgrObj = new LoginMgr();
    }
    return this._loginMgrObj;
  }

  _userMgrObj = null;
  userMgr() {
    if (null == this._userMgrObj) {
      this._userMgrObj = new UserMgr();
    }
    return this._userMgrObj;
  }

  getUserId() {
    //return this.userMgr().getUserId()
    const cognitoUser = Controller.get().loginMgr().getCurrentUser()
    if (cognitoUser) {
      return cognitoUser.username
    }
    return null;
  }

  setNavigator(history) {
    this.history = history
  }

  pushRoute(route) {
    this.history.push(route)
  }

  pushRouteWithParams(route, params) {
    this.history.push({ pathname: route, state: params })
  }

  pushToHome() {
    this.pushRoute(Controller.get().roleHelper().getHome())
  }

  logout() {
    //console.log('logout');
    this.loginMgr().signOut();
    this.pushRoute('/login')
  }

  getHomeByRole() {
    return Controller.get().roleHelper().getHome() // backward compatibility
  }

  roleHelper() {
    return this._roleHelper
  }

  mockHelper() {
    return this._mockHelper
  }
}
