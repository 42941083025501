/* eslint-disable camelcase */

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';

export class CrmSettings extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('crm-settings')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.groupId = 'settingscrmcontactattrs' + this.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('crm-settings') },
    ]
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['items', `${this.groupId}-all`], queryParams: { groupId: this.groupId, pb: 'fieldDefAttrs' } },
    ]
    this.apiData = {
      //orgId: this.orgId
    }
    this.pageKey = 'crm-settings'
    const _this = this
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Attribute: (current) => UiHelper.extractSettingsLabel(current, _this.fieldsAttrs),
      Value: (current) => UiHelper.extractSettingsValue(current),
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.noAdd = true
    this.noTableHead = true
  }

  onFetchSuccess(results) {
    this.crmContactAttrs = results[0]
    this.fieldsAttrs = results[0].fieldDefAttrs.steps[0].fields

    this.tableItems = []
    this.fieldsAttrs.forEach(a => {
      this.tableItems.push({
        [a.key]: this.crmContactAttrs[a.key] || '',
      })
    })

    this.setState({
      items: this.tableItems,
    })
  }

  openModalForEdit(current) {
    this.formDefinition = {
      steps: [
        {
          fields: UiHelper.extractSettingsFields(current, this.fieldsAttrs)
        }
      ]
    }

    super.openModalForEdit(current, this.formDefinition)
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['items', `${this.groupId}-all`], queryParams: {groupId: this.groupId} }),
    });
  }

  customSubmit(options) {
    const { formType, formData, caller } = options
    if (formType !== 'edit') {
      return caller.submit.call(caller, formData, true);
    }

    ApiHelper.callAwait({
      method: 'PUT',
      jsonBody: { ...formData },
      endPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['items', `${this.groupId}-all`], queryParams: {groupId: this.groupId} }),
    }).then(() => {
      this.setState({ modalOpen: false })
      this.fetchItems()
    })
      .catch(err => {
        const errorMessage = Helper.getErrorMsg(err)
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
      })
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    return actions
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }
}
