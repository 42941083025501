import { Helper } from './Helper';
import { Auth } from '@aws-amplify/auth';
import { Analytics } from '@aws-amplify/analytics';
import { Controller } from '../Controller';

export class AWSAmplifyHelper {

  static _config = null;
  static init(config) {
    this._config = config
    console.log('Amplify::Auth.configure...')
    Auth.configure(Helper.selectDict('Auth', this._config))

    const c = {
      AWSPinpoint: this._config.AWSPinpoint
    }
    c.AWSPinpoint.endpoint = {
      userId: Controller.get().getUserId()
    }
    console.log('Amplify::Analytics.configure...:', c)
    Analytics.configure(c)
  }

  static eventPageView(attributes) {
    if(!this._config) return
    // console.log('Analytics.record...')
    // Analytics.record('cart-open') // Record an event

    const event = { // Record a custom event
      name: 'pageView',
      attributes
    }
    console.log('Amplify::Analytics.record::', event)
    Analytics.record(event)
  }

  static event(event) {
    if(!this._config) return

    console.log('Amplify::Analytics.record::', event)
    Analytics.record(event)
  }

}
