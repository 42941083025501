import React, { Fragment } from 'react';
import { css } from 'aphrodite';

import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { CommonStyles } from '../../../styles/Styles';
import { Grid } from '@material-ui/core';
import { StatCard } from '../../../common/components/StatCard';

export class JourneyResult extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = Helper.getString('journeyresult')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.itemId = this.props.match.params.id
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('journeys'), to: this.baseRoute() + '/journeys/messaging' },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['journeys', this.itemId], queryParams: { orgId: this.orgId, loadExternal: true } },
    ]
    this.apiData = {
      orgId: this.orgId
    }
    this.noAdd = true
    this.headerActions = [
      {
        label: 'Refresh',
        icon: 'refresh',
        onClick: () => this.fetchItems(),
      }
    ]
  }

  onFetchSuccess(results) {
    this.item = results[0]
    this.pageTitle = this.item.title
    this.setState({
      items: []
    })
  }

  renderAboveTable() {
    const r = this.item._extJourneyPerformance.kpis
    const tiles = [
      { title: 'Emails Sent', value: Math.round(r['emails-sent'].count) },
      { title: 'Emails Delivered', value: Math.round(r['emails-delivered'].count) },
      { title: 'Emails Opened', value: Math.round(r['emails-opened'].count) },
      { title: 'Emails Clicked', value: Math.round(r['emails-clicked'].count) },
      { title: 'Emails Unsubscribed', value: Math.round(r['emails-unsubscribed'].count) },
      { title: 'Emails Bounced', value: Math.round(r['emails-hardbounced'].count) },
      { title: 'Emails Complained', value: Math.round(r['emails-complained'].count) },
    ]

    return (
      <Fragment>
        <Grid container spacing={2} className={css(CommonStyles.statContainer)}>
          {tiles.map((tile, index) => (
            <StatCard
              // sm={5}
              key={tile.title}
              text={tile.value}
              subText={tile.title.toLocaleUpperCase()}
              cardIndex={index}
            />
          ))}
        </Grid>
      </Fragment>
    )
  }

  renderBelowTable() {
    return <p>It can take up to 2 hours for results to be processed.</p>
  }

  renderTable() {
    return ''
  }

  onActions = (current) => {
    const actions = []
    return actions
  }
}
