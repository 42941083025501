import React, { Component } from 'react';

import { ApiHelper } from '../../helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';
import { CustomBlogTagsStyles } from '../../../styles/CustomStyles';

export class BlogTags extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('blogtags')
    this.pageKey = 'blogTags'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.parentId = 'ROOT-' + this.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      {
        method: 'GET', paths: ['btags'],
        queryParams: { orgId: this.orgId, parentId: this.parentId, pb: 'fieldDef', limit: 200 }
      },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      'Slug / ID': (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.slug}</span>
            <br />
            <span className={css(Styles.spanValue)}>{current.id}</span>
          </div>
        )
      },
      Title: (current) => current.title,
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId,
      parentId: this.parentId
    }
  }

  onFetchSuccess(results) {
    this.fieldDef = results[0].fieldDef
    this.formDefinition = results[0].fieldDef
    const fields = []
    for (const field of this.formDefinition.steps[0].fields) {
      if (field.key !== 'parentId') {
        fields.push(field)
      }
    }
    this.formDefinition.steps[0].fields = fields
    this.handleNextpage(results[0])
    this.setState({
      items: Helper.sortDictArray(this.state.items.concat(results[0].items), 'title')
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['btags']),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['btags', current.id]),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['btags', current.id]),
    });
  }

  openModalForAdd() {
    const fields = []
    this.fieldDef.steps[0].fields.forEach(i => { if (i.key !== 'slug') fields.push(i) })
    this.formDefinition = { steps: [{ fields }] }
    super.openModalForAdd(this.formDefinition)
  }

  openModalForEdit(current) {
    this.formDefinition = this.fieldDef
    super.openModalForEdit(current)
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    actions.push(UiHelper.buttonOpenBlogsInTag(this, current))
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomBlogTagsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})