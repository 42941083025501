import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../helpers/ApiHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../../components/BaseEntityPage';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { UiHelper } from '../../helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { AppHelper } from '../../../helpers/AppHelper';

export class Brands extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('brands')
    this.pageKey = 'brands'
    this.orgId = this.props.match.params.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['brands'], { orgId: this.orgId, pb: 'fieldDef' }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      'Slug / ID': (current) => {
        return UiHelper.itemDetails(current)
      },
      Title: (current) => current.title,
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
  }

  onFetchSuccess(results) {
    this.fieldDef = results[0].fieldDef
    this.formDefinition = results[0].fieldDef
    this.setState({
      items: results[0].items,
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['brands'], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['brands', current.id], {}),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['brands', current.id], {}),
    });
  }

  openModalForAdd() {
    const fields = []
    this.fieldDef.steps[0].fields.forEach(i => { if (i.key !== 'slug') fields.push(i) })
    this.formDefinition = { steps: [{ fields }] }
    super.openModalForAdd(this.formDefinition)
  }

  openModalForEdit(current) {
    this.formDefinition = this.fieldDef
    super.openModalForEdit(current)
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(
      <Link to={'/admin/orgs/' + this.orgId + '/brands/' + current.id} key={current.id + '-brands'} >
        <Tooltip title='Products' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>layers</i>
          </span>
        </Tooltip>
      </Link>
    )
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})