
import React, { Component, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import AvatarEditor from 'react-avatar-editor'
import CircularProgress from '@material-ui/core/CircularProgress';

import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { UiHelper } from '../common/helpers/UiHelper';

import moment from 'moment';
import { CustomImageUploaderStyles } from '../styles/CustomStyles';
import FileDrop from 'react-file-drop'
import { ImageCollection } from './ImageCollection';
import { ImageUploader } from '../common/components/ImageUploader';
import { AppConfig } from '../AppConfig';
import { AppTheme } from '../styles/AppTheme';

const RESIZE_DEFAULT = 1200
const IMG_RENDER_SIZE = 100
const FILE_SIZE_MB = 10000000
const HEIGHT = 49
const ICON_SIZE = 35


export class ImageUploaderWithAssetPicker extends ImageUploader {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  openAssetPicker = (e) => {
    this.setState({
      showAssetPicker: true
    })
  }

  render() {
    if (this.props.mode === 'fit' && this.selectedImgHeight && this.selectedImgWidth) {
      if (this.selectedImgWidth < this.width) {
        this.width = this.selectedImgWidth
      }
      this.height = Math.round((this.selectedImgHeight / this.selectedImgWidth) * this.width)
      if(this.height > this.heightExpected) {
        this.height = this.heightExpected
        this.width = Math.round((this.selectedImgWidth / this.selectedImgHeight) * this.height)
      }
    }
    const ratio = this.width / this.height
    let height = HEIGHT
    let width = height * ratio
    if (width > 250) { // too wide
      width = 250
      height = width / ratio
    }
    const EditorStyles = StyleSheet.create({
      editor: {
        backgroundColor: 'white',
        height,
        width
      },
    })

    const inputTagId = 'upload-button-' + this.props.uploadId + '-' + moment().valueOf()
    const outerContainer = (!this.state.uploadInProgress && (this.currentUrl || this.image.imageUrl)) ? [Styles.outerContainer, Styles.imageOuterContainer] : [Styles.outerContainer]
    if(this.props.disabled) {
      outerContainer.push(Styles.outerContainerDisabled)
    }
    return (
      <div className={css(Styles.container)}>
        <div className={css(Styles.imgContainer)}>
          <AvatarEditor
            ref={this.setEditorRef.bind(this)}
            className={css(EditorStyles.editor)}
            image={this.state.dataURL || this.currentUrl}
            width={this.width}
            height={this.height}
            border={1}
            borderRadius={this.borderRadius}
            color={[0, 0, 0, 0.5]} // RGBA
          //scale={1}
          //rotate={0}
          />
        </div>
        {/*<div className={this.props.mode === 'fit' ? css(Styles.textContainer, Styles.fitContainer) : css(Styles.textContainer)} style={{ height: height, width: width }}>
           <input
            id={inputTagId}
            className={css(Styles.fileInput)}
            onChange={this.onFileChange.bind(this)}
            type='file'
            style={{ display: 'none' }}
            accept={this.props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'} />
          <label htmlFor={inputTagId} className={css(Styles.labelContainer)}>
            <div className={css(Styles.attachFile)}>
              {(!(this.currentUrl || this.image.imageUrl) || this.state.uploadInProgress ) &&
                <Fragment>
                  {this.state.uploadInProgress ?
                    <CircularProgress size={24} className={css(Styles.progressIcon)} />
                    :
                    <p className={css(Styles.attachFileText)}>Select Image</p>
                  }
                </Fragment>
              }
              {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress &&
                <div className={this.props.mode === 'fit' ? css(Styles.imageContainer, Styles.fitContainer) : css(Styles.imageContainer)} style={{height: height, width: width}}>
                  <img src={this.image.imageUrl || this.currentUrl} className={css(Styles.imageContent)} />
                </div>
              }
            </div>
            <div className={css(Styles.closeIconContainer)}>
              <i className='material-icons-outlined' style={{ fontSize: 14 }}>upload</i>
            </div>
          </label>
          </div>*/}
        <input
          id={inputTagId}
          className={css(Styles.fileInput)}
          onChange={this.onFileChange.bind(this)}
          type='file'
          style={{ display: 'none' }}
          accept={this.props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'}
          disabled={this.props.disabled} />
        <div className={css(Styles.outerMainContainer)}>

          <div className={css(outerContainer)}>
            <label htmlFor={inputTagId} className={this.props.disabled ? css(Styles.labelDisableContainer) : css(Styles.labelContainer)}>
              <FileDrop onDrop={!this.props.disabled && this.handleDrop.bind(this)} className={css(Styles.fileDrop)} targetClassName={css(Styles.targetFileDrop)}>
                {(!(this.currentUrl || this.image.imageUrl) || this.state.uploadInProgress) &&
                  <Fragment>
                    {this.state.uploadInProgress ?
                      <CircularProgress size={24} className={css(Styles.progressIcon)} />
                      :
                      <div className={css(Styles.imageIconOuterContainer)}>
                        <div className={css(Styles.imageIconContainer)}>
                          {UiHelper.renderImg({ key: 'imgUpload', size: ICON_SIZE })}
                        </div>
                        <p className={css(Styles.attachFileText)}>{Helper.getString('dragAndDropImage')} or <span className={css(Styles.attachFileTextBrowse)}>Browse</span></p>
                      </div>
                    }
                  </Fragment>
                }
                {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress &&
                  <div className={this.props.mode === 'fit' ? css(Styles.textContainer, Styles.fitContainer) : css(Styles.textContainer)} style={this.props.mode !== 'fit' ? { height: height, width: width } : {}}>
                    {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress &&
                      <div className={this.props.mode === 'fit' ? css(Styles.imageContainer, Styles.fitContainer) : css(Styles.imageContainer)} style={this.props.mode !== 'fit' ? { height: height, width: width } : {}}>
                        <img src={this.image.imageUrl || this.currentUrl} className={css(Styles.imageContent)} />
                      </div>
                    }
                  </div>
                }
              </FileDrop>
              {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress &&
                <Fragment>
                  <div
                    className={this.props.disabled ? css([Styles.closeIconContainer, Styles.reuploadIconContainer, Styles.closeIconDisableContainer]) : css([Styles.closeIconContainer, Styles.reuploadIconContainer])}>
                    <i className='material-icons-outlined' style={{ fontSize: 14 }}>upload</i>
                  </div>
                </Fragment>
              }
            </label>
            {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress && !this.props.disableDeletion &&
              <Fragment>
                <div className={this.props.disabled ? css([Styles.closeIconContainer, Styles.closeIconDisableContainer, Styles.deleteIconContainer]) : css([Styles.closeIconContainer, Styles.deleteIconContainer])}
                  onClick={() => !this.props.disabled && this.onFileDelete(inputTagId)}>
                  <i className='material-icons-outlined' style={{ fontSize: 14 }}>delete</i>
                </div>
              </Fragment>
            }
          </div>
          <div disabled={this.props.disabled} onClick={(e) => !this.props.disabled && this.openAssetPicker(e)} className={this.props.disabled ? css([Styles.assetPickerContainer, Styles.assetPickerContainerDisabled]) : css(Styles.assetPickerContainer)}>
            <p className={css(Styles.assetPickerTitle)}>{Helper.getString('assetPickerTitle')}</p>
          </div>
          {/* {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress &&
            <p className={css(Styles.reuploadContainer)}><label htmlFor={inputTagId} className={css(Styles.reuploadLabel)}>Reupload</label></p>
          } */}
        </div>
        {this.state.message && !this.props.disabled && this.renderMessage()}
        <ImageCollection
          orgId={this.props.orgId}
          onUploadComplete={this.onUploadComplete.bind(this)}
          isOpen={this.state.showAssetPicker}
          onClosed={() => this.setState({ showAssetPicker: false })}
        />
      </div>
    )
  }

  async onUploadComplete(signedUploadInfo, options) {
    const img = new Image;
    img.src = signedUploadInfo.link
    img.onload = async function () {
      this.selectedImgWidth = img.width
      this.selectedImgHeight = img.height
      if (!this.matchImgDimensions()) return
      this.uploadToAssets(signedUploadInfo, options)
    }.bind(this);
  }

  async uploadToAssets(signedUploadInfo, options) {
    const fromAssetPicker = options && options.fromAssetPicker
    if (!fromAssetPicker) {
      const ext = this._getFileExt()
      const fName = (this.state.file && this.state.file.name) ? this.state.file.name.replace('.' + ext, '') : null
      try {
        const response = await ApiHelper.callAwait({
          method: 'POST',
          paths: ['assets'],
          jsonBody: { orgId: this.props.orgId, imageName: signedUploadInfo.fields.key, title: fName || Helper.getString('untitledImg') }
        })
      } catch (e) {
        this.setState({ message: Helper.getErrorMsg(e),
          fetchState: ApiHelper.State.ERROR,
          uploadInProgress: false,
          deleteInProgress: null
        })
        return
      }
    }
    this.setState({
      message: '',
      fetchState: ApiHelper.State.READY,
      uploadInProgress: false,
      deleteInProgress: null
    })
    this.image = {
      imageUrl: signedUploadInfo.link,
      imageName: signedUploadInfo.fields.key
    }
    signedUploadInfo.height = this.selectedImgHeight
    signedUploadInfo.width = this.selectedImgWidth
    if (this.props.onUploadSuccess) {
      this.props.onUploadSuccess(this.props.uploadId, signedUploadInfo)
    }
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomImageUploaderStyles : StyleSheet.create({
  container: {
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
  },
  fitContainer: {
    height: HEIGHT,
    width: 'auto'
  },
  fileInput: {
    width: '100%'
  },
  textButton: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 100,
    minHeight: 24,
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10
  },
  text: {
    marginTop: 10,
    marginBottom: 3,
    fontSize: 12
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: '#f44336',
  },
  textSuccess: {
    color: 'green',
  },
  editor: {
    width: 65,
    height: 65,
  },
  imgContainer: {
    display: 'none'
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  imageContent: {
    borderRadius: 0,
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  closeIconContainer: {
    backdropFilter: 'blur(6px)',
    backgroundColor: 'white',
    display: 'flex',
    cursor: 'pointer',
    border: '1px solid black',
    padding: 2,
    borderRadius: 4,
  },
  closeIconDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  attachFileText: {
    margin: 0,
    fontSize: 12,
    color: 'black',
    marginLeft: 13
  },
  attachFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10
  },
  labelContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    padding: 0,
    borderRadius: 0,
    alignItems: 'center'
  },
  labelDisableContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: 0,
    // backgroundColor: '#f5f5f5',
    cursor: 'unset',
    borderRadius: 0,
    alignItems: 'center'
  },
  outerContainerDisabled: {
    backgroundColor: '#f5f5f5'
  },
  attachFileTextBrowse: {
    color: AppTheme.primaryColor,
    fontWeight: 500
  },
  outerMainContainer: {
    display: 'flex'
  },
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    border: '1px dashed #E5E5E5',
    borderRadius: AppTheme.borderRadius,
    height: HEIGHT,
    position: 'relative',
    overflow: 'hidden'
  },
  imageOuterContainer: {
    // flex: 'unset',
    border: '1px solid #E5E5E5'
  },
  imageIconContainer: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageIconOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  fileDrop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    position: 'relative'
  },

  targetFileDrop: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  reuploadContainer: {
    fontSize: 16,
    color: AppTheme.primaryColor,
    margin: 20,
    display: 'flex',
    alignItems: 'center'
  },
  reuploadLabel: {
    cursor: 'pointer'
  },
  deleteIconContainer: {
    margin: '0px 5px'
  }
})
