import React, { Component, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ApiHelper } from '../helpers/ApiHelper';
import { Helper } from '../helpers/Helper';
import { UiHelper } from '../helpers/UiHelper';

import { AppStyles, AppTheme } from '../../styles/AppTheme';
import { UploadHelper } from '../helpers/UploadHelper';
import { CustomDocumentUploaderStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import moment from 'moment';
import FileDrop from 'react-file-drop';

const FIELD_HEIGHT = 49
const ICON_SIZE = 35
const FILE_SIZE_BYTES = 10000000

export class DocumentUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      fetchState: ApiHelper.State.READY,
      uploadInProgress: false
    };
  }

  componentDidMount() {
  }

  render() {
    const inputTagId = 'upload-button-' + this.props.uploadId + '-' + moment().valueOf()
    return (
      <div>
        <div className={css(Styles.container)}>
          <input
            id={inputTagId}
            className={css(Styles.fileInput)}
            onChange={this.onFileChange.bind(this)}
            type='file'
            style={{ display: 'none' }}
            accept={this.props.uploadAccept || 'application/pdf'}
            disabled={this.props.disabled} />
          <div className={this.props.disabled ? css([Styles.buttonContainer, Styles.buttonContainerDisabled]) : css(Styles.buttonContainer)}>
            <label htmlFor={inputTagId} className={this.props.disabled ? css(Styles.labelContainer, Styles.labelDisableContainer) : css(Styles.labelContainer)}>
              <FileDrop onDrop={!this.props.disabled && this.handleDrop.bind(this)} className={css(Styles.fileContainer)} targetClassName={css(Styles.targetContainer)}>
                {(!(this.props.link || this.state.dataURL) || this.state.uploadInProgress) &&
                  <Fragment>
                    {this.state.uploadInProgress ?
                      <CircularProgress size={24} className={css(Styles.progressIcon)} />
                      :
                      <div className={css(Styles.imageIconOuterContainer)}>
                        <div className={css(Styles.imageIconContainer)}>
                          {UiHelper.renderImg({ key: 'docUpload', size: ICON_SIZE })}
                        </div>
                        <p className={css(Styles.attachFileText)}>{Helper.getString('uploadFile')} <span className={css(Styles.attachFileTextBrowse)}>Browse</span></p>
                      </div>
                    }
                  </Fragment>
                }
                {(this.props.link || this.state.dataURL) && !this.state.uploadInProgress &&
                  <div className={css(Styles.documentContainer)}>
                    <div className={css(Styles.documentImageContainer)}>
                      {UiHelper.renderImg({ key: 'file', size: ICON_SIZE })}
                    </div>
                    {this.props.value &&
                      <div className={css(Styles.documentNameContainer)}>
                        <p className={css(Styles.docName)}>{this.props.value}</p>
                        {/* <p className={css(Styles.docName)}>Uploaded {this.props.label}</p> */}
                      </div>
                    }
                  </div>
                }
              </FileDrop>
              {(this.props.link || this.state.dataURL) && !this.state.uploadInProgress &&
                <div className={this.props.disabled ? css(Styles.closeImgContainer, Styles.reuploadIconContainer, Styles.closeImgDisableContainer) : css(Styles.closeImgContainer, Styles.reuploadIconContainer)}>
                  <i className='material-icons-outlined' style={{ fontSize: 14 }}>upload</i>
                </div>
              }
            </label>
            {(this.props.link || this.state.dataURL) && !this.state.uploadInProgress &&
              <div className={
                this.props.disabled ? css([Styles.closeImgContainer, Styles.delImgContainer, Styles.closeImgDisableContainer]) : css([Styles.closeImgContainer, Styles.delImgContainer])}
              onClick={() => !this.props.disabled && this.onFileDelete(inputTagId)}>
                <i className='material-icons-outlined' style={{ fontSize: 14 }} >delete</i>
              </div>
            }
          </div>
        </div>
        {this.state.message && !this.props.disabled && this.renderMessage()}
        {this.props.link && (!this.props.disabled || this.props.forceAllowFileOpen) &&
          <a className={css(Styles.openDoc)} href={this.props.link} target='_blank'>{Helper.getString('openFile')}</a>
        }
      </div>
    )
  }

  renderMessage() {
    let messageClass = css(Styles.textUpload, Styles.text)
    if (this.state.fetchState === ApiHelper.State.ERROR) {
      messageClass = css(Styles.textError, Styles.text)
    } else if (this.state.fetchState === ApiHelper.State.READY) {
      messageClass = css(Styles.textSuccess, Styles.text)
    }
    return (
      <div className={css(Styles.msgContainer)}>
        <p className={messageClass}>{this.state.message}</p>
      </div>
    )
  }

  onUpload() {
    if (!this.state.file) {
      return this.onError(Helper.getString('fileChoose'))
    }
    const maxSize = this.props.fileSizeLimit ? this.props.fileSizeLimit : FILE_SIZE_BYTES;
    if (this.state.file.size > maxSize) {
      const errMsg = maxSize / 1000000 + Helper.getString('fileSizeExceedMsg')
      return this.onError(errMsg)
    }
    this.setState(
      {
        message: '',
        fetchState: ApiHelper.State.READY
      }
    )

    let ext = null;
    if (this.state.file && this.state.file.name) {
      const userInputFilename = this.state.file.name;
      ext = userInputFilename.split('.').pop()
    }

    const options = {
      uploadLocation: this.props.uploadLocation.replace('__FILENAME__', this.state.file.name),
      type: this.props.uploadType || 'media',
      ext: ext || this.props.uploadExt || 'pdf',
      ms: this.props.uploadMs,
    }
    this.onUploadProgress()
    UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo) => {
      if (err) {
        return this.onError(err)
      }
      console.log('fetchUploadUrl:', err, signedUploadInfo)
      const uploadOptions = {
        pickerResult: this.state.file,
        signedUploadInfo: signedUploadInfo,
        onEvent: (type, percent) => {
          console.log('progress:', type, percent)
          if (type === 'progress') {
            return this.onUploadProgress(percent)
          } else if (type === 'load') {
            return this.onUploadComplete(signedUploadInfo)
          } else if (type === 'error') {
            return this.onError(Helper.getString('fileUploadErr'))
          }
        }
      }
      UploadHelper.uploadFormDataWithProgress(uploadOptions)
    })
  }

  onFileChange(e) {
    const file = e.target.files[0]
    const acceptArray = this.props.uploadAccept || 'application/pdf'
    if (file) {
      if (acceptArray.split(', ').some(accept => {
        const acceptParts = accept.split('/')
        const fileTypeParts = file.type.split('/')
        if (acceptParts && acceptParts.length === 2 && fileTypeParts && fileTypeParts.length === 2) {
          if (acceptParts[1] === '*') {
            return acceptParts[0] === fileTypeParts[0]
          } else {
            return accept === file.type
          }
        } else {
          return accept === file.type
        }
      })) {
        this.readFile(file)
      } else {
        this.onError(Helper.getString('invalidFileType'))
      }
    }
  }

  readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState(
        {
          file: file,
          dataURL: e.target.result,
          message: '',
          fetchState: ApiHelper.State.READY,
          uploadInProgress: true
        }, () => {
          setTimeout(() => {
            this.onUpload()
          }, 1000)
        }
      )
    }
    reader.readAsDataURL(file)
  }

  onFileDelete(inputTagId) {
    this.setState({
      dataURL: '',
      message: ''
    })
    const e = document.getElementById(inputTagId)
    e.value = ''
    const field = {
      key: ''
    }
    this.props.onUploadSuccess(this.props.uploadId, { link: '', fields: field })
  }

  handleDrop = (files, event) => {
    const file = files[0]
    const acceptArray = this.props.uploadAccept || 'application/pdf'
    if (file && acceptArray.split(', ').indexOf(file.type) > -1) {
      this.readFile(file)
    } else {
      this.setState({ fetchState: ApiHelper.State.ERROR, message: Helper.getString('invalidFileType') })
    }
  }

  onUploadProgress(percent) {
    console.log('onUploadProgress:', percent);
    let message = 'Uploading'
    if (percent) {
      message += ': ' + percent + ' %'
    } else {
      message += '...'
    }
    this.setState({
      // message: message,
      fetchState: ApiHelper.State.LOADING
    })
  }

  onUploadComplete(signedUploadInfo) {
    this.setState({
      // message: Helper.getString('fileUploadSuccess'),
      fetchState: ApiHelper.State.READY,
      uploadInProgress: false,
    })
    if (this.props.onUploadSuccess) {
      this.props.onUploadSuccess(this.props.uploadId, signedUploadInfo)
    }
  }

  onError(err) {
    console.log('err:', err);
    this.setState({
      message: Helper.getErrorMsg(err),
      fetchState: ApiHelper.State.ERROR,
      uploadInProgress: false
    })
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomDocumentUploaderStyles : StyleSheet.create({

  container: {
    // minHeight: 52,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
  },
  buttonContainer: {
    height: FIELD_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: AppTheme.borderRadius,
    position: 'relative',
  },
  buttonContainerDisabled: {
    backgroundColor: '#f5f5f5'
  },
  fileInput: {
    width: '100%'
  },
  button: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    // minWidth: 150,
    padding: '5px 15px',
    fontSize: 10,
    color: AppTheme.secondaryBg
  },
  text: {
    fontSize: 10,
    margin: 0,
    marginRight: 10,
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: '#f44336',
  },
  textSuccess: {
    color: 'green',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    cursor: 'pointer',
    borderRadius: AppTheme.borderRadius
  },
  labelDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'unset',
  },
  attachFile: {
    marginRight: 10,
    color: AppTheme.primaryColor,
    minWidth: 160
  },
  closeIconContainer: {
    color: AppTheme.primaryColor,
    marginRight: 10
  },
  openDoc: {
    marginTop: 0,
    marginBottom: 0,
    color: '#7d7d7d',
    fontSize: 10,
    textDecoration: 'none',
    borderBottom: '0.5px solid #7d7d7d'
  },
  msgContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5
  },
  imageIconOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  imageIconContainer: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    display: 'flex',
    marginRight: 10
  },
  imageContent: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: '4px 15px'
  },
  targetContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  documentContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  },
  documentImageContainer: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
  closeImgContainer: {
    display: 'flex',
    cursor: 'pointer',
    color: '#222222',
    border: '1px solid' + '#222222',
    borderRadius: 4,
    padding: 2
  },
  closeImgDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  documentNameContainer: {
    lineHeight: 'unset',
    margin: 0,
    display: 'grid'
  },
  docName: {
    fontSize: 16,
    color: '#222222',
    margin: 0,
    alignItems: 'center',
    marginLeft: 10,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  attachFileText: {
    fontSize: 11,
    color: '#222222',
    //fontWeight: 500,
    margin: 0
  },
  attachFileTextBrowse: {
    color: AppTheme.primaryColor,
    fontWeight: 500
  },
  reuploadIconContainer: {
    right: 36
  },
  delImgContainer: {
    margin: '0px 10px'
  }
})
