/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
export const StringTableCatalog = [
  {
    "key": "cats",
    "en": "Categories"
  },
  {
    "key": "categories",
    "en": "Categories"
  },
  {
    "key": "level1",
    "en": "L1"
  },
  {
    "key": "level2",
    "en": "L2"
  },
  {
    "key": "level3",
    "en": "L3"
  },
  {
    "key": "brands",
    "en": "Brands"
  },
  {
    "key": "banners",
    "en": "Banners"
  },
  {
    "key": "admins",
    "en": "Staff"
  },
  {
    "key": "orders",
    "en": "Orders"
  },
  {
    "key": "lists",
    "en": "Collections"
  },
  {
    "key": "itemsOfList",
    "en": "Items of Collection"
  },
  {
    "key": "products",
    "en": "Products"
  },
  {
    "key": "product",
    "en": "Product"
  },
  {
    "key": "banners",
    "en": "Banners"
  },
  {
    "key": "brands",
    "en": "Brands"
  },
  {
    "key": "orders",
    "en": "Orders"
  },
  {
    "key": "productEditWhileLiveErr",
    "en": "You have to temporarily make the product offline(disable) before making this change. \
          Contact the customer care team if you face a problem."
  },
  {
    "key": "imageUploaderTitle",
    "en": "Images"
  },
  {
    "key": "vaiantsTitle",
    "en": "Variants"
  },
  {
    "key": "vaiantsOptionsTitle",
    "en": "Variant Configuration [ADVANCED]"
  },
  {
    "key": "variantSpecs",
    "en": "Specs"
  },
  {
    "key": "coupons",
    "en": "Coupons"
  },
  {
    "key": "forms",
    "en": "Forms"
  },
  {
    "key": "formfields",
    "en": "Form Fields"
  },
  {
    "key": "unableToDeleteTopicWarning",
    "en": "This topic is the parent topic of at least 1 other sub-topic. Please modify all sub-topics which depend on this and try deleting again."
  },
]
