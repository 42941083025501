import React, { Component } from 'react';

import { StyleSheet, css } from 'aphrodite';
import { CbImageSpots } from './CbImageSpots';

export class CustomCbs extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.cb.cbstyle === 'IMAGEHOTSPOT1') {
      return <CbImageSpots {...this.props}/>
    } else {
      return ''
    }
  }
}

const Styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
})