import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { BaseEntityPage } from '../../common/components/BaseEntityPage';
import { AppStyles } from '../../styles/AppTheme';
import { UiHelper } from '../../common/helpers/UiHelper';
import { Helper } from '../../common/helpers/Helper';
import { Controller } from '../../common/Controller';


export class Orgs extends BaseEntityPage {

  constructor(props) {
    super(props)
    this.pageTitle = Helper.getString('orgs')
    this.pageKey = 'adminOrgs'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: Controller.get().getHomeByRole() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs'],
        { pb: 'fieldDef', parentOrgId: 'ROOT' }) },
    ]
    this.tableHeaders = [
      'Id',
      'Name'
    ]
    this.apiData = {}
  }

  onFetchSuccess(results) {
    this.fieldDef = results[0].fieldDef
    this.formDefinition = this.fieldDef
    this.setState({
      items: results[0].items.sort(
        function(a, b) { return a.title.toLowerCase() >= b.title.toLowerCase() ? 1 : -1 })
    })
  }

  onTableValue(current, index) {
    switch (index) {
    case 0:
      return current.id
    case 1:
      return current.title
    default:
      return ''
    }
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['orgs']),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['orgs', current.id]),
    });
  }

  onActions(current) {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    // actions.push(
    //   <Link to={'/admin/orgs/' + current.id + '/users'} key={current.id + '-users'}>
    //     <Tooltip title='Users' placement='top-start'>
    //       <span className={css(AppStyles.entityTableLinkIcon)}>
    //         <i className='material-icons-outlined'>people</i>
    //       </span>
    //     </Tooltip>
    //   </Link>
    // )
    actions.push(
      <Link to={'/admin/orgs/' + current.id} key={current.id + '-org'}>
        <Tooltip title='Drill down' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>subdirectory_arrow_right</i>
          </span>
        </Tooltip>
      </Link>
    )
    actions.push(
      <Link to={'/admin/orgs/' + current.id + '/settings-master'} key={current.id + '-settings'}>
        <Tooltip title='Settings Master' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>settings</i>
          </span>
        </Tooltip>
      </Link>
    )
    actions.push(
      <Link to={'/admin/orgs/' + current.id + '/jobs'} key={current.id + '-jobs'}>
        <Tooltip title='Jobs' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>import_export</i>
          </span>
        </Tooltip>
      </Link>
    )
    actions.push(
      <Link to={'/admin/orgs/' + current.id + '/cbdefs'} key={current.id + '-cbdefs'}>
        <Tooltip title={Helper.getString('blocks')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>dns</i>
          </span>
        </Tooltip>
      </Link>
    )
    actions.push(
      <Link to={'/admin/orgs/' + current.id + '/cotypes'} key={current.id + '-cotypes'}>
        <Tooltip title={Helper.getString('cotypes')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>format_overline</i>
          </span>
        </Tooltip>
      </Link>
    )
    return actions
  }

}