import { AppConfigGen } from './AppConfigGen'

export const AppConfig = {
  ...AppConfigGen,
  SETTINGS_IN_MENU: false,
  LOGO: require('./assets/images/logo.svg'),
  LOGO_WHITE: require('./assets/images/logo-white.svg'),
  CATALOG_PARAMS: 'noTags',
  ALLOWMOCK: false,
  VERSION: '1.0.144',
  SLIXTA_WEB: 'https://slixta.com/',
  CUSTOM_STYLE: true,
  TERMS: 'https://slixta.com/terms',
  PRIVACY_POLICY: 'https://slixta.com/privacy',
  BRANDCOLOURS: ['#FFFFFF', '#000000', AppConfigGen.TINT_COLOUR, '#00000000'],
  GFONTNAME:'Raleway, sans-serif',
  TRANSPARENT_COLOR_CODE: '#00000000',
  SHOW_ALL_CB: 'NO',
  AWS_AMPLIFY_CONFIG: {
    Auth: {
      identityPoolId: 'ap-south-1:9463895f-8f4c-4c47-99cc-414893a8f54b',
      region: 'ap-south-1'
    },
    AWSPinpoint: {
      appId: 'fbde8183d8dd4bf4918b960598f27914',
      region: 'ap-south-1',
      mandatorySignIn: false,
    }
  },
  CONTENT_MS: {
    ENDPOINT: 'https://ez5wglip9b.execute-api.ap-south-1.amazonaws.com/Prod',
  },
  CDP_MS: {
    ENDPOINT: 'https://zbjjie97zk.execute-api.ap-south-1.amazonaws.com/Prod',
  }
}
