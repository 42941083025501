import { ApiHelper } from '../../common/helpers/ApiHelper'

export class CbHelper {
  static getParamsByCb(options) {
    const { cb, formType, formData, formDefinition, currentHotpot, items } = options
    if (cb.cbstyle === 'IMAGEHOTSPOT1') {
      if (formType === 'add') {
        if (formData.image) {
          for (const f of formDefinition.steps[0].fields) {
            if (f.key === 'image') {
              formData.imageLink = f.link
              if (formType === 'add') {
                formData.imageHeight = f._uploadedImgdimensions && f._uploadedImgdimensions.height
                formData.imageWidth = f._uploadedImgdimensions && f._uploadedImgdimensions.width
              }
            }
          }
        }
        return ({
          jsonBody: {
            order: '001',
            state: 'LIVE',
            cbId: formData.cbId,
            navLink: '',
            data: { ...formData }
          },
          method: 'POST',
          endPoint: ApiHelper.makeUrlPath(['cbdats'], {})
        })
      } else if (formType === 'edit' || formType === 'delete') {
        const editData = {}
        if (formType === 'edit') {
          if (formData.image) {
            for (const f of formDefinition.steps[0].fields) {
              if (f.key === 'image') {
                editData.imageLink = f.link
                editData.image = formData.image
              }
            }
          }
          if (formData.theme) {
            editData.theme = formData.theme
          }
          const hotspots = items[0].data && items[0].data.hotspots || []
          if (currentHotpot) {
            let foundHs = false
            for (const h of hotspots) {
              if (h.id === currentHotpot.widget.options.id) {
                for (const f of formDefinition.steps[0].fields) {
                  h[f.key] = formData[f.key] || ''
                }
                h.bottomLeftCoordinates = currentHotpot.blc
                h.topRightCoordinates = currentHotpot.trc
                foundHs = true
                break
              }
            }
            if (!foundHs) {
              const newH = {
                id: currentHotpot.id,
                bottomLeftCoordinates: currentHotpot.blc,
                topRightCoordinates: currentHotpot.trc
              }
              for (const f of formDefinition.steps[0].fields) {
                newH[f.key] = formData[f.key] || ''
              }
              hotspots.push(newH)
            }
            editData.hotspots = hotspots
          }
        } else {
          const hotspots = []
          for (const h of items[0].data.hotspots) {
            if (h.id === currentHotpot.widget.options.id) {
              continue
            }
            hotspots.push(h)
          }
          editData.hotspots = hotspots
        }
        return ({
          jsonBody: {
            order: items[0].order,
            state: items[0].state,
            cbId: items[0].cbId,
            navLink: '',
            data: { ...items[0].data, ...editData }
          },
          method: 'PUT',
          endPoint: ApiHelper.makeUrlPath(['cbdats', items[0].id], {})
        })
      }
    }
    return ({ jsonBody: null, method: null, endPoint: null })
  }
}
