import { css, StyleSheet } from 'aphrodite';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import React from 'react';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { AppStyles } from '../../../styles/AppTheme';

export class JourneysBase extends BaseEntityPage {
  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = options.pageTitle
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.groupId = 'settingscrmcontactattrs' + this.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: options.pageTitle },
    ]
    this.apiData = {
      orgId: this.orgId
    }
    this.headerActions = [
      {
        label: 'Add new',
        onClick: () => this.openModalForAdd(),
      },
      {
        label: 'Refresh',
        icon: 'refresh',
        onClick: () => this.fetchItems(),
      }
    ]
  }
  onEdit(current) {
    this.apiData = {
      orgId: this.orgId
    }
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['journeys', current.id], queryParams: {} }),
    });
  }

  onDelete(current) {
    this.apiData = {
      orgId: this.orgId
    }
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['journeys', current.id], {}),
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  updateJourneyState(current, action) {
    this.apiData = {
      updateAction: action,
      orgId: this.orgId
    }
    const fields = [{
      key: 'updateAction',
      label: 'Update action',
      type: 'edit_text',
      hint: 'Update action',
      // eslint-disable-next-line camelcase
      v_required: { value: true, err: 'Provide update action' },
      placement: 'left',
      value: action,
      disabled: true,
    }]
    current.updateAction = action
    super.openModalForEdit(current, {steps: [{ fields }]});
  }

  onActions(current) {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current, 'Edit Info'))
    if(current.state === 'DRAFT' || (current._extJourney ? current._extJourney.state === 'PAUSED' : current.state === 'PAUSED')) {
      actions.push(
        <Tooltip title={current.state === 'DRAFT' ? 'Start' : 'Resume'} placement='top-start' key={current.id + '-start'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => {
              if(current.state === 'DRAFT') {
                this.updateJourneyState(current, 'start')
              } else {
                this.updateJourneyState(current, 'resume')
              }
            }}>
          send
            </i>
          </span>
        </Tooltip>
      )
    }
    if(current._extJourney ? current._extJourney.state === 'ACTIVE' : current.state === 'LIVE') {
      actions.push(
        <Tooltip title='Pause' placement='top-start' key={current.id + '-pause'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => {
              this.updateJourneyState(current, 'pause')
            }}>
          pause
            </i>
          </span>
        </Tooltip>
      )
    }
    if(['ACTIVE', 'PAUSED', 'LIVE'].includes(current._extJourney ? current._extJourney.state : current.state)) {
      actions.push(
        <Tooltip title='Stop' placement='top-start' key={current.id + '-stop'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => {
              this.updateJourneyState(current, 'stop')
            }}>
          stop
            </i>
          </span>
        </Tooltip>
      )
    }
    if(current.iType === 'MESSAGING' && ['COMPLETED', 'PAUSED', 'LIVE', 'ACTIVE'].includes(current.state)) {
      actions.push(
        <Link to={this.baseRoute() + '/journeys/' + current.id + '/results'} key={current.id + '-results'}>
          <Tooltip title='Results' placement='top-start'>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined'>trending_up</i>
            </span>
          </Tooltip>
        </Link>
      )
    }
    // prevent delete, to preserve data
    //actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 16
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
})