import { StyleSheet } from 'aphrodite';
import { AppTheme, AppStyles } from '../../../styles/AppTheme';

export const SEStyles = StyleSheet.create({
  container: {
    background: 'white',
    height: '100%',
    borderRadius: AppTheme.borderRadius,
  },
  content: {
    padding: AppTheme.pagePadding * 4,
  },
  heading: {
    borderBottom: '1px solid lightgray',
  },
  headingContent: {
    padding: AppTheme.pagePadding * 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  editButton: {
    color: AppTheme.primaryColor,
    backgroundColor: 'none',
    fontWeight: 600,
    padding: 0,
    fontSize: 16,
    textTransform: 'none',
    minWidth: 'auto',
    ':hover': {
      background: 'none',
      backgroundColor: 'none',
    }
  },
  stepFormTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: 0,
    textTransform: 'capitalize'
  },
  label: {
    fontSize: 14,
    color: 'grey',
    marginBottom: 2,
    marginTop: 0
  },
  value: {
    margin: 0,
    fontSize: 16,
    color: '#222222',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  valueDisplayMultiline: {
    whiteSpace: 'normal'
  },
  actionIcon: {
    color: AppTheme.primaryColor
  },
  imageContainer: {
    width: 'fit-content',
    height: 35,
    border: '1px solid #E2EAFC',
    borderRadius: 8,
    overflow: 'hidden'
  },
  colourPickerContainer: {
    width: 35,
    height: 35,
    borderRadius: 8,
    overflow: 'hidden'
  },
  imageContent: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  link: {
    color: AppTheme.primaryColor,
    textDecoration: 'none',
    borderBottom: '0.5px solid ' + AppTheme.primaryColor,
    width: 'fit-content'
  },
  openFileText:{
    color: '#7d7d7d',
    borderBottom: '0.5px solid #7d7d7d'
  },
  buttonText: {
    color: AppTheme.primaryColor,
    fontWeight: 600
  },
  labelContainer: {
    paddingBottom: 20
  },
  contentForRow: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  labelContainerForRow: {
    paddingBottom: 20,
    width: '46%',
    '@media (max-width: 767px)': {
      width: '100%'
    }
  },
  dividerForRow: {
    width: '8%',
    '@media (max-width: 767px)': {
      width: '0%',
      display: 'none'
    }
  },
})
