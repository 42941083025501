import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { css } from 'aphrodite';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { AppStyles } from '../../../styles/AppTheme';

export class ContentTypes extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CONTENT_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('content-types')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('content-types') },
    ]
    this.toFetch = [
      {
        method: 'GET',
        paths: ['contents', 'PRODUCT'],
        queryParams: { groupId: this.orgId, pb: 'fieldDefContentTypesEntity', limit: 100 },
        ms: this.ms,
      },
    ]
    this.pageKey = 'content-types'
    this.tableHeadersMap = {
      Type: (current) => {
        return UiHelper.itemTitleId(current)
      },
      Status: (current) => current.status.toUpperCase(),
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.entityAttrName = 'types';
  }

  onFetchSuccess(results) {
    this.types = Object.values(results[0].types);

    const fieldDefContentTypesEntity = JSON.parse(JSON.stringify((results[0].fieldDefContentTypesEntity)));
    this.fieldDef = this.formDefinition = fieldDefContentTypesEntity;

    this.setState({
      items: this.types,
    })
  }

  onAdd() {
    this.formDefinition.disableAlwaysFormFields = ['status'];

    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName, fragments: [] },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', 'PRODUCT'], queryParams: {} }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', 'PRODUCT'], queryParams: {} }),
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []

    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(
      <Link to={`${this.baseRoute()}/content/types/${current.id}`} key={current.id + 'edit'}>
        <Tooltip title='View fragments' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>list</i>
          </span>
        </Tooltip>
      </Link>
    );

    return actions
  }
}
