import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Login } from '../components/Login';
import { Banner } from '../components/Banner';
import { AppTheme } from '../styles/AppTheme';

export class LoginPage extends Component {
  render() {
    return (
      <Banner>
        <Login
          loginPanelStyle={Styles.loginPanel}
          showTerms={true}>
        </Login>
      </Banner>
    )
  }
}
const Styles = StyleSheet.create({
  loginPanel: {
    backgroundColor: AppTheme.secondaryBg,
    textAlign: 'left'
  },
})
