import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { Helper } from '../helpers/Helper';
import { ApiHelper } from '../helpers/ApiHelper';
import { AppHelper } from '../../helpers/AppHelper';
import { BaseEntityPage } from './BaseEntityPage';
import { AppStyles } from '../../styles/AppTheme';
import { UiHelper } from '../helpers/UiHelper';

export class Testimonials extends BaseEntityPage {

  constructor(props) {
    super(props)
    this.pageTitle = Helper.getString('testimonials')
    this.pageKey = 'testimonials'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.groupId = 'testimonial' + this.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['items'],
        { groupId: this.groupId, pb: 'fieldDef' }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Order: (current) => { return current.order },
      Title: (current) => UiHelper.itemTitleImage(current),
      Descr: (current) => { return current.descr },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {}
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDef
    this.setState({
      items: Helper.sortDictArray(results[0].items)
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['items'], { groupId: this.groupId }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['items', current.id], { groupId: this.groupId }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['items', current.id], { groupId: this.groupId }),
    });
  }

  onActions(current) {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }

}