
import { Helper } from '../../common/helpers/Helper';
import { PageCbs } from './PageCbs';

export class PageCbsCoType extends PageCbs {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('cotypeblocks')
    this.entityAttrName = 'cotypeblocks'
  }
}