import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { css, StyleSheet } from 'aphrodite';

import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { StatCard } from '../../../common/components/StatCard';

export class PopupAnalytics extends BaseEntityPage {
  constructor(props) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = Helper.getString('popup-analytics')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.groupId = this.props.match.params.id
    this.groupIdiType = `${this.groupId}-popups`
    this.period = Helper.yearMonthDayHour(Date.now() - 30 * 24 * 60 * 60 * 1000)
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('popups'), to: this.baseRoute() + '/campaigns/popups' },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['analytics'], queryParams: { orgId: this.orgId, groupIdiType: this.groupIdiType, period: this.period } },
      { ms: this.ms, method: 'GET', paths: ['journeys', this.groupId], queryParams: { orgId: this.orgId } },
    ]
    this.apiData = {}
    this.noAdd = true
    this.pageKey = this.pageTitle
    this.headerActions = [
      {
        label: 'Refresh',
        icon: 'refresh',
        onClick: () => this.fetchItems(),
      }
    ]
    this.tableHeadersMap = {
      Date: (current) => {
        const date = Helper.yyyymmddToDate(current.yyyymmdd)
        return Helper.formatDate(date.getTime())
      },
      Views: (current) => current.views || 0,
      Clicks: (current) => current.clicks || 0,
      Conversions: (current) => current.conversions || 0,
      Desktop: (current) => current.desktop || 0,
      Mobile: (current) => current.mobile || 0,
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
  }

  onFetchSuccess(results) {
    this.analytics = this.transform(results[0].items)
    console.log('analytics: ', this.analytics)
    this.popupCampaign = results[1]
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('popups'), to: this.baseRoute() + '/campaigns/popups' },
      { title: this.popupCampaign.title },
    ]
    this.pageTitle = this.popupCampaign.title
    this.setState({
      items: this.analytics
    })
  }

  transform(rawItems) {
    if (!rawItems) return []
    return Object.values(rawItems.reduce((p, c) => {
      const yyyymmdd = c.period.toString().slice(0, -2)
      if (p[yyyymmdd]) {
        p[yyyymmdd].views = p[yyyymmdd].views + (c.viewCount || 0) + (c.showCount || 0)
        p[yyyymmdd].clicks = p[yyyymmdd].clicks + (c.clickCount || 0)
        p[yyyymmdd].conversions = p[yyyymmdd].conversions + (c.submitCount || 0)
        p[yyyymmdd].desktop = p[yyyymmdd].desktop + (c.desktopCount || 0)
        p[yyyymmdd].mobile = p[yyyymmdd].mobile + (c.mobileCount || 0)
      } else {
        p[yyyymmdd] = {
          yyyymmdd,
          views: (c.viewCount || 0) + (c.showCount || 0),
          clicks: c.clickCount || 0,
          conversions: c.submitCount || 0,
          desktop: c.desktopCount || 0,
          mobile: c.mobileCount || 0,
        }
      }
      return p
    }, {})).sort((a, b) => Number.parseInt(b.yyyymmdd) - Number.parseInt(a.yyyymmdd))
  }

  renderAboveTable() {
    const numTotalViews = this.analytics.reduce((p, c) => p + c.views, 0)
    const numTotalClicks = this.analytics.reduce((p, c) => p + c.clicks, 0)
    const numTotalConversions = this.analytics.reduce((p, c) => p + c.conversions, 0)
    const numDesktopViews = this.analytics.reduce((p, c) => p + c.desktop, 0)
    const numMobileViews = this.analytics.reduce((p, c) => p + c.mobile, 0)

    return <>
      <div className={css(Styles.aboveTableContainer)}>
        <Grid container className={css(Styles.numericStatContainer)}>
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'totalviews'}
            text={numTotalViews}
            subText={'Total Views'}
            cardIndex={1}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'totalclicks'}
            text={numTotalClicks}
            subText={'Total Clicks'}
            cardIndex={2}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'totalconversions'}
            text={numTotalConversions}
            subText={'Total Conversions'}
            cardIndex={3}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'desktopviews'}
            text={numDesktopViews}
            subText={'Desktop Views'}
            cardIndex={4}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'mobileviews'}
            text={numMobileViews}
            subText={'Mobile Views'}
            cardIndex={5}
            padding='small'
          />
        </Grid>
      </div>
    </>
  }

  onActions = (current) => {
    const actions = []
    return actions
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }
}

const Styles = StyleSheet.create({
  aboveTableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  numericStatItem: {
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
      height: '100%',
    },
    width: '20%',
  },
  numericStatContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    '@media (max-width: 767px)': {
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    justifyContent: 'space-between',
    marginBottom: 16,
    gap: '16px',
  },
})