import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppTheme } from '../../styles/AppTheme';
import { Helper } from '../helpers/Helper';
import { HelmetComponent } from '../components/HelmetComponent';
import { CustomNoPermissionStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { AppButton } from './AppButton';
import { UiHelper } from '../helpers/UiHelper';
import { Controller } from '../Controller';

export class NoPermission extends Component {
  render() {
    return (
      <div className={css(Styles.container)}>
        <div className={css(Styles.logoContainer)}>
          <img
            src={AppConfig.LOGO}
            alt={AppConfig.NAME}
            width='250'
            height='100'
            className={css(Styles.img)} />
        </div>
        <div className={css(Styles.nfMiddle)}>

          <HelmetComponent page={'no-permission'} />

          <p className={css(Styles.text)}>{Helper.getString('permissionErr')}</p>
          <div className={css(Styles.buttonContainer)}>
            <AppButton
              onClick={() => UiHelper.goToHome()}
              buttonText={Helper.getString('startAgain')}
            />
            <div className={css(Styles.divider)}></div>
            <AppButton
              onClick={() => Controller.get().logout()}
              buttonText={Helper.getString('logout')}
            />
          </div>
        </div>
      </div>
    )
  }
}


const Styles = AppConfig.CUSTOM_STYLE ? CustomNoPermissionStyles : StyleSheet.create({
  nfMiddle: {
    maxWidth: 500,
    fontSize: 16,
    paddingTop: '15%',
    textAlign: 'center'
  },
  button: {
    textAlign: 'center',
    backgroundColor: 'whitesmoke',
    fontSize: 16,
    borderRadius: 0,
    cursor: 'pointer',
    padding: 10,
    color: AppTheme.primaryColor
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    width: 10
  },
  logoContainer: {
    width: '100%',
    marginTop: 30
  },
  img: {
    width: 250,
    height: 'auto'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    maxWidth: 1310,
    padding: '0px 25px'
  }
})
