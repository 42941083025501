import React, { Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite';

import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { CustomCbDefFieldsStyles } from '../../../styles/CustomStyles';
import { AppConfig } from '../../../AppConfig';
import { CommonStyles } from '../../../styles/Styles';
import { Grid } from '@material-ui/core';
import { StatCard } from '../../../common/components/StatCard';

export class CampaignResult extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = Helper.getString('campaignresult')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.itemId = this.props.match.params.id
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('campaigns') },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['campaigns', this.itemId], queryParams: { orgId: this.orgId } },
    ]
    this.apiData = {
      orgId: this.orgId
    }
    this.noAdd = true
    this.headerActions = [
      {
        label: 'Refresh',
        icon: 'refresh',
        onClick: () => this.fetchItems(),
      }
    ]
  }

  onFetchSuccess(results) {
    this.item = results[0]
    const campaignChannel = this.item.channel
    const isOOH = campaignChannel === 'OOH'
    const campaignsLink = `${this.baseRoute()}/campaigns/${isOOH ? 'ooh' : 'broadcasts'}`
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('campaigns'), to: campaignsLink },
      { title: this.pageTitle },
    ]
    this.pageTitle = this.item.title
    this.setState({
      items: []
    })
  }

  renderAboveTable() {
    if (!this.item) return

    let tiles = []
    if (this.item.iType === 'BROADCAST') {
      const r = this.item._extCampaignPerformance.kpis
      tiles = [
        { title: 'Delivery', value: Math.round(r['delivery-rate'].percent) + '%' },
        { title: 'Bounce', value: Math.round(r['hard-bounce-rate'].percent) + '%' },
        { title: 'Opens', value: Math.round(r['open-rate'].percent) + '%' },
        { title: 'Clicks', value: Math.round(r.clicks.count) },
        { title: 'Attempted', value: Math.round(r['attempted-deliveries'].count) },
        { title: 'Successful', value: Math.round(r['successful-deliveries'].count) },
        { title: 'Recipients', value: Math.round(r['unique-deliveries'].count) },
        { title: 'Opens', value: Math.round(r['email-opens'].count) },
      ]
    } else if (this.item.iType === 'AD') {
      tiles = [
        { title: 'Screens', value: '1,241' },
        { title: 'Imprs', value: '1,304,432' },
      ]
    }

    return (
      <Fragment>
        <Grid container spacing={2} className={css(CommonStyles.statContainer)}>
          {tiles.map((tile, index) => (
            <StatCard
              // sm={5}
              key={tile.title}
              text={tile.value}
              subText={tile.title.toLocaleUpperCase()}
              cardIndex={index}
            />
          ))}
        </Grid>
      </Fragment>
    )
  }

  renderBelowTable() {
    return <p>Results may be delayed by 10 minutes.</p>
  }

  renderTable() {
    return ''
  }

  onActions = (current) => {
    const actions = []
    return actions
  }
}
