import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { createReactEditorJS } from 'react-editor-js'
import Undo from 'editorjs-undo';
import { EDITOR_JS_TOOLS } from './Tools'
import { Headerbar } from '../Headerbar';
import { Button, CircularProgress, Dialog } from '@material-ui/core';
import { Helper } from '../../helpers/Helper';
import { UiHelper } from '../../helpers/UiHelper';
import { BaseEntityPage } from '../BaseEntityPage';
import { ApiHelper } from '../../helpers/ApiHelper';
import { AppTheme } from '../../../styles/AppTheme';
import { CustomRichContentEditorStyles } from '../../../styles/CustomStyles';
import { AppConfig } from '../../../AppConfig';
import { AppButton } from '../AppButton';

//const edjsHTML = require('editorjs-html');

const parserFunctions = {
  image: (block) => {
    const caption = block.data.caption ? block.data.caption : 'Image';
    return `<img class="editorImg" src="${block.data.file.url}" alt="${caption}" />`;
  }
}
const ReactEditorJS = createReactEditorJS()
//const edjsParser = edjsHTML(parserFunctions);

export class RichContentEditor extends BaseEntityPage {

  constructor(props) {
    super(props)
    this.pageTitle = 'Blog content'
    this.pageKey = 'richContentEditor'
    this.orgId = this.props.match.params.orgId
    this.prodId = this.props.match.params.id
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: UiHelper.baseRoute(this.orgId) },
      { title: Helper.getString('blogs'), to: UiHelper.baseRoute(this.orgId) + '/blogs' },
      { title: this.pageTitle },
    ]
    this.editorJS = {}
    this.state = {
      isOpen: false
    }
    this.toFetch = [
      {
        key: 'item',
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['blogs', this.prodId], {
          orgId: this.orgId
        })
      }
    ]
  }

  onSubmit = async (data) => {
    this.setState({
      inProcess: true,
      errMsg: null
    })
    const body = {
      contentEjs: data
    }
    return ApiHelper.callAwait(
      {
        method: 'PUT', endPoint: ApiHelper.makeUrlPath(['blogs', this.prodId]),
        jsonBody: body,
      })
      .then(data => {
        this.setState({
          successMsg: 'Successful',
          inProcess: false,
          errMsg: null
        });
      })
      .catch(err => {
        const errorMessage = Helper.getErrorMsg(err);
        this.setState({
          errMsg: errorMessage,
          inProcess: false
        })
        return;
      });
  }

  onFetchSuccess(results) {
    const item = this.getResult(results, 'item')
    this.conf = {
      previewPath: '/blogs/' + item.slug
    }
    this.pageTitle = item.title

    this.setState({
      item: item,
    })
  }

  handleInitialize = (instance) => {
    this.editorJS.current = instance
  }

  handleReady = (editor) => {
    //new Undo({ editor });
    new Undo({ editor: this.editorJS.current });
  };

  renderContent = () => {
    return (
      <Dialog
        open={this.state.isOpen}
        aria-labelledby={this.props.title}
        fullWidth={true}
        fullScreen={true}
        maxWidth={false}
      >
        <div className={css(Styles.modalOuterContainer)}>
          <div className={css([Styles.modalHeader])}>
            <button
              className={css(Styles.modalCloseButton)}
              onClick={() => this.setState({ isOpen: false })}>
              <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
                <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
              </svg>
            </button>
          </div>
          <div className={css(Styles.modalInnerContainer)}>
            {this.state.html &&
              <div dangerouslySetInnerHTML={{ __html: this.state.html.join('') }} />
            }
          </div>
        </div>
      </Dialog>
    )
  }

  async handleSave() {
    const savedData = await this.editorJS.current.save();
    return this.onSubmit(savedData);

    //console.log('savedData', savedData)
    // const html = edjsParser.parse(savedData);
    //console.log('html', html)
    // this.setState({
    //   html: html
    // })
  }

  getBtn = () => {
    return (
      <AppButton
        buttonStyle={Styles.button}
        buttonTextStyle={Styles.buttonLabel}
        disabled={this.state.inProcess}
        onClick={() => this.handleSave()}
        buttonText={'Save'}
        inProcess={this.state.inProcess}
      />
    )
  }

  getPreviewBtn = () => {
    return (
      <AppButton
        buttonStyle={Styles.button}
        buttonTextStyle={Styles.buttonLabel}
        disabled={this.state.inProcess}
        onClick={async () => {
          await this.handleSave()
          this.openNewWindow()
        }}
        buttonText={Helper.getString('preview')}
      />
    )
  }

  renderHeaderbar() {
    const title = this.pageTitle ? this.pageTitle.toUpperCase() : null
    return (
      <Headerbar
        title={title}
        actionComponents={[this.getBtn(), this.getPreviewBtn()]}
        breadCrumbs={this.breadCrumbs}
        errMsg={this.state.errMsg}
      />
    )
  }

  renderAboveTable() {
    return (
      <Fragment>
        {this.renderHeaderbar()}
        <div className={css(Styles.outerContainer)}>
          {this.state.item &&
            <div className={css(Styles.imgContainer)}>
              <img className={css(Styles.img)} src={this.state.item.image}></img>
              <h1 className={css(Styles.blogTitle)}>{this.state.item.title}</h1>
              <h2 className={css(Styles.blogSummary)}>{this.state.item.descr}</h2>
            </div>
          }
          {this.state.item &&
            <div className={css(Styles.container)}>
              <ReactEditorJS
                holder='editorjs'
                onReady={this.handleReady}
                onInitialize={this.handleInitialize}
                defaultValue={this.state.item.contentEjs}
                tools={EDITOR_JS_TOOLS} ><div id='editorjs'></div></ReactEditorJS>
            </div>
          }
        </div>
        {this.renderContent()}
      </Fragment>
    )
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomRichContentEditorStyles : StyleSheet.create({
  outerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    width: '80%',
    marginBottom: 100,
    minHeight: 500,
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  modalInnerContainer: {
    marginTop: 120,
    marginBottom: 20,
    padding: 10,
    width: '60%'
  },
  modalHeader: {
  },
  modalCloseIcon: {
    width: 28,
    height: 28
  },
  modalCloseButton: {
    position: 'absolute',
    top: 50,
    right: 50,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalOuterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imgContainer: {
    width: '80%',
    backgroundColor: 'white',
    borderBottom: '1px solid #F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 40
  },
  img: {
    width: '70%'
  },
  blogTitle: {
    fontSize: 20,
    width: '70%',
    textAlign: 'left',
    paddingTop: 10
  },
  blogSummary: {
    width: '70%',
    color: '#3E4462',
    opacity: 0.7,
    margin: 0,
    marginBottom: 10,
    fontSize: 16,
  },
  button: {
    color: AppTheme.buttonTextColor,
    boxShadow: 'none',
    marginLeft: 10
  },
  buttonLabel: {
    marginLeft: 4
  },
  progressIcon: {
    marginLeft: 10,
    color: 'white'
  }
})
