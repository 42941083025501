import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { AppTheme, AppStyles } from '../../styles/AppTheme';
import { Checkbox, FormControl, Input, TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CustomSearchBarStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { AppButton } from './AppButton';
import { Helper } from '../helpers/Helper';

export class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: this.props.searchText || '',
      searchFilters: this.props.searchFilters || {},
    }
    this.debouncedSearch = this.props.autoRefresh && Helper.debounce(this.onSearch, 1000).bind(this);
  }

  render() {
    return (
      <div className={css(Styles.container)}>
        <form key='form' autoComplete='off' className={css(Styles.form)}>
          {this.props.filterOptions && <div className={css(Styles.filtersContainer)}>
            {this.props.filterOptions.map(filterOption => {
              if (!filterOption) return <></>
              const value = this.processFilterValueToPreFill(filterOption)
              return <FormControl className={css(Styles.formControlMainFilters)} fullWidth>
                <div className={css(Styles.labelContainer)}>
                  <p className={css(Styles.label)}>{filterOption.label}</p>
                  {filterOption.tip &&
                      <Tooltip title={filterOption.tip} classes={{ popper: css(Styles.tooltip) }}>
                        <i className='material-icons-outlined' style={{ fontSize: 14, color: '#7d7d7d', cursor: 'pointer', marginLeft: 5, marginTop: 0, alignSelf: 'center' }}>info</i>
                      </Tooltip>
                  }
                </div>
                <Autocomplete
                  value={value}
                  fullWidth={true}
                  disableCloseOnSelect={((this.props.autoRefresh) && (filterOption.multiSelect || filterOption.type === 'check_box'))}
                  multiple={filterOption.multiSelect || filterOption.type === 'check_box'}
                  options={filterOption.options}
                  onChange={(e, val) => this.onAutoCompleteChange(val, filterOption)}
                  getOptionLabel={(option) => option.label || option.text}
                  getOptionSelected={(option, value) => option.key === value.key}
                  ChipProps={{
                    deleteIcon: <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
                      <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
                    </svg>
                  }}
                  classes={{
                    tag: css(Styles.tag),
                    popupIndicator: css(Styles.popupIndicator),
                    popper: css(Styles.dialog)
                  }}
                  {...((this.props.autoRefresh) && (filterOption.multiSelect || filterOption.type === 'check_box') && { renderOption: (option, props) => (
                    <>
                      <Checkbox
                        color='primary'
                        checked={props.selected}
                      />
                      {option.label || option.text}
                    </>
                  ) })}
                  renderInput={params =>
                    <TextField
                      {...params}
                      variant={'standard'}
                      className={css(Styles.formControlFilters)}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        classes: {
                          root: css([Styles.textInputStyles, Styles.autoCompleteInputRoot]),
                          input: css(Styles.formInputStyle, Styles.textInput),
                        },
                      }} />}
                />
              </FormControl>
            })}
          </div>}
          <div className={css(Styles.innerContainer)}>
            <div className={css(Styles.searchContainer)}>
              <FormControl key='searchKeywords' margin='normal' fullWidth className={css(Styles.formControl)}>
                <Input className={css(Styles.searchInput)} type='text' disableUnderline={true}
                  onKeyPress={(e) => { if (e.key === 'Enter') this.onSearch() }}
                  placeholder={this.props.hint || 'Type keywords'} value={this.state.searchText} onChange={this.onSearchKeywordsChange.bind(this)} />
              </FormControl>
            </div>
            { !this.props.autoRefresh && <div className={css(Styles.buttonInnerContainer)}>
              <AppButton
                buttonStyle={Styles.button}
                buttonTextStyle={Styles.buttonText}
                onClick={this.onSearch.bind(this)}
                buttonText={this.props.searchButtonLabel || 'Search'}
              />
            </div>}
            <div className={css(Styles.buttonInnerContainer)}>
              {this.props.onList &&
                <AppButton
                  buttonStyle={Styles.button}
                  buttonTextStyle={Styles.buttonText}
                  onClick={this.props.onList.bind(this)}
                  buttonText={this.props.listButtonLabel || 'Show recent'} />
              }
            </div>
          </div>
        </form>
      </div>
    )
  }

  onSearchKeywordsChange = (event) => {
    const target = event.target;
    const value = target.value;
    this.setState({
      searchText: value
    }, () => {
      this.props.autoRefresh && this.debouncedSearch();
    })
  }

  onSearch() {
    this.setState({
      searchText: this.state.searchText || '',
      searchFilters: this.state.searchFilters || {},

    })
    this.props.onSearch(this.state.searchText || '', this.state.searchFilters || {})
  }

  onAutoCompleteChange = (value, current) => {
    if (!(current.multiSelect || current.type === 'check_box')) {
      this.setState(prevState => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState.searchFilters[current.key] = (value && value.key) || ''
        return newState
      },() => {
        this.props.autoRefresh && this.onSearch()
      })
    } else if (value) {
      const valDictByKey = {}
      const vals = []
      for (const v of value) {
        if (!valDictByKey[v.key]) {
          valDictByKey[v.key] = true
          vals.push(v.key)
        }
      }
      this.setState(prevState => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState.searchFilters[current.key] = vals
        return newState
      },()=>{
        this.props.autoRefresh && this.debouncedSearch()
      })

    }
  }

  processFilterValueToPreFill = (current) => {
    let value
    const currentFilter = this.state.searchFilters[current.key]
    if (!currentFilter || !current.options) return []
    if (current.multiSelect || current.type === 'check_box') {
      value = []
      for (const o of current.options) {
        if (currentFilter.includes(o.key)) {
          value.push({
            key: o.key,
            text: o.label || o.text
          })
        }
      }
    } else {
      for (const o of current.options) {
        if (o.key === currentFilter || (Array.isArray(currentFilter) && currentFilter.includes(o.key))) {
          value = {
            key: o.key,
            text: o.label || o.text
          }
        }
      }
    }
    return value
  }
}


const Styles = AppConfig.CUSTOM_STYLE ? CustomSearchBarStyles : StyleSheet.create({
  container: {
    minHeight: AppTheme.toolbarHeight * 2 / 3,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 16,
    maxWidth: AppTheme.viewPort,
  },
  searchInput: {
    minWidth: 150,
    padding: 10,
    paddingLeft: 22,
    border: '1px solid #E5E5E5',
    textDecoration: 'none',
    borderRadius: AppTheme.borderRadius,
    backgroundColor: 'white'
  },
  button: {
    color: AppTheme.primaryColor,
    border: '1px solid #E5E5E5',
    backgroundColor: AppTheme.secondaryBg
  },
  formControl: {
    margin: 0,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '@media (max-width: 320px)': {
      maxWidth: 300,
    },
    maxWidth: AppTheme.viewPort,
    width: '100%',
  },
  rightGridPadding: {
    paddingLeft: 19,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    }
  },
  innerContainer: {
    display: 'flex',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  searchContainer: {
    flex: 1
  },
  buttonInnerContainer: {
    paddingLeft: 10,
    minWidth: 130,
    '@media (max-width: 767px)': {
      flex: 1,
      paddingLeft: 0
    }
  },
  buttonText: {
    color: AppTheme.primaryColor,
    fontWeight: 600
  },
  filtersContainer: {
    display: 'flex',
    gap: '16px',
    marginBottom: '16px',
    '@media (max-width: 767px)': {
      gap: '4px',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  formControlMain: {
    marginTop: 0,
    marginBottom: 0
  },
  labelContainer: {
    display: 'flex',
    marginBottom: 8
  },
  label: {
    fontSize: 14,
    color: 'grey',
    margin: 0,
    // marginBottom: 8,
    display: 'flex',
  },
  tooltip: {
    zIndex: 9999999999
  },
  modalCloseIcon: {
    width: 12,
    height: 12,
    padding: 1,
    borderRadius: 6
  },
  tag: {
    backgroundColor: AppTheme.secondaryColor,
    color: '#222222',
    fontSize: 13,
    borderRadius: 8,
    height: 26
  },
  popupIndicator: {
    marginRight: 5,
  },
  dialog: {
    zIndex: 130000,
    fontSize: 16,
    color: '#222222',
  },
  formControlMainFilters: {
    marginTop: 16,
    '@media (max-width: 767px)': {
      width: '48%',
    },
  },
  formControlFilters: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    overflow: 'hidden'
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16
  },
  autoCompleteInputRoot: {
    paddingRight: 56
  },
  formInputStyle: {
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  textInput: {
    padding: '4px 0px'
  },
})
