import React, {  } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { Grid } from '@material-ui/core';

import { BaseEntityPage } from './BaseEntityPage';
import { ApiHelper } from '../helpers/ApiHelper';
import { AppTheme } from '../../styles/AppTheme';
import { Helper } from '../helpers/Helper';
import { SubEntityCard } from './SubEntity/SubEntityCard';

export class Settings extends BaseEntityPage {
  constructor(props, options) {
    super(props)
    this.pageTitle = Helper.getString('settings')
    this.pageKey = 'settings'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.emptyText = ' ' // space
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.groupId = 'settings' + this.orgId
    this.toFetch = [
      {
        key: 'item',
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['items', this.groupId + '-all'], {
          groupId: this.groupId,
          pb: 'fieldDefAddress,fieldDefCustomerCare,fieldDefSocialMedia' +
          ',fieldDefAbout,fieldDefInfo,fieldDefSEO,fieldDefFlashNotice' +
          ',fieldDefHeader,fieldDefFooter,fieldDefButton'
        })
      },
    ]
    this.noAdd = true
    this.apiData = {}
  }

  onFetchSuccess(results) {
    console.log('onFetchSuccess:', results)
    const item = this.getResult(results, 'item')

    this.fieldDefAbout = item.fieldDefAbout
    this.fieldDefCustomerCare = item.fieldDefCustomerCare
    this.fieldDefAddress = item.fieldDefAddress
    this.fieldDefSocialMedia = item.fieldDefSocialMedia
    this.fieldDefInfo = item.fieldDefInfo
    this.fieldDefSEO = item.fieldDefSEO
    this.fieldDefFlashNotice = item.fieldDefFlashNotice
    this.fieldDefHeader = item.fieldDefHeader
    this.fieldDefFooter = item.fieldDefFooter
    this.fieldDefButton = item.fieldDefButton

    this.setState({
      item
    })
  }

  renderAboveTable() {
    return (
      <div className={css(Styles.containerNarrow)}>
        <Grid container spacing={4}>
          {/* <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Info'} formDefinition={this.fieldDefInfo}
              item={this.state.item.info || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefInfo, 'info')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'SEO'} formDefinition={this.fieldDefSEO}
              item={this.state.item.seo || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefSEO, 'seo')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Header'} formDefinition={this.fieldDefHeader}
              item={this.state.item.header || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefHeader, 'header')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Footer'} formDefinition={this.fieldDefFooter}
              item={this.state.item.footer || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefFooter, 'footer')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Button'} formDefinition={this.fieldDefButton}
              item={this.state.item.button || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefButton, 'button')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Social Media'} formDefinition={this.fieldDefSocialMedia}
              item={this.state.item.socialMedia || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefSocialMedia, 'socialMedia')}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'About Us'} formDefinition={this.fieldDefAbout}
              item={this.state.item.about || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefAbout, 'about')}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Customer Care'} formDefinition={this.fieldDefCustomerCare}
              item={this.state.item.customerCare || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefCustomerCare, 'customerCare')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Address'} formDefinition={this.fieldDefAddress}
              item={this.state.item.address || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefAddress, 'address')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Flash Notice'} formDefinition={this.fieldDefFlashNotice}
              item={this.state.item.flashNotice || {}}
              openModalForEdit={(current) =>
                this.openModalForEdit(current, this.fieldDefFlashNotice, 'flashNotice')}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  openModalForEdit(current, formDefinition, attrName) {
    this.attrName = attrName
    this.formDefinition = formDefinition
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.setState({ modalOpen: true })
  }

  customSubmit(options) {
    const { formType, formData } = options
    console.log('customSubmit:', formData)
    ApiHelper.call({
      method: 'PUT', paths: ['items', this.groupId + '-all'], queryParams: { groupId: this.groupId },
      jsonBody: { [this.attrName]: { ...formData } }
    },
    (err, data) => {
      if (err) {
        const errorMessage = Helper.getErrorMsg(err)
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
        return
      } else {
        this.setState({ modalOpen: false })
        this.fetchItems()
      }
    })
  }
}

const Styles = StyleSheet.create({
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
  container: {
    textAlign: 'center',
    maxWidth: AppTheme.viewPort,
    marginBottom: 20,
    width: '100%',
  }
})