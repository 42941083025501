import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Popover from '@material-ui/core/Popover'
import { Helper } from '../helpers/Helper';
import MenuItem from '@material-ui/core/MenuItem'
import { Controller } from '../Controller';
import { AppConfig } from '../../AppConfig';
import { CustomSettingsMenuStyles } from '../../styles/CustomStyles';
import { AppTheme } from '../../styles/AppTheme';

export class SettingsMenu extends Component {
  constructor(props) {
    super(props)
  }

  logout = () => {
    Controller.get().logout()
  }

  navigateToSettings = () => {
    const pathname = '/admin/orgs/' + this.props.orgId + '/settings'
    Controller.get().pushRoute(pathname);
  }

  getOrgTitle = () => {
    const currentOrg = Controller.get().roleHelper().getOrg()
    return currentOrg.title || currentOrg.orgId
  }

  render() {
    const open = Boolean(this.props.settingPopover)
    const appUser = Controller.get().userMgr().getAppUser()
    return (
      <Popover
        id={Helper.getString('settings')}
        open={open}
        classes={{ paper: css(Styles.popoverContainer) }}
        anchorEl={this.props.settingPopover}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => this.props.settingClose()}
      >
        <div className={css(Styles.popoverContent)}>
          {appUser &&
            <MenuItem disabled className={css(Styles.menuItemContainer)}>
              <p className={css(Styles.menuItemContent)}>{appUser.email || appUser.phone}</p>
            </MenuItem>
          }
          {!Controller.get().userMgr().isProductSU() &&
            <MenuItem disabled className={css(Styles.menuItemContainer)}>
              <p className={css(Styles.menuItemContent)}>{this.getOrgTitle()}</p>
            </MenuItem>
          }
          {(appUser && appUser.orgs && appUser.orgs.length > 1 && !Controller.get().userMgr().isProductSU()) &&
            <MenuItem onClick={() => this.props.orgSwitcherDialogOpen()} className={css(Styles.menuItemContainer)}>
              <p className={css(Styles.menuItemContent)}>{Helper.getString('orgSwitcher')}</p>
            </MenuItem>
          }
          {this.props.orgId && AppConfig.SETTINGS_IN_MENU &&
            <MenuItem onClick={() => this.navigateToSettings()} className={css(Styles.menuItemContainer)}>
              <p className={css(Styles.menuItemContent)}>{Helper.getString('settings')}</p>
            </MenuItem>
          }
          <MenuItem onClick={() => this.logout()} className={css(Styles.menuItemContainer)}>
            <p className={css(Styles.menuItemContent)}>{Helper.getString('logout')}</p>
          </MenuItem>
          <p className={css(Styles.version)}>{Helper.getString('version')} {AppConfig.VERSION}</p>
        </div>
      </Popover>
    )
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomSettingsMenuStyles : StyleSheet.create({
  popoverContainer: {
    borderRadius: AppTheme.borderRadius,
    boxShadow: '3px 6px 43px rgba(0, 0, 0, 0.05)',
    border: '1px solid #E5E5E5',
    marginTop: 10,
    minWidth: 199
  },
  popoverContent: {
    padding: 5,
    paddingBottom: 10,
    display: 'grid'
  },
  popoverTitle: {
    color: '#222222',
    fontSize: 20,
    margin: 0,
    fontWeight: 500,
    padding: 10
  },
  menuItemContainer: {
    borderRadius: AppTheme.borderRadius,
    padding: '6px 10px'
  },
  menuItemContent: {
    margin: 0,
    color: '#222222',
    fontSize: 16,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  version: {
    color: '#7D7D7D',
    marginLeft: 12,
    fontSize: 12
  }
})
