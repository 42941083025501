import React, { Component } from 'react';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { StyleSheet, css } from 'aphrodite';
import Dialog from '@material-ui/core/Dialog';
import { AppStyles, AppTheme } from '../styles/AppTheme';
import Button from '@material-ui/core/Button';
import { CommonStyles } from '../styles/Styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';

export class DownloadDialog extends Component {
  constructor(props) {
    console.log('DownloadDialog:', props)
    super(props);
    this.state = {
    }
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    this.props.onClosed();
  }

  render() {
    return (
      <Dialog
        open={this.props.downloadDialog}
        classes={{
          paperWidthSm: css(Styles.dialog),
          scrollPaper: css(Styles.scrollPaper)
        }}
      >
        <div className={css([Styles.modalHeader, AppStyles.modalHeader])}>
          <div className={css(Styles.modalTitle)}>Download</div>
          <button className={css(Styles.modalCloseButton)} onClick={this.onCloseModal} disabled={this.props.inProcess}>
            <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
              <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
            </svg>
          </button>
        </div>
        <div className={css(Styles.modalContent)}>
          {(!this.props.inProcess && !this.props.errMsg && this.props.data) &&
          <div className={css(Styles.modalInnerContent)}>
            <a href={this.props.data.link} target='_blank' className={css(Styles.link)} >
              <Button
                className={css(CommonStyles.button, Styles.payButton)}
                variant='contained'
                color='primary'
              >
            Download File
                {this.props.inProcess && <CircularProgress size={24} className={css(Styles.marginLeft)} />}
              </Button>
            </a>
          </div>
          }
          {this.props.inProcess &&
          <div className={css(Styles.modalInnerContent)}>
            <CircularProgress size={30}  />
          </div>
          }
          {this.props.errMsg &&
            <p className={css(CommonStyles.textStyle, Styles.msgContentErr)}>{this.props.errMsg}</p>
          }
        </div>
      </Dialog>
    );
  }
}

const Styles = StyleSheet.create({
  dialog: {
    minWidth: 300,
    borderRadius: 8,
    padding: 15,
    paddingLeft: 15,
    paddingRight: 15,
    '@media(max-width: 767px)': {
      minWidth: 260,
    }
  },
  modalHeader: {
    paddingBottom: 10,
    borderBottom: '1px solid #d3d3d3',
  },
  modalTitle: {
    color: 'black',
    fontSize: 20,
  },
  modalContent: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 90
  },
  modalInnerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalCloseButton: {
    position: 'absolute',
    top: 15,
    right: 15,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalCloseIcon: {
    width: 18,
    height: 18
  },

  marginLeft: {
    marginLeft: 10,
    color: 'white'
  },
  payButton: {
    backgroundColor: AppTheme.primaryColor,
    color: AppTheme.buttonTextColor,
    borderRadius: AppTheme.pagePadding * 4,
    textAlign: 'center',
    letterSpacing: 0,
    textTransform: 'unset',
    opacity: 1,
    minWidth: 200,
    '@media(max-width: 767px)': {
      minWidth: 160,
    }
  },
  msgContentErr: {
    color: 'red'
  },
  link: {
    textDecoration: 'unset'
  }
})
