import { AppHelper } from '../../helpers/AppHelper'
import { Controller } from '../Controller'

export class RoleHelper {

  getAppUser() {
    return Controller.get().userMgr().getAppUser()
  }

  canEditOrg() {
    return this.isOrgSU() || this.isProductSU()
  }

  isProductSU() {
    return Controller.get().userMgr().isProductSU()
  }

  isOrgSU() {
    return this.checkOrgAndRoleMatch(this.getOrgId(), 'SUPERUSER')
  }

  isPartnerSU() {
    return this.checkGroupAndRole('p', 'SUPERUSER')
  }

  hasProductRole(role) {
    return Controller.get().userMgr().hasOrgRole('PRODUCT', role)
  }

  hasOrgRole(orgId, role) {
    return Controller.get().userMgr().hasOrgRole(orgId, role)
  }

  hasCurrentOrgRole(role) {
    return Controller.get().userMgr().hasOrgRole(this.getOrgId(), role)
  }

  getSelectedOrgId() {
    const orgInfoStr = localStorage.getItem('orgInfo')
    if(orgInfoStr) {
      const orgInfo = JSON.parse(orgInfoStr)
      return orgInfo.currentOrgId
    } else {
      return null
    }
  }

  setCurrentOrgInfo(orgId) {
    const orgInfo = {
      currentOrgId: orgId
    }
    localStorage.setItem('orgInfo', JSON.stringify(orgInfo))
  }

  getSelectedRole() {
    return null
  }

  getOrgs() {
    const user = this.getAppUser()
    return user ? user.orgs : []
  }

  getOrgId() {
    const selectedOrgId = AppHelper.parseOrgId(window.location.pathname) || this.getSelectedOrgId()
    if (selectedOrgId) {
      return selectedOrgId
    }
    // Nothing Selected return default orgId
    const orgs = this.getOrgs()
    if (orgs.length) {
      return orgs[0].orgId
    }
    return null
  }

  getOrg() {
    let org = null
    const orgs = this.getOrgs()
    const orgId = this.getOrgId()
    for (let i = 0; i < orgs.length; i++) {
      if (orgs[i].orgId === orgId) {
        org = orgs[i]
        break
      }
    }
    return org
  }

  getUserRolesOfOrg() {
    const org = this.getOrg()
    return (org && org.roles) ? org.roles : []
  }

  checkOrg(orgId) {
    return this.getOrgId() === orgId
  }

  checkGroup(groupId) {
    const org = this.getOrg()
    return org && (org.groupId === groupId)
  }

  checkRole(role) {
    const selectedRole = this.getSelectedRole()
    if (selectedRole) {
      return selectedRole === role
    }
    const roles = this.getUserRolesOfOrg()
    for (let i = 0; i < roles.length; i++) {
      if (roles[i] === role) {
        return true
      }
    }
    return false
  }

  checkGroupAndRole(groupId, role) {
    return this.checkGroup(groupId) && this.checkRole(role)
  }

  checkOrgAndRoleMatch(orgId, role) {
    return this.checkOrg(orgId) && this.checkRole(role)
  }
}
