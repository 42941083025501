import { CampaignsBase } from './CampaignsBase';
import { Helper } from '../../../common/helpers/Helper';

export class Notifications extends CampaignsBase {
  constructor(props, options) {
    super(props, {
      ...options,
      pageTitle: Helper.getString('notifications'),
      campaignType: 'NOTIFICATION',
    });
  }
}
