import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { Helper } from '../helpers/Helper';
import { ApiHelper } from '../helpers/ApiHelper';
import { AppHelper } from '../../helpers/AppHelper';
import { BaseEntityPage } from './BaseEntityPage';
import { AppStyles } from '../../styles/AppTheme';
import { UiHelper } from '../helpers/UiHelper';

export class Faqs extends BaseEntityPage {

  constructor(props) {
    super(props)
    this.pageTitle = Helper.getString('faqs')
    this.pageKey = 'faqs'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.groupId = 'faq' + this.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['items'],
        { groupId: this.groupId, pb: 'fieldDef' }) },
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['items'],
        { groupId: 'faqsection' + this.orgId }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Order: (current) => { return current.order },
      Section: (current) => { return UiHelper.fieldDefResolve({ def: this.formDefinition, values: current, key: 'iType' }) },
      Title: (current) => { return current.title },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {}
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDef
    UiHelper.populateOptions(this.formDefinition, results[1], 'iType')
    const iTypeById = Helper.itemsToMap(results[1].items)
    const items = results[0].items
    items.forEach(i => {
      const iType = i.iType && iTypeById[i.iType]
      i.iTypeOrder = (iType && iType.order || '999') + '-' + i.iType + '-' + i.order}
    )
    console.log(items)
    this.setState({
      items: Helper.sortDictArray(items, 'iTypeOrder')
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['items'], { groupId: this.groupId }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['items', current.id], { groupId: this.groupId }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['items', current.id], { groupId: this.groupId }),
    });
  }

  onActions(current) {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }

}