import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';
import { UiHelper } from '../../helpers/UiHelper';

export class ProductsByBrand extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('products')
    this.pageKey = 'productsByBrand'
    this.noAdd = true
    this.brandId = this.props.match.params.id
    this.orgId = this.props.match.params.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: 'Brands', to: '/admin/orgs/' + this.orgId + '/brands' },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['products'], { orgId: this.orgId, brandId: this.brandId, pb: 'fieldDef' }) },
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['brands', this.brandId], { orgId: this.orgId }) },
    ]
    this.tableHeadersMap = {
      'Slug / ID': (current) => {
        return UiHelper.itemDetails(current)
      },
      Title: (current) => current.title,
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      brandId: this.brandId
    }
  }

  onFetchSuccess(results) {
    this.pageTitle = Helper.getString('products')
    this.setState({
      items: Helper.sortDictArray(results[0].items),
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonOpenProduct(this, current))
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})