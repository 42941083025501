import React, { useEffect } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import { ApiHelper } from '../../common/helpers/ApiHelper'
import { Helper } from '../../common/helpers/Helper'
import { CbSelection } from '../../common/components/CbSelection';
import { cbSource } from '../../data/data'
import { AppTheme } from '../../styles/AppTheme'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { AppConfig } from '../../AppConfig'
import { Tooltip } from '@material-ui/core'
import { AppButton } from '../../common/components/AppButton'
const uuid = require('uuid');

const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppTheme.primaryColor
    },
    secondary: {
      main: AppTheme.secondaryColor
    }
  },
  typography: {
    fontFamily: [
      AppConfig.GFONTNAME
    ].join(',')
  }
});

const ReactComponent = (props) => {
  const [blockSelectionModal, setblockSelectionModal] = useState(false)
  const [blocks, setBlocks] = useState([])
  const [selectedBlock, setBlock] = useState(null)

  useEffect(() => {
    setBlocks(props.cbs)
    setblockSelectionModal(true)
  }, [])

  const doActionNext = (selectedBlockSource, selectedBlockType, selectedBlock) => {
    setblockSelectionModal(false)
    setBlock(selectedBlock)
    props.setCbId(selectedBlock.id)
  }

  const getTitle = (cbId) => {
    for (const block of blocks) {
      if (block.id === cbId) return block.title
    }
  }

  const openNewWindow = () => {
    window.open('/admin/orgs/' + props.orgId + '/preview?ppath=' + props.previewPath, 'slixtaPreivew');
  }

  const renderCb = () => {
    return (
      <CbSelection
        selectedBlock={'SITE'}
        title={Helper.getString('blockSelection')}
        isOpen={blockSelectionModal}
        onClosed={() => setblockSelectionModal(false)}
        cbSite={{ items: blocks }}
        doActionNext={doActionNext}
        cbSource={cbSource}
        hideBlockSelector={true}
      />
    )
  }

  if (props.cbId) {
    return (
      <div className={css(Styles.container)}>
        <p>{getTitle(props.cbId)}</p>
        <Tooltip title={Helper.getString('preview')}>
          <i onClick={() => openNewWindow()} className='material-icons-outlined' style={{ fontSize: 18, marginLeft: 10, fontStyle: 'normal', cursor: 'pointer' }}>desktop_windows</i>
        </Tooltip>
      </div>
    )
  }

  return (
    <div>
      {selectedBlock ?
        <div className={css(Styles.container)}>
          <p>{selectedBlock.title}</p>
        </div>
        :
        <div className={css(Styles.container)}>
          <AppButton
            onClick={() => setblockSelectionModal(true)}
            buttonText={Helper.getString('blockSelection')}
          />
        </div>
      }
      {blockSelectionModal && renderCb()}
    </div>
  )
}

export default class SlixtaWidget {
  constructor({ data, api, config }) {
    // provided by EditorJS
    this.api = api
    this.config = config
    this.data = data
    this.container = undefined
    this.cbId = data.cbId || null
    this._CSS = {
      block: this.api.styles.block,
      react: 'react-component',
    }
    this.blockId = uuid()
  }

  static get toolbox() {
    return {
      title: 'Slixta Widgets',
      icon: `<svg width="118" height="40" viewBox="0 0 118 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9197 37.2274C8.84657 37.2274 7.00078 36.8729 5.38236 36.1639C3.76394 35.4529 2.48656 34.4804 1.55024 33.2464C0.613907 32.0104 0.0889019 30.6256 -0.0247803 29.0919H7.1713C7.25812 29.9145 7.64153 30.5822 8.32156 31.0948C9.00365 31.6053 9.8418 31.8606 10.836 31.8606C11.7434 31.8606 12.4451 31.6828 12.9412 31.3273C13.4393 30.9718 13.6884 30.5108 13.6884 29.9445C13.6884 29.2624 13.3329 28.7581 12.6218 28.4315C11.9129 28.1049 10.7637 27.7432 9.17418 27.3463C7.47101 26.9474 6.05102 26.5278 4.9142 26.0876C3.77944 25.6473 2.79971 24.9518 1.97499 24.001C1.15235 23.0502 0.741024 21.7656 0.741024 20.1472C0.741024 18.785 1.11721 17.5428 1.86958 16.4204C2.62195 15.2981 3.7288 14.4103 5.19013 13.7572C6.65353 13.104 8.39287 12.7774 10.4081 12.7774C13.3908 12.7774 15.7409 13.5164 17.4585 14.9943C19.1761 16.4701 20.1631 18.4295 20.4194 20.8727H13.6884C13.5747 20.0479 13.213 19.3948 12.6032 18.9132C11.9935 18.4295 11.1915 18.1877 10.1973 18.1877C9.34573 18.1877 8.69258 18.351 8.23785 18.6776C7.78312 19.0041 7.55576 19.4516 7.55576 20.02C7.55576 20.7001 7.91747 21.2106 8.64091 21.5517C9.3664 21.8927 10.495 22.2337 12.0266 22.5748C13.7876 23.0295 15.2221 23.477 16.33 23.9173C17.4378 24.3575 18.4103 25.0675 19.2475 26.0473C20.0846 27.027 20.5176 28.3395 20.5465 29.9848C20.5465 31.3779 20.1559 32.6212 19.3746 33.7146C18.5933 34.806 17.4709 35.6648 16.0075 36.2911C14.5462 36.9153 12.8502 37.2274 10.9197 37.2274ZM32.3027 5.36743V36.8863H25.0198V5.36743H32.3027Z" fill="blue"/>
      <path d="M41.2466 10.6475C39.9693 10.6475 38.9255 10.2713 38.1152 9.51891C37.307 8.76654 36.903 7.83641 36.903 6.72853C36.903 5.59377 37.307 4.65021 38.1152 3.89784C38.9255 3.14547 39.9693 2.76929 41.2466 2.76929C42.4972 2.76929 43.5265 3.14547 44.3347 3.89784C45.1449 4.65021 45.55 5.59377 45.55 6.72853C45.55 7.83641 45.1449 8.76654 44.3347 9.51891C43.5265 10.2713 42.4972 10.6475 41.2466 10.6475ZM44.8679 13.1185V36.8864H37.585V13.1185H44.8679Z" fill="#00CA6F"/>
      <path d="M64.2043 36.8864L59.6466 30.2855L55.8145 36.8864H47.9332L55.7711 24.7482L47.7224 13.1185H55.8982L60.4559 19.679L64.2911 13.1185H72.1693L64.2043 25.0892L72.3832 36.8864H64.2043ZM88.4401 30.7103V36.8864H84.7351C82.0936 36.8864 80.0349 36.2404 78.5591 34.9486C77.0833 33.6567 76.3454 31.5485 76.3454 28.6237V19.1674H73.4465V13.1185H76.3454V7.3269H83.6283V13.1185H88.3998V19.1674H83.6283V28.7074C83.6283 29.4185 83.7988 29.93 84.1398 30.2421C84.4809 30.5543 85.0483 30.7103 85.842 30.7103H88.4401ZM91.0402 24.959C91.0402 22.518 91.5012 20.3745 92.423 18.5287C93.347 16.683 94.6037 15.263 96.1931 14.2688C97.7826 13.2746 99.5571 12.7775 101.517 12.7775C103.193 12.7775 104.662 13.1185 105.925 13.8006C107.188 14.4827 108.161 15.3766 108.843 16.4825V13.1185H116.126V36.8864H108.843V33.5224C108.134 34.6282 107.147 35.5222 105.882 36.2043C104.619 36.8864 103.149 37.2274 101.473 37.2274C99.5426 37.2274 97.7826 36.7241 96.1931 35.7175C94.6037 34.7088 93.347 33.2744 92.423 31.4141C91.5012 29.5538 91.0402 27.4022 91.0402 24.959ZM108.843 25.0024C108.843 23.1856 108.339 21.7521 107.33 20.7021C106.323 19.6501 105.096 19.124 103.647 19.124C102.198 19.124 100.969 19.6428 99.9602 20.6804C98.9536 21.716 98.4503 23.1422 98.4503 24.959C98.4503 26.7779 98.9536 28.2196 99.9602 29.2841C100.969 30.3486 102.198 30.8808 103.647 30.8808C105.096 30.8808 106.323 30.3558 107.33 29.3058C108.339 28.2537 108.843 26.8193 108.843 25.0024Z" fill="blue"/>
      </svg>`
    }
  }

  setCbId = (cbId) => {
    this.cbId = cbId
  }

  render() {
    if (!this.container) {
      this.container = document.createElement('div')
      this.container.classList.add(this._CSS.block)
      ReactDOM.render(
        <MuiThemeProvider theme={theme}>
          <ReactComponent
            {...this.data}
            {...this.config}
            className={this._CSS.react}
            blockId={this.blockId}
            setCbId={this.setCbId}
          /></MuiThemeProvider>,
        this.container,
      )
    }
    return this.container
  }

  rendered() {
    console.log('SlixtaWidget was rendered')
  }

  moved() {
    console.log('SlixtaWidget was moved')
  }

  removed() {
    console.log('SlixtaWidget was removed')
    ReactDOM.unmountComponentAtNode(this.container)
  }

  save(element) {
    return {
      cbId: this.cbId
    }
  }
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    borderRadius: AppTheme.borderRadius,
    border: '1px solid ' + AppTheme.borderColor
  },
  title: {
    margin: 0
  }
})