/* eslint-disable camelcase */
import React from 'react';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { Controller } from '../../../common/Controller';

export class CrmUserSettings extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('crm-user-settings')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.staffUserId = Controller.get().getUserId()
    this.orgIdUserId = `${this.orgId}-${this.staffUserId}`
    this.groupId = 'settingscrmusersettings' + this.orgIdUserId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('crm-user-settings') },
    ]
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['items', `${this.groupId}-all`], queryParams: { groupId: this.groupId, pb: 'fieldDefUserSettings', limit: 1 } },
    ]
    this.apiData = {
      //orgIdUserId: this.orgIdUserId,
    }
    this.pageKey = 'crm-user-settings'
    const _this = this
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Attribute: (current) => UiHelper.extractSettingsLabel(current, _this.fieldsUserSettings),
      Value: (current) => {
        const fields = UiHelper.extractSettingsFields(current, _this.fieldsUserSettings)
        const value = UiHelper.extractSettingsValue(current)
        return (fields[0].type === 'rich_text') ? <div dangerouslySetInnerHTML={{ __html: Helper.renderHtmlText(value)}}></div> : value
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.noAdd = true
    this.noTableHead = true
  }

  onFetchSuccess(results) {
    this.crmUserSettings = results[0]
    this.fieldsUserSettings = results[0].fieldDefUserSettings.steps[0].fields

    this.tableItems = []
    this.fieldsUserSettings.forEach(a => {
      this.tableItems.push({
        [a.key]: this.crmUserSettings[a.key] || '',
      })
    })

    this.setState({
      items: this.tableItems,
    })
  }

  openModalForEdit(current) {
    this.formDefinition = {
      steps: [
        {
          fields: UiHelper.extractSettingsFields(current, this.fieldsUserSettings)
        }
      ]
    }

    super.openModalForEdit(current, this.formDefinition)
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['items', `${this.groupId}-all`], queryParams: {groupId: this.groupId} }),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    return actions
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }
}
