/* eslint-disable camelcase */
import { StyleSheet, css } from 'aphrodite';
import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppStyles } from '../../../styles/AppTheme';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { UiHelper } from '../../../common/helpers/UiHelper';

export class ContentBlogs extends BaseEntityPage {
  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CONTENT_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('content-cluster-blogs')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.topicClusterId = this.props.match.params.topicClusterId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('content-planner'), to: this.baseRoute() + '/content/planner' },
    ]
    this.toFetch = [
      {
        method: 'GET',
        paths: ['contents', this.orgId],
        queryParams: { groupId: this.orgId, pb: 'fieldDefContentTopicsEntity', limit: 1 },
        ms: this.ms,
      },
    ]
    this.pageKey = 'content-cluster-blogs'
    this.tableHeadersMap = {
      'Blog Title': (current) => {
        return UiHelper.itemTitleId(current)
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.entityAttrName = 'topics';
    this.noAdd = true;
    this.headerActions = [{
      label: this.addNewLabel || 'Generate blog',
      onClick: () => this.onGenerateBlogClick()
    }];

    this.personas = this.personas || [];
    this.topics = this.topics || [];
    this.clusters = this.clusters || [];
    this.blogs = this.blogs || [];
    this.pillar = '';
    this.blogIds = [];

    this.state.topicCluster = {};
  }

  async onFetchSuccess(results) {
    const MAX_BLOGS_TO_REQUEST = 100;
    this.personas = Object.values(results[0].personas);
    this.topics = Object.values(results[0].topics || {});
    this.topicCluster = this.topics.find(t => t.id.toString() === this.topicClusterId) || {};
    this.pillar = this.topicCluster.topic || '';
    this.blogIds = this.topicCluster.blogs.slice(0, MAX_BLOGS_TO_REQUEST);

    if (this.blogIds.length === 0) {
      this.blogIds.push(-1);
    }

    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('content-planner'), to: this.baseRoute() + '/content/planner' },
      { title: this.pillar, to: this.baseRoute() + `/content/pillars/${this.pillar}` },
      { title: this.topicCluster.title || '' }
    ]

    await ApiHelper.callAwait({ method: 'GET', paths: ['miscs', 'blogs'], queryParams: { orgId: this.orgId, blogIds: this.blogIds.join(','), allvisible: true } })
      .then(response => {
        this.blogs = response.items;
        this.setState({
          items: this.blogs,
          topicCluster: this.topicCluster,
        })
      })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = [];
    actions.push(
      <Link to={this.baseRoute() + '/blogs/' + current.id} key={current.id} target='_blank' rel='noopener noreferrer'>
        <Tooltip title='Open' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>open_in_new</i>
          </span>
        </Tooltip>
      </Link>
    )
    return actions;
  }

  onGenerateBlogClick() {
    const topicPillar = this.state.topicCluster.topic || '';
    const topicClusterTitle = this.state.topicCluster.title || '';
    const personaId = this.state.topicCluster.persona || '';

    const queryParams = {
      personaId,
      topicPillar,
      topicClusterTitle,
      topicId: this.state.topicCluster.id || '',
    };
    window.open(this.baseRoute() + '/content/generator?' + Helper.encodeQueryString(queryParams));
  }

  renderAboveTable() {
    const pillar = this.state.topicCluster.topic || '';
    const clusterTitle = this.state.topicCluster.title || '';
    const personaTitle = (this.personas.find(p => p.id === this.state.topicCluster.persona) || {}).title || '';
    return <div className={css(Styles.aboveHeader)}>
      {
        pillar && <div className={css(Styles.aboveHeaderItem)}>Topic Pillar:&nbsp;<strong>{pillar}</strong></div>
      }
      {
        clusterTitle && <div className={css(Styles.aboveHeaderItem)}>Cluster Title:&nbsp;<strong>{clusterTitle}</strong></div>
      }
      {
        personaTitle && <div className={css(Styles.aboveHeaderItem)}>User Persona:&nbsp;<strong>{personaTitle}</strong></div>
      }
    </div>
  }
}

const Styles = StyleSheet.create({
  aboveHeader: {
    width: '100%',
    fontSize: 14,
    border: '2px solid #F3F4F6',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
    color: '#888888',
  },
  aboveHeaderItem: {
    marginBottom: '8px',
  },
});