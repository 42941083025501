import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { AppConfig } from '../AppConfig';
import { AppTheme } from '../styles/AppTheme';
import { CommonStyles } from '../styles/Styles';
import { AppHelper } from '../helpers/AppHelper';
import { AppButton } from '../common/components/AppButton';
import { Helper } from '../common/helpers/Helper';

export class PublicHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.items = AppHelper.getPublicDrawerMenuItems()
  }

  headerBackgroundTransparentStyle = () => {
    const pathname = window.location.pathname
    const transparentStyle = css([Styles.appbar, Styles.appbarTransparent, CommonStyles.pagePadding])
    if (pathname === '/') {
      return transparentStyle
    }
    if (pathname === '/login') {
      return transparentStyle
    }
    return css(Styles.appbar, CommonStyles.pagePadding)
  }

  headerBarButton = () => {
    const pathname = window.location.pathname
    // if (pathname === '/') {
    //   return (
    //     <Link target='_blank' to={AppConfig.SLIXTA_WEB} className={css(Styles.link)}>
    //       <AppButton
    //         fullWidth
    //         buttonStyle={Styles.buttonHomeStyle}
    //         buttonTextStyle={Styles.buttonTextHomeStyle}
    //         buttonText={Helper.getString('knowAboutSlixta')}
    //       />
    //     </Link>
    //   )
    // }

    if (pathname === '/login' || pathname === '/') {
      return null
    }

    return <div className={css(Styles.buttonContainer)} >
      <Link to={AppConfig.SLIXTA_WEB} target={'_blank'} className={css(Styles.link, Styles.knowMore)}>
        <p  className={css(Styles.buttonTextSecondaryStyle)}>{Helper.getString('knowAbout')}<span className={css(Styles.cName)}>Slixta</span></p>
      </Link>
      <Link to={'/login'} className={css(Styles.link)}>
        <AppButton
          fullWidth
          buttonStyle={[CommonStyles.buttonStyle, Styles.buttonStyle]}
          buttonTextStyle={[CommonStyles.buttonTextStyle, Styles.buttonTextStyle]}
          buttonText={Helper.getString('ctaButtonLabel')}
        />
      </Link>
    </div>
  }

  render() {
    const headerBackgroundStyle = this.headerBackgroundTransparentStyle()
    return (
      <div>
        <AppBar className={headerBackgroundStyle} color='primary' elevation={0} position='fixed'>
          <Toolbar className={css(Styles.toolbar)}>
            <div className={css(Styles.logoContainer)}>
              <Link to={'/'}>
                <img src={AppConfig.LOGO_WHITE}
                  alt={AppConfig.NAME} className={css(CommonStyles.logoImage)} />
              </Link>
            </div>

            <div className={css(Styles.fillSpace)}></div>

            {/* <div>
              {this.headerBarButton()}
            </div> */}
          </Toolbar>
        </AppBar>
      </div>
    );
  }

}

const Styles = StyleSheet.create({
  appbar: {
    backgroundColor: AppTheme.primaryColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 20px'
  },
  appbarTransparent: {
    backgroundColor: 'transparent',
  },
  toolbar: {
    minHeight: AppTheme.toolbarHeight,
    width: '100%',
    padding: 0
  },
  logoContainer: {
    textAlign: 'left',
    display: 'flex',
    marginRight: 15
  },
  fillSpace: {
    flex: 1
  },
  buttonStyle: {
    backgroundColor: AppTheme.secondaryBg,
    minWidth: 390,
    minHeight: 74,
    padding: '10px 20px',
    '@media(max-width: 767px)': {
      minWidth: 'unset'
    },
  },
  buttonTextStyle: {
    color: AppTheme.primaryColor,
    fontWeight: 500,
    fontSize: 20
  },
  buttonSeconadryStyle: {
    backgroundColor: AppTheme.primaryColor,
    minWidth: 246,
    padding: '10px 20px',
    marginRight: 20,
    '@media(max-width: 767px)': {
      display: 'none'
    },
  },
  buttonTextSecondaryStyle: {
    fontSize: 16,
    fontWeight: 400,
    marginRight: 64,
    color: AppTheme.secondaryBg,
    textTransform: 'none',
    '@media(max-width: 767px)': {
      fontSize: 13,
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  cName: {
    marginLeft: 5,
    borderBottom: '1px solid white',
    fontWeight: 'bold'
  },
  knowMore: {
    '@media(max-width: 767px)': {
      display: 'none',
    },
  }
})
