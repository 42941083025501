
import { Helper } from '../../common/helpers/Helper';
import { EmailCbDefs } from './EmailCbDefs';

export class EmailCbDefsGlobal extends EmailCbDefs {
  constructor(props) {
    super(props, { pageTitle: Helper.getString('emailglobalBlocks') })
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('blocks') },
    ]
  }
}