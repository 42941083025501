import React, { Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { UiHelper } from '../../common/helpers/UiHelper';
import { CustomCbDatsStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { BaseEntityPageSlixta } from '../../components/BaseEntityPageSlixta';
import { CustomCbs } from '../../components/cb/CustomCbs';
import { CbHelper } from '../../components/cb/CbHelper';
import { UploadHelper } from '../../common/helpers/UploadHelper';
import { AppUiHelper } from '../../helpers/AppUiHelper';

const queryString = require('query-string');
export class EmailCbDats extends BaseEntityPageSlixta {

  constructor(props) {
    super(props, { assetPicker: true });
    this.pageTitle = Helper.getString('cbdats')
    // console.log('this.props:', this.props)
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.pageId = queryString.parse(this.props.location.search).pageId
    this.cbScopeId = this.props.match.params.id
    const cbScopeIdSplits = this.cbScopeId.split('-')
    if (cbScopeIdSplits.length === 4) {
      this.cbId = cbScopeIdSplits[2] + '-' + cbScopeIdSplits[3]
    } else {
      throw new Error('Invalid input')
    }
    this.toFetch = [
      { method: 'GET', paths: ['emailcbdats'], queryParams: { cbId: this.cbScopeId, pb: 'fieldDef' } },
      { method: 'GET', paths: ['emailcbdefs', this.cbId], queryParams: {} },
    ]
    if (this.cbScopeId.startsWith('o')) {
      const groupId = 'emailcborg' + this.orgId
      const itemId = groupId + '-all'
      this.toFetch.push({ method: 'GET', paths: ['items', itemId], queryParams: { groupId, pb: 'fieldDef' } })
    } else if (this.pageId) {
      this.toFetch.push({ method: 'GET', paths: ['emailpages', this.pageId], queryParams: {} })
    }
    this.apiData = {
      cbId: this.cbScopeId
    }
  }

  onFetchSuccess(results) {
    console.log('##onFetchSuccess')
    this.cbdef = results[1]
    this.pageTitle = 'Data for "' + this.cbdef.title + '"'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
    ]
    if (this.cbScopeId.startsWith('o')) {
      this.pageTitle = 'Data for "' + results[2].items[this.cbScopeId.split('-')[1]].title + '"'
      this.breadCrumbs.push({ title: Helper.getString('blocks'), to: this.baseRoute() + '/cborgs/' })
      this.breadCrumbs.push({ title: 'Data' })
    } else if (this.pageId) {
      this.page = results[2]
      // TODO: Handle crash
      // const blockTitle = Helper.ellipsis(results[2].blocks[this.cbScopeId.split('-')[1]].title, 20)
      // this.pageTitle = 'Data for "'  + blockTitle + '"'
      this.breadCrumbs.push({ title: 'Email Pages', to: this.baseRoute() + '/emailpages' })
      this.breadCrumbs.push({ title: Helper.ellipsis(results[2].title, 20), to: this.baseRoute() + '/emailpages/' + this.pageId + '/blocks' })
      this.breadCrumbs.push({ title: 'Data' })
    } else {
      this.breadCrumbs.push({ title: this.pageTitle })
    }

    this.formDefinition = this.fieldDef = results[0].fieldDef
    this.fieldDef.steps[0].fields =
      this.fieldDef.steps[0].fields.filter(i => !Helper.inArray(['navLinkMode'], i.key))
    AppUiHelper.limitEmailImages(this.fieldDef)

    const fieldOne = this.formDefinition.steps[0].fields[0]
    this.tableHeadersMap = {
      Order: (current) => { return current.order },
      Image: undefined,
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{Helper.ellipsis(current[fieldOne.key])}</span>
            {current.navLink &&
              <Fragment>
                <br />
                <span className={css(Styles.spanValue, Styles.line2)}>{'Links to: ' + current.navLink}</span>
              </Fragment>
            }
          </div>
        )
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      }
    }
    if (this.formDefinition.steps[0].fields.find(i => i.subType === 'image' || i.imageUpload)) {
      this.tableHeadersMap.Image = (current) => { return UiHelper.itemImage(current) }
    } else {
      delete this.tableHeadersMap.Image
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    const items = Helper.sortDictArray(results[0].items)
    // if(this.isSingleton() && items.length > 0) {
    //   this.noAdd = true
    // }
    this.setState({
      items: items,
      customCbRender: this.isCustomRender(this.cbdef)
    })
    this.addNewLabel = 'Add Data'
  }

  // isSingleton() {
  //   return Helper.inArray(['IMAGE1', 'H1CENTER'], this.cbdef.cbstyle)
  // }

  isCustomRender(cbdef) {
    return false
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  openModalForAdd() {
    if (this.cbdef.cbstyle !== 'ITEMBLOCK1') {
      return super.openModalForAdd(this.fieldDef)
    }
    this.formDefinition = {
      steps: [
        {
          fields: [
            {
              key: 'contentUrl',
              type: 'edit_text',
              label: 'Content Url [Optional]',
              v_required: { value: false }, // eslint-disable-line camelcase
              placement: 'left'
            },
          ]
        }
      ],
      formType: 'add',
      ctaButtonText: 'Next',
    }
    this.customSubmit = null
    this.customSubmit = (options) => {
      this.customSubmit = null
      const fieldDef = JSON.parse(JSON.stringify(this.fieldDef))
      const { formData } = options
      const _this = this
      const _openModalForAdd = super.openModalForAdd.bind(this)
      function loadForm() {
        _this.setState({ fetchState: ApiHelper.State.READY, modalOpen: false }, () => {
          _openModalForAdd(fieldDef, false)
        })
      }
      if (formData.contentUrl) {
        ApiHelper.callAwait({
          ms: AppConfig.CDP_MS.ENDPOINT,
          method: 'get',
          paths: ['miscs', 'get-link-preview'],
          queryParams: {link: formData.contentUrl}}
        ).then(linkPreview => {
          const titleField = fieldDef.steps[0].fields.find(f => f.key === 'title')
          titleField.defaultValue = linkPreview.title || ''
          const descriptionField = fieldDef.steps[0].fields.find(f => f.key === 'description')
          descriptionField.defaultValue = linkPreview.description || ''
          const navLinkField = fieldDef.steps[0].fields.find(f => f.key === 'navLink')
          navLinkField.defaultValue = formData.contentUrl
          if (linkPreview.imageData) {
            const imageBlob = Helper.b64toBlob(linkPreview.imageData.base64, linkPreview.imageData.type);
            const imageField = fieldDef.steps[0].fields.find(f => f.key === 'image')
            const options = {
              uploadLocation: this.orgId,
              type: 'media',
              //contentType: linkPreview.imageData.contentType,
              ext:  linkPreview.imageData.extension,
            }
            return UploadHelper.fetchUploadUrlAwait(null, options).then(signedUploadInfo => {
              const file = new File([imageBlob], 'untitled', { type: linkPreview.imageData.contentType })
              return UploadHelper.uploadFormDataAwait({
                signedUploadInfo,
                pickerResult: file,
              }).then(() => {
                imageField.link = signedUploadInfo.link
                imageField.value = signedUploadInfo.fields.key
              })
            })
          }

        }).then(() => loadForm()).catch(err => {
          console.log(err)
          loadForm()
        })
      } else {
        loadForm()
      }
    }
    this.setState({
      modalOpen: true
    });
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['emailcbdats'], {}),
    });
  }

  onEdit(current) {
    this.customSubmit = null
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['emailcbdats', current.id], {}),
    });
  }

  onDelete(current) {
    this.customSubmit = null
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['emailcbdats', current.id], {}),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }

  setCurrentHotpot = (item) => {
    this.currentHotpot = item
  }

  customSubmitForCb(options, callback) {
    const { formType, formData, caller } = options
    const { jsonBody, method, endPoint } = CbHelper.getParamsByCb({
      cb: this.cbdef,
      formType,
      formData,
      formDefinition: this.formDefinition,
      currentHotpot: this.currentHotpot,
      items: this.state.items
    })
    if(!method) {
      caller.setState({
        success: true,
        message: Helper.getString('unknownFieldType'),
        inProcess: false
      })
      return
    }
    ApiHelper.call(
      { method, endPoint, jsonBody },
      function (err, data) {
        if (err) {
          const errorMessage = Helper.getErrorMsg(err);
          caller.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: errorMessage,
            success: false,
            message: errorMessage,
            inProcess: false
          })
          return;
        }
        caller.previousState = caller.state;
        if (Helper.inArray(['delete', 'del'], this.formDefinition.formType)) {
          caller.setState({
            success: true,
            message: '',
            inProcess: false
          })
          this.currentHotpot = null
        } else {
          caller.setState({
            success: true,
            message: Helper.getString('successful'),
            inProcess: false
          })
          this.currentHotpot = null
        }
        this.fetchItems()
        callback()
      }.bind(this));
  }

  renderTable() {
    if (this.state.customCbRender) {
      return (
        <CustomCbs
          cb={this.cbdef}
          modalOpen={this.state.modalOpen}
          items={this.state.items}
          formDefinition={this.formDefinition}
          openModalForAdd={this.openModalForAdd.bind(this)}
          setCurrentHotpot={this.setCurrentHotpot.bind(this)}
          openModalForEdit={this.openModalForEdit.bind(this)}
          openModalForDelete={this.openModalForDelete.bind(this)} />

      )
    } else {
      return super.renderTable()
    }
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDatsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})