import { StyleSheet } from 'aphrodite';
import { AppConfig } from '../../AppConfig';
import { AppTheme } from '../../styles/AppTheme';
import { CommonTheme } from '../../styles/Styles';

export class AppStyleHelper {
  static modifyStyleSheet = (st, options) => {
    if (!AppConfig.CUSTOM_STYLE) {
      if (options.type) {
        return st
      } else {
        return StyleSheet.create(st)
      }
    }
    if (options.comp === 'drawerMenuDashboard') {
      if (options.type === 'drawerStyle') {
        const bgImgPath = '../assets/images/ellipse-color.svg'
        try {
          const imgSrc = require(bgImgPath)
          st.drawerOpen.backgroundImage = `url(${imgSrc})`
          st.drawerOpen.backgroundRepeat = 'no-repeat'
          st.drawerClose.backgroundImage = `url(${imgSrc})`
          st.drawerClose.backgroundRepeat = 'no-repeat'
        } catch (err) {
          console.log('bg image not present')
        }
        return st
      } else {
        st.drawerImageContainer.justifyContent = 'flex-start'
        st.drawerImageContainer.padding = '0px 30px'
      }
    }
    return StyleSheet.create(st)
  }
}
