import Header from '@editorjs/header'
import List from '@editorjs/list'
import Paragraph from '@editorjs/paragraph'

import { aggregation, blockToHtml, extractBlog, getBlockIndex, getContentUptoIndex, getSectionHeader } from './AIUtils';

class AITool {
  constructor(params) {
    const {api, block, config, data} = params;
    this.api = api;
    this.block = block;
    this.config = config;
    this.blockdata = data;
    this.orgId = config.orgId;
    this.ms = config.ms;
    this.blogTitle = config.blogTitle;
    this.blogSummary = config.blogSummary;
  }

  onClick() {
    const blockIndex = getBlockIndex(this.api, this.block.id);
    const title = this.blogTitle;
    const summary = this.blogSummary;
    const { sectionHeader, sectionHeaderLevel } = getSectionHeader(this.api, blockIndex);
    const replacements = {title, summary};
    replacements.priorContent = getContentUptoIndex(this.api, blockIndex);
    replacements.priorText = blockToHtml(this.api, blockIndex);
    replacements.sectionHeader = sectionHeader || title;
    replacements.sectionHeaderLevel = sectionHeaderLevel;
    replacements.subsectionHeaderLevel = Math.min(sectionHeaderLevel + 1, 6);
    this.config.launchAiAssistant({
      orgId: this.orgId,
      ms: this.ms,
      blockIndex,
      replacements,
      onGenerateTextDone: this.onGenerateTextDone.bind(this),
    });
  }

  onGenerateTextDone(generatedText, blockIndex) {
    const { blocks } = extractBlog(generatedText.text);
    blocks.forEach((b, i) => {
      this.api.blocks.insert(b.type, b.data, {}, blockIndex + i + 1);
    });
  }

  renderSettings() {
    const editorJsBase = Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this)));
    const baseSettings = editorJsBase.renderSettings && editorJsBase.renderSettings.apply(this);
    const wrapper = document.createElement('div');
    const button = document.createElement('div');
    button.setAttribute('title', 'Slixta AI');
    button.setAttribute('style', 'width:100%;');
    button.classList.add('cdx-settings-button');
    button.innerHTML = '<svg style="display:block;margin:auto;" width="80px" height="20px" viewBox="4.727 0.787 163.005 43.497" xmlns="http://www.w3.org/2000/svg">\
      <text style="fill: rgb(51, 51, 51); font-family: Arial; font-size: 39px; font-weight: 700; white-space: pre;" x="128.73" y="36.165">AI</text>\
      <path d="M 15.671 37 C 13.598 37 11.752 36.645 10.134 35.936 C 8.515 35.225 7.238 34.253 6.302 33.019 C 5.365 31.783 4.84 30.398 4.727 28.864 L 11.923 28.864 C 12.009 29.687 12.393 30.355 13.073 30.867 C 13.755 31.378 14.593 31.633 15.587 31.633 C 16.495 31.633 17.196 31.455 17.693 31.1 C 18.191 30.744 18.44 30.283 18.44 29.717 C 18.44 29.035 18.084 28.531 17.373 28.204 C 16.664 27.877 15.515 27.516 13.925 27.119 C 12.222 26.72 10.802 26.3 9.666 25.86 C 8.531 25.42 7.551 24.724 6.726 23.773 C 5.904 22.823 5.492 21.538 5.492 19.92 C 5.492 18.557 5.869 17.315 6.621 16.193 C 7.373 15.071 8.48 14.183 9.941 13.53 C 11.405 12.876 13.144 12.55 15.159 12.55 C 18.142 12.55 20.492 13.289 22.21 14.767 C 23.927 16.243 24.914 18.202 25.171 20.645 L 18.44 20.645 C 18.326 19.82 17.964 19.167 17.355 18.686 C 16.745 18.202 15.943 17.96 14.949 17.96 C 14.097 17.96 13.444 18.123 12.989 18.45 C 12.534 18.777 12.307 19.224 12.307 19.792 C 12.307 20.473 12.669 20.983 13.392 21.324 C 14.118 21.665 15.246 22.006 16.778 22.347 C 18.539 22.802 19.973 23.249 21.081 23.69 C 22.189 24.13 23.162 24.84 23.999 25.82 C 24.836 26.799 25.269 28.112 25.298 29.757 C 25.298 31.15 24.907 32.394 24.126 33.487 C 23.345 34.578 22.222 35.437 20.759 36.064 C 19.298 36.688 17.602 37 15.671 37 Z M 37.054 5.14 L 37.054 36.659 L 29.771 36.659 L 29.771 5.14 L 37.054 5.14 Z" fill="#0246E5"/>\
      <path d="M 45.998 10.42 C 44.721 10.42 43.677 10.044 42.867 9.291 C 42.058 8.539 41.654 7.609 41.654 6.501 C 41.654 5.366 42.058 4.423 42.867 3.67 C 43.677 2.918 44.721 2.542 45.998 2.542 C 47.249 2.542 48.278 2.918 49.086 3.67 C 49.896 4.423 50.301 5.366 50.301 6.501 C 50.301 7.609 49.896 8.539 49.086 9.291 C 48.278 10.044 47.249 10.42 45.998 10.42 Z M 49.619 12.891 L 49.619 36.659 L 42.336 36.659 L 42.336 12.891 L 49.619 12.891 Z" fill="#00CA6F"/>\
      <path d="M 68.956 36.659 L 64.398 30.058 L 60.566 36.659 L 52.685 36.659 L 60.522 24.521 L 52.474 12.891 L 60.65 12.891 L 65.207 19.451 L 69.042 12.891 L 76.921 12.891 L 68.956 24.862 L 77.135 36.659 L 68.956 36.659 Z M 93.191 30.483 L 93.191 36.659 L 89.486 36.659 C 86.845 36.659 84.786 36.013 83.31 34.721 C 81.835 33.429 81.097 31.321 81.097 28.396 L 81.097 18.94 L 78.198 18.94 L 78.198 12.891 L 81.097 12.891 L 81.097 7.099 L 88.38 7.099 L 88.38 12.891 L 93.151 12.891 L 93.151 18.94 L 88.38 18.94 L 88.38 28.48 C 88.38 29.191 88.55 29.702 88.891 30.015 C 89.232 30.327 89.8 30.483 90.593 30.483 L 93.191 30.483 Z M 95.792 24.731 C 95.792 22.29 96.253 20.147 97.174 18.301 C 98.098 16.455 99.355 15.035 100.944 14.041 C 102.534 13.047 104.308 12.55 106.268 12.55 C 107.944 12.55 109.413 12.891 110.676 13.573 C 111.939 14.255 112.912 15.149 113.594 16.255 L 113.594 12.891 L 120.877 12.891 L 120.877 36.659 L 113.594 36.659 L 113.594 33.295 C 112.885 34.401 111.898 35.295 110.633 35.977 C 109.37 36.659 107.9 37 106.224 37 C 104.294 37 102.534 36.497 100.944 35.49 C 99.355 34.481 98.098 33.047 97.174 31.187 C 96.253 29.326 95.792 27.175 95.792 24.731 Z M 113.594 24.775 C 113.594 22.958 113.09 21.525 112.081 20.475 C 111.074 19.423 109.847 18.896 108.398 18.896 C 106.949 18.896 105.72 19.415 104.712 20.453 C 103.705 21.488 103.202 22.915 103.202 24.731 C 103.202 26.55 103.705 27.992 104.712 29.057 C 105.72 30.121 106.949 30.653 108.398 30.653 C 109.847 30.653 111.074 30.128 112.081 29.078 C 113.09 28.026 113.594 26.592 113.594 24.775 Z" fill="#0246E5"/>\
    </svg>';
    button.addEventListener('click', this.onClick.bind(this));
    wrapper.appendChild(button);

    if (baseSettings) {
      wrapper.appendChild(baseSettings);
    }

    return wrapper;
  }
}

export class AIHeader extends aggregation(Header, AITool) {}
export class AIList extends aggregation(List, AITool) {}
export class AIParagraph extends aggregation(Paragraph, AITool) {}
