/* eslint-disable camelcase */
import { StyleSheet, css } from 'aphrodite';
import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppStyles } from '../../../styles/AppTheme';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { UiHelper } from '../../../common/helpers/UiHelper';

export class ContentCluster extends BaseEntityPage {
  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CONTENT_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('content-cluster-titles')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.pillar = this.props.match.params.pillar
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('content-planner'), to: this.baseRoute() + '/content/planner' },
      { title: this.pillar },
    ]
    this.toFetch = [
      {
        method: 'GET',
        paths: ['contents', this.orgId],
        queryParams: { groupId: this.orgId, pb: 'fieldDefContentTopicsEntity', limit: 1 },
        ms: this.ms,
      },
    ]
    this.pageKey = 'content-cluster-titles'
    this.tableHeadersMap = {
      'Cluster Title': (current) => {
        return UiHelper.itemTitleId(current)
      },
      'Generated Content': (current) => {
        return <div>
          <span>
            Blogs:&nbsp;
            <Link to={this.baseRoute() + '/content/blogs/' + current.id} key={current.id}>
              {current.blogs.length}
            </Link>
          </span>
        </div>
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.entityAttrName = 'topics';
    this.headerActions = [{
      label: this.addNewLabel || 'Add title',
      onClick: () => this.openModalForAdd()
    }];

    this.personas = this.personas || [];
    this.topics = this.topics || [];
    this.clusters = this.clusters || [];
    this.personaTitle = '';

    this.state.pillar = this.pillar;
    this.state.personaTitle = this.personaTitle;
  }

  onFetchSuccess(results) {
    this.personas = Object.values(results[0].personas);
    this.topics = Object.values(results[0].topics || {});
    this.topics.sort((a, b) => {
      return (a.topic === b.topic) ? (a.title < b.title ? -1 : 1) : (a.topic < b.topic ? -1 : 1);
    });
    this.clusters = this.topics.filter(t => t.topic === this.pillar);

    if (this.clusters.length) {
      const personaId = this.clusters[0].persona;
      const persona = this.personas.find(p => p.id === personaId) || {};
      this.personaTitle = persona.title || '';
    }

    const fieldDefContentTopicsEntity = JSON.parse(JSON.stringify((results[0].fieldDefContentTopicsEntity)));
    fieldDefContentTopicsEntity.disableAlwaysFormFields = ['topic'];
    fieldDefContentTopicsEntity.steps[0].fields.find(i => i.key === 'persona').options = this.personas.map(p => ({ key: p.id, label: p.title }));
    this.fieldDef = this.formDefinition = fieldDefContentTopicsEntity;

    this.setState({
      items: this.clusters,
      pillar: this.pillar,
      personaTitle: this.personaTitle,
    })
  }

  onAdd() {
    this.formDefinition.steps[0].fields.find(i => i.key === 'topic').value = this.pillar;
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', this.orgId], queryParams: {} }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', this.orgId], queryParams: {} }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', this.orgId], queryParams: {} }),
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []

    const queryParams = {
      personaId: current.persona || '',
      topicPillar: current.topic,
      topicClusterTitle: current.title,
      topicId: current.id,
    };

    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(
      <Link to={this.baseRoute() + '/content/generator?' + Helper.encodeQueryString(queryParams)} key={current.id}>
        <Tooltip title='Generate Blog' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>auto_awesome</i>
          </span>
        </Tooltip>
      </Link>
    )
    actions.push(UiHelper.buttonDelete(this, current))

    return actions
  }

  renderAboveTable() {
    return <div className={css(Styles.aboveHeader)}>
      <div className={css(Styles.aboveHeaderItem)}>Topic Pillar:&nbsp;<strong>{this.state.pillar}</strong></div>
      { this.state.personaTitle && <div className={css(Styles.aboveHeaderItem)}>User Persona:&nbsp;<strong>{this.state.personaTitle}</strong></div> }
    </div>
  }
}

const Styles = StyleSheet.create({
  aboveHeader: {
    width: '100%',
    fontSize: 14,
    border: '2px solid #F3F4F6',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
    color: '#888888',
  },
  aboveHeaderItem: {
    marginBottom: '8px',
  },
});