

import { BaseEntityPage } from '../common/components/BaseEntityPage';
import { ImageUploaderWithAssetPicker } from './ImageUploaderWithAssetPicker';

export class BaseEntityPageSlixta extends BaseEntityPage {
  constructor(props, options) {
    super(props);
    this.orgId = this.props.match.params.orgId
    if(options && options.assetPicker && this.orgId) {
      this.customFormComponents = {
        imageUpload: {
          props: { orgId: this.orgId },
          comp: ImageUploaderWithAssetPicker
        }
      }
    }
  }
}