import React, { Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite';

import { Helper } from '../../common/helpers/Helper';
import { AppConfig } from '../../AppConfig';
import { CustomCbDefsStyles } from '../../styles/CustomStyles';
import { BaseEntityPageSlixta } from '../../components/BaseEntityPageSlixta';

export class Carts extends BaseEntityPageSlixta {

  constructor(props) {
    super(props);
    this.pageKey = 'carts'
    this.pageTitle = Helper.getString('carts')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['miscs', 'carts-org'], queryParams: { orgId: this.orgId } },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Customer: (current) => {
        // console.log(current.__index, current)
        // console.log('user:', current.__index, current.user)
        const user = current.user
        const name = Helper.formatName(user)
        return (
          <div>
            {name &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Name: {name}</span>
                <br />
              </Fragment>
            }
            {user.email &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Email: {user.email}</span>
                <br />
              </Fragment>
            }
            {user.phone &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Phone: {user.phone}</span>
                <br />
              </Fragment>
            }
            <span className={css(Styles.line2)}>ID: {user.id}</span>
            <br />
            <br />
            <span className={css(Styles.spanValue, Styles.line2)}>Cart Status: {current.activeMsg}</span>
            <br />
          </div>
        )
      },
      CartItems:(current) => {
        return(
          <div>
            {current._lineItems.map((item, index) => {
              return (
                <div>
                  <span className={css(Styles.spanValue)}>{item.slug}</span>
                  <br />
                  <span className={css(Styles.line2)}>- SKU: {item.variantSku} - Qty: {item.quantity} - Unit Price: {Helper.formatCurrency(item.price)}</span>
                  <br />
                </div>
              )
            })}
          </div>
        )
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.export = {
      headers: {
        __index: 'index',
        activeMsg: 'activity',
        userId: 'userId',
        name: 'name',
        email: 'email',
        phone: 'phone',
        sku: 'sku',
        totalQuantity: 'totalQuantity',
        totalPrice: 'totalPrice',
      },
      format: 'xlsx'
    }
  }

  onFetchSuccess(results, options) {
    const { fetchNext } = options || {}
    this.handleNextpage(results[0])
    const items = results[0].items.filter(i => i.user)
    items.forEach(i => {
      i.userId = i.user.id
      i.name = Helper.formatName(i.user)
      i.email = i.user.email
      i.phone = i.user.phone
      i.sku = ''
      i.totalPrice = 0
      i.totalQuantity = 0
      i._lineItems.forEach((item, index) => {
        if(i.sku) i.sku += ', '
        i.sku += item.variantSku
        if(item.price && item.quantity) {
          i.totalQuantity += item.quantity
          i.totalPrice += (item.price * item.quantity)
        }
      })
      i.totalPrice = i.totalPrice / 100
    })
    this.setState({
      items: fetchNext ? this.state.items.concat(items) : items
    })
  }

  makeFileName = () => {
    return 'carts';
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDefsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})