import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';

export class ContentPersonas extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CONTENT_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('content-personas')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('content-personas') },
    ]
    this.toFetch = [
      {
        method: 'GET',
        paths: ['contents', this.orgId],
        queryParams: { groupId: this.orgId, pb: 'fieldDefContentPersonasEntity', limit: 100 },
        ms: this.ms,
      },
    ]
    this.pageKey = 'content-personas'
    this.tableHeadersMap = {
      Persona: (current) => {
        return UiHelper.itemTitleId(current)
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.entityAttrName = 'personas';
  }

  onFetchSuccess(results) {
    this.personas = Object.values(results[0].personas);

    const fieldDefContentPersonasEntity = JSON.parse(JSON.stringify((results[0].fieldDefContentPersonasEntity)));
    this.fieldDef = this.formDefinition = fieldDefContentPersonasEntity;

    this.setState({
      items: this.personas,
    })
  }

  onAdd() {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', this.orgId], queryParams: {} }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', this.orgId], queryParams: {} }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['contents', this.orgId], queryParams: {} }),
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []

    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))

    return actions
  }
}
