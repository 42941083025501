import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Helper } from '../../../common/helpers/Helper';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { BaseEntityPageSlixta } from '../../../components/BaseEntityPageSlixta';
import { SearchBar } from '../../../common/components/SearchBar';
import { BarChartCard, PieChartCard } from '../../../common/components/charts/ChartCard';
import { StatCard } from '../../../common/components/StatCard';
import { Controller } from '../../../common/Controller';

function generateColor(fraction) {
  return `rgba(66, 117, 245, ${fraction.toFixed(2)})`
}

function getPieChartColor(key, index, numKeys) {
  switch(key) {
  case 'UNQUALIFIED': return 'darkgray'
  case 'LOST': return 'silver'
  case 'BAD TIMING': return 'lightgray'
  }
  return generateColor(Number(index + 1) / numKeys)
}

function statsToPieData(stats, key, label) {
  if (!stats || !(key in stats) || !stats[key].buckets) return {}
  const buckets = stats[key].buckets
  const specialBucketKeyOrder = ['BAD TIMING', 'LOST', 'UNQUALIFIED']
  const progressiveBucketKeyOrder = ['PROSPECT', 'NEW', 'OPEN', 'ATTEMPTED TO CONTACT', 'CONNECTED', 'IN PROGRESS', 'OPEN DEAL', 'CUSTOMER']
  const specialBucketKeys = specialBucketKeyOrder.reduce((p, c) => p.concat(buckets.filter(b => b.key === c)), [])
  const progressiveBucketKeys = progressiveBucketKeyOrder.reduce((p, c) => p.concat(buckets.filter(b => b.key === c)), [])
  const sortedBucketKeys = progressiveBucketKeys.concat(specialBucketKeys)
  const numProgressiveBucketKeys = Number(progressiveBucketKeys.length)
  return {
    labels: sortedBucketKeys.map(b => b.key),
    datasets: [
      {
        label,
        data: sortedBucketKeys.map(b => b.doc_count),
        backgroundColor: sortedBucketKeys.map((b, i) => getPieChartColor(b.key, i, numProgressiveBucketKeys)),
        borderWidth: 1,
      },
    ],
  }
}

function statsToBarData(stats, key, label) {
  if (!stats || !(key in stats) || !stats[key].buckets) return {}
  const topBuckets = stats[key].buckets.slice(0, 5);
  return {
    labels: topBuckets.map(b => b.key),
    datasets: [
      {
        label,
        data: topBuckets.map(b => b.doc_count),
        backgroundColor: topBuckets.map((b, i) => generateColor(Number(i + 1) / Number(topBuckets.length))),
        borderWidth: 1,
      },
    ],
  }
}

function updateWindowUrl(searchText = '', searchFilters = {}) {
  const queryParams = {}
  if (searchText) queryParams.query = searchText
  Object.keys(searchFilters).forEach(f => {
    queryParams[f] = searchFilters[f]
  })
  const _url = new URL(document.location.href)
  const urlSearchParamsKeys = [..._url.searchParams.keys()]
  for (const urlSearchParamsKey of urlSearchParamsKeys) {
    _url.searchParams.delete(urlSearchParamsKey)
  }
  Object.keys(queryParams).forEach(p => {
    if (Array.isArray(queryParams[p]) ? queryParams[p].length > 0 : queryParams[p]) {
      _url.searchParams.append(p, queryParams[p])
    }
  })
  window.history.replaceState(null, '', _url.toString());
}

function getImportFieldDef(staffUsers, currentStaffUser, orgId) {
  const fieldDef = {
    title: 'Import',
    ctaButtonText: 'Import',
    steps: [
      {
        fields: [
          {
            key: 'importCsv',
            linkName: 'importCsvLink',
            type: 'document',
            subType: 'document',
            label: 'Import CSV',
            tip: 'Select a csv file to bulk import up to 100 contacts; Supported fields are \'firstname\', \'lastname\', \'email\', \'phone\', \'company\', \'jobtitle\', \'linkedinurl\'; All contacts will be imported as PROSPECT',
            v_required: { value: true, err: 'Provide csv file' },
            placement: 'left',
            documentUpload: true,
            fileSizeLimit: 1048576,
            uploadAccept: 'text/csv',
          },
          {
            key: 'assignee',
            type: 'radio',
            label: 'Assignee',
            hint: 'Assignee',
            options: [], // FE
            v_required: {
              value: false,
              err: 'Provide Assignee'
            },
            placement: 'right'
          },
        ]
      }
    ]
  }
  UiHelper.populateOptions(fieldDef, {items: staffUsers}, 'assignee')
  if (currentStaffUser && currentStaffUser.id) {
    const assigneeField = fieldDef.steps[0].fields.find(f => f.key === 'assignee')
    assigneeField.defaultValue = `${orgId}-${currentStaffUser.id}`
  }
  return fieldDef
}

function getProspectFieldDef() {
  return {
    title: 'Prospect',
    ctaButtonText: 'Next',
    steps: [
      {
        fields: [
          {
            key: 'prospectSource',
            type: 'radio',
            label: 'Source',
            tip: 'Select a prospect source',
            v_required: { value: true, err: 'Provide prospect source' },
            placement: 'left',
            options: [
              {key: 'linkedin', label: 'LINKEDIN'},
              {key: 'website', label: 'WEBSITE'},
              {key: 'email', label: 'EMAIL'},
              {key: 'csv', label: 'CSV'},
            ],
            defaultValue: 'linkedin',
          },
          {
            condition: { key: 'prospectSource', value: 'linkedin', values: ['linkedin'] },
            key: 'linkedinProfileUrl',
            type: 'edit_text',
            label: 'LinkedIn Profile Url',
            tip: 'Paste the link to the LinkedIn profile of the person you want to prospect',
            v_required: { value: true, err: 'Provide LinkedIn profile url' },
            placement: 'right'
          },
          {
            condition: { key: 'prospectSource', value: 'website', values: ['website'] },
            key: 'companyWebsiteUrl',
            type: 'edit_text',
            label: 'Company Website Url',
            tip: 'Paste the link to the company website of the person you want to prospect',
            v_required: { value: true, err: 'Provide company website url' },
            placement: 'right'
          },
          {
            condition: { key: 'prospectSource', value: 'website', values: ['website'] },
            key: 'givenname',
            type: 'edit_text',
            label: 'First Name',
            tip: 'Enter the first name of the person you want to prospect',
            v_required: { value: true, err: 'Provide first name' },
            placement: 'left'
          },
          {
            condition: { key: 'prospectSource', value: 'website', values: ['website'] },
            key: 'familyname',
            type: 'edit_text',
            label: 'Last Name',
            tip: 'Enter the last name of the person you want to prospect',
            v_required: { value: true, err: 'Provide last name' },
            placement: 'right'
          },
          {
            condition: { key: 'prospectSource', value: 'email', values: ['email'] },
            key: 'email',
            type: 'edit_text',
            label: 'Email',
            tip: 'Enter the email of the person you want to prospect',
            v_required: { value: true, err: 'Provide email' },
            v_email: { value: true, err: 'Provide a valid email' },
            placement: 'right'
          },
          {
            condition: { key: 'prospectSource', value: 'csv', values: ['csv'] },
            key: 'importCsv',
            linkName: 'importCsvLink',
            type: 'document',
            subType: 'document',
            label: 'Import CSV',
            tip: 'Select a csv file to bulk prospect up to 100 leads; Supported fields are \'email\', \'linkedinurl\', \'firstname\', \'lastname\', \'companyurl\'; Please re-import the generated CSV file after review',
            v_required: { value: true, err: 'Provide csv file' },
            placement: 'left',
            documentUpload: true,
            fileSizeLimit: 1048576,
            uploadAccept: 'text/csv',
          },
        ]
      }
    ]
  }
}

export class CrmContacts2 extends BaseEntityPageSlixta {

  constructor(props) {
    super(props);
    // this.noAdd = true
    this.emptyText = 'No results. Check your search query or search filters.'
    this.pageKey = 'crm-contacts'
    this.pageTitle = Helper.getString('crm-contacts')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.staffUserId = Controller.get().getUserId()
    this.apiData = {
      orgId: this.orgId,
    }
    this.staffUsers = []
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.attribsGroupId = 'settingscrmcontactattrs' + this.orgId
    this.toFetch = [
      { key: 'crmcontacts', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crmcontacts'], queryParams: { orgId: this.orgId, pb: 'fieldDef', limit: 100 } },
      { key: 'crmcontactattrs', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['items', `${this.attribsGroupId}-all`], queryParams: { groupId: this.attribsGroupId } },
      { key: 'usersORG', method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], { orgId: this.orgId }) },
      { key: 'usersME', method: 'GET', endPoint: ApiHelper.makeUrlPath(['users', 'me'], {}) },
      { key: 'crmsequences', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['campaigns'], queryParams: { orgId: this.orgId, iTypeState: 'DRIP-LIVE' } },
      { key: 'crminboxes', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crminboxs'], queryParams: { orgId: this.orgId, orgIdUserId: `${this.orgId}-${this.staffUserId}`, includeShared: 'true' } },
      { key: 'crmemailtemplates', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crmemailtemplates'], queryParams: { orgId: this.orgId, pb: 'fieldDef', includeShared: 'true', limit: 100 } },
      { key: 'crmusersettings', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['items', `settingscrmusersettings${this.orgId}-${this.staffUserId}-all`], queryParams: { groupId: `settingscrmusersettings${this.orgId}-${this.staffUserId}` } },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Joined: (current) => { return Helper.formatTimestamp(current.createdTs) },
      Info: (current) => {
        const name = Helper.formatName(current)
        const assignee = this.staffUsers.find(u => u.id === current.assignee)
        return (
          <div>
            {name &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Name: {name}</span>
                <br />
              </Fragment>
            }
            {current.email &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Email: {current.email}</span>{current.emailProvider && <span className={css(Styles.line2)}> [{current.emailProvider}]</span>}
                <br />
              </Fragment>
            }
            {current.phone &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Phone: {current.phone}</span>
                <br />
              </Fragment>
            }
            {current.assignee &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Assignee: {Helper.formatName(assignee)}</span>
                <br />
              </Fragment>
            }
            {current.state &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Status: {current.state}</span>
                <br />
              </Fragment>
            }
            {current.tags && current.tags.length > 0 &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Tags: {current.tags.join(', ')}</span>
                <br />
              </Fragment>
            }
            {current.subscriptions && current.subscriptions.length > 0 &&
              <Fragment>
                <span className={css(Styles.spanValue)}>Subscriptions: {current.subscriptions.join(', ')}</span>
                <br />
              </Fragment>
            }
            {current.displayLabels &&
              <span className={css(Styles.itemValue, Styles.line3)}>{current.displayLabels.join(', ')}</span>
            }
          </div>
        )
      },
      // Profile: (current) => { return Helper.formatName(current) }
    }
    this.noTableHead = true

    this.headerActions = [
      {
        label: 'Prospect',
        icon: 'search',
        onClick: () => this.openModalForProspect(),
      },
      {
        label: 'Add New',
        icon: 'add',
        onClick: () => this.openModalForAdd(),
      },
      {
        label: 'Import',
        icon: 'group_add',
        onClick: () => this.openModalForAdd(getImportFieldDef(this.staffUsers, this.currentStaffUser, this.orgId)),
      },
      {
        label: 'Refresh',
        icon: 'refresh',
        onClick: () => this.fetchItems(),
      }
    ]

    const _url = new URL(document.location.href)
    this.state.searchFilters = {}
    _url.searchParams.forEach((v, k) => {
      if (k === 'query') this.state.searchText = v
      else this.state.searchFilters[k] = v.split(',').map(e => e.trim())
    })
    this.uploadMs = AppConfig.CDP_MS.ENDPOINT
    this.displayCheckBoxTable = true
    this.checkboxTableItemNameSingular = 'contact'
    this.checkboxTableItemNamePlural = 'contacts'
  }

  onFetchSuccess(results, options) {
    const { fetchNext } = options || {}
    if (!fetchNext) {
      this.crmContactsResult = this.getResult(results, 'crmcontacts')
      this.fieldDef = this.formDefinition = this.crmContactsResult.fieldDef
      this.crmContactAttrs = this.getResult(results, 'crmcontactattrs')
      this.staffUsers = this.getResult(results, 'usersORG').items
      this.currentStaffUser = this.getResult(results, 'usersME')
      this.crmSequences = this.getResult(results, 'crmsequences').items.sort((a, b) => b.createdTs - a.createdTs)
      this.crmInboxes = this.getResult(results, 'crminboxes').items
      this.crmEmailTemplates = this.getResult(results, 'crmemailtemplates').items
      this.crmEmailTemplateFieldDef = this.getResult(results, 'crmemailtemplates').fieldDef
      this.crmUserSettings = this.getResult(results, 'crmusersettings').items
      Helper.loadAttrOptions(this.fieldDef, this.crmContactAttrs, 'tags')
      UiHelper.populateOptions(this.fieldDef, {items: this.staffUsers}, 'assignee')
      if (this.currentStaffUser && this.currentStaffUser.id) {
        const assigneeField = this.fieldDef.steps[0].fields.find(f => f.key === 'assignee')
        assigneeField.defaultValue = `${this.orgId}-${this.currentStaffUser.id}`
      }
      this.apiData = {
        orgId: this.orgId,
        creatorId: `${this.orgId}-${this.currentStaffUser.id}`,
      }
    } else {
      this.crmContactsResult = results[0]
    }
    this.transform(this.crmContactsResult);
    this.handleNextpage(this.crmContactsResult)
    this.setState({
      items: fetchNext ? this.state.items.concat(this.crmContactsResult.items) : this.crmContactsResult.items,
      stats: this.crmContactsResult.stats,
    })
    if (this.state.searchText || Object.keys(this.state.searchFilters).length > 0) {
      return this.onSearch(this.state.searchText, this.state.searchFilters)
    }
  }

  transform(result) {
    result.items.forEach(i => {
      const displayLabels = [];
      if (i.cognitoVerified) displayLabels.push('OTP VERIFIED');
      if (i.createdOnFormSubmission) displayLabels.push('CREATED BY FORM');
      if (i.createdByImport) displayLabels.push('CREATED BY IMPORT');
      if (displayLabels.length <= 0 && (i.emailAdminVerified || i.phoneAdminVerified)) {
        displayLabels.push('CREATED ON CONSOLE');
      }
      if (i.source) displayLabels.push(`SOURCE: ${i.source}`)
      if (displayLabels.length > 0) {
        i.displayLabels = displayLabels;
      }
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  renderAboveTable() {
    const filterOptions = []
    const leadStatusField = Helper.getField(this.fieldDef, 'state')
    if (leadStatusField) leadStatusField.multiSelect = true
    filterOptions.push(leadStatusField)
    filterOptions.push(Helper.getField(this.fieldDef, 'tags'))
    const subscriptionsField = JSON.parse(JSON.stringify(Helper.getField(this.fieldDef, 'subscriptions')))
    subscriptionsField.options.unshift({key: 'none', text: 'NONE'})
    filterOptions.push(subscriptionsField)
    const assigneeField = Helper.getField(this.fieldDef, 'assignee')
    if (assigneeField) assigneeField.multiSelect = true
    filterOptions.push(assigneeField)
    const sequencesFieldDef = UiHelper.getSequencesFieldDef(this.crmSequences, this.crmInboxes)
    const sequencesField = Helper.getField(sequencesFieldDef, 'campaignId')
    sequencesField.key = 'campaigns!'
    sequencesField.label = 'Not in sequence'
    sequencesField.options.unshift({key: 'any', text: 'ANY'})
    filterOptions.push(sequencesField)

    const showStats = this.state.stats && this.state.stats.states && this.state.stats.tags
    const numContacts = this.state.stats && this.state.stats.states ? this.state.stats.states.buckets.reduce((p, c) => p + c.doc_count, 0) : 0
    const numPropsects = this.state.stats && this.state.stats.states ? (this.state.stats.states.buckets.find(b => b.key === 'PROSPECT') || {}).doc_count || 0 : 0
    const numSubscriptions = this.state.stats && this.state.stats.subscriptions ? this.state.stats.subscriptions.buckets.reduce((p, c) => p + c.doc_count, 0) : 0
    const numLeads = numContacts - numPropsects

    return <>
      <div className={css(Styles.aboveTableContainer)}>
        {showStats &&
        <div className={css(Styles.statContainer)}>
          <div className={css(Styles.numericStatItem)}>
            <Grid container className={css(Styles.numericStatContainer)}>
              <StatCard
                xs={6}
                sm={12}
                key={'contacts'}
                text={numLeads}
                subText={'Contacts'}
                cardIndex={1}
                padding='small'
              />
              {numPropsects > 0 && <StatCard
                xs={6}
                sm={12}
                key={'prospects'}
                text={numPropsects}
                subText={'Prospects'}
                cardIndex={2}
                padding='small'
              />}
              <StatCard
                xs={6}
                sm={12}
                key={'subscriptions'}
                text={numSubscriptions}
                subText={'Subscriptions'}
                cardIndex={3}
                padding='small'
              />
            </Grid>
          </div>
          <div className={css(Styles.pieStatItem)}>
            <PieChartCard item={{data: statsToPieData(this.state.stats, 'states', 'Status'), title: 'Status'}}/>
          </div>
          <div className={css(Styles.barStatItem)}>
            <BarChartCard item={{data: statsToBarData(this.state.stats, 'tags', 'Tags'), title: 'Tags'}}/>
          </div>
        </div>
        }
        <SearchBar
          hint={'Type Name, Email or Phone'}
          onSearch={this.onSearch.bind(this)}
          onList={this.onList.bind(this)}
          searchText={this.state.searchText}
          searchFilters={this.state.searchFilters}
          filterOptions={filterOptions}
          searchButtonLabel='Filter'
          listButtonLabel='Reset Filters'
          autoRefresh={false}>
        </SearchBar>
      </div>
    </>
  }

  onSearch = (searchText = '', searchFilters = {}) => {
    Object.keys(searchFilters).forEach(k => {
      if (Array.isArray(searchFilters[k]) && searchFilters[k].length === 0) {
        delete searchFilters[k]
      } else if (!Array.isArray(searchFilters[k]) && searchFilters[k] === '') {
        delete searchFilters[k]
      }
    })
    updateWindowUrl(searchText, searchFilters)
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      searchText: searchText,
      searchFilters: searchFilters,
    })
    const searchFiltersQuery = {}
    Object.keys(searchFilters).forEach(key => {
      let val = searchFilters[key]
      const isAny = val === 'any' || (Array.isArray(val) && val.length === 1 && val[0] === 'any')
      const isNone = val === 'none' || (Array.isArray(val) && val.length === 1 && val[0] === 'none')
      if (isAny || isNone) {
        if (isAny) {
          if (key.endsWith('!')) {
            key = key.slice(0, -1)
          } else {
            key = key + '!'
          }
        }
        val = ''
      }
      searchFiltersQuery[key] = Array.isArray(val) ? val.join(',') : val
    })
    const queryParams = { orgId: this.orgId, query: searchText, ...searchFiltersQuery }
    return ApiHelper.callAwait({
      ms: AppConfig.CDP_MS.ENDPOINT, method: 'get', paths: ['crmcontacts'],
      queryParams
    }).then(result => {
      this.transform(result)
      this.setState({
        items: (result && result.items) || [],
        stats: result.stats,
        fetchState: ApiHelper.State.READY,
        nextPage: ''
      })
    }).catch(err => {
      console.log('Search Error:', err)
    })
  }

  onList = () => {
    updateWindowUrl()
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      searchText: '',
      searchFilters: {},
    }, () => this.fetchItems())
  }

  openModalForProspect() {
    this.apiData = {
      orgId: this.orgId,
      creatorId: `${this.orgId}-${this.currentStaffUser.id}`,
      isProspect: true,
    }
    this.formDefinition = getProspectFieldDef()
    this.formDefinition.formType = 'add'
    this.setState({
      modalOpen: true
    });
  }

  openModalForAdd(fieldDef, apiData = {}) {
    this.apiData = {
      orgId: this.orgId,
      creatorId: `${this.orgId}-${this.currentStaffUser.id}`,
      ...apiData,
    }
    super.openModalForAdd(fieldDef || this.fieldDef)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({
        ms: AppConfig.CDP_MS.ENDPOINT,
        paths: ['miscs', 'create-crm-contact'],
        queryParams: { orgId: this.orgId }
      }),
    });
  }

  openModalForEdit(current) {
    this.apiData = {
      orgId: this.orgId,
    }
    super.openModalForEdit(current)
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({
        ms: AppConfig.CDP_MS.ENDPOINT,
        paths: ['miscs', 'update-crm-contact'],
        queryParams: { id: current.id, orgId: this.orgId }
      }),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(
      <Link to={this.baseRoute() + '/crm-contacts/' + current.userId} key={current.userId}>
        <Tooltip title='Drill down' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>subdirectory_arrow_right</i>
          </span>
        </Tooltip>
      </Link>
    )
    if (current.email) {
      actions.push(
        <Tooltip title={'Sequences'} placement='top-start' key={current.id + 'enrol-in-sequence'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => UiHelper.openModalForSequences.call(this, current, this.crmSequences, this.crmInboxes)}>dynamic_feed</i>
          </span>
        </Tooltip>
      )
    }
    return actions
  }

  checkBoxActions = (checkedItems) => {
    const headerActions = []
    headerActions.push(
      <Tooltip title={'Enroll'} placement='top-start' key={'enroll'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => UiHelper.openModalForSequences.call(this, checkedItems, this.crmSequences, this.crmInboxes)}>dynamic_feed</i>
        </span>
      </Tooltip>
    )
    return headerActions
  }

  async customSubmitForProspecting(options) {
    const { formType, formData, caller } = options

    delete formData.isProspect

    ApiHelper.callAwait({
      method: 'POST',
      endPoint: ApiHelper.makeUrlPath2({
        ms: AppConfig.CDP_MS.ENDPOINT,
        paths: ['miscs', 'create-prospect'],
        queryParams: {},
      }),
      jsonBody: formData,
    }).then(response => {
      if (formData.prospectSource === 'csv') {
        if (response.csvUrl) {
          window.open(response.csvUrl, '_blank')
          caller.setState({
            fetchState: ApiHelper.State.READY,
          })
          this.setState({
            modalOpen: false,
          })
          return
        } else {
          throw new Error('Csv not found')
        }
      }
      const fieldDef = JSON.parse(JSON.stringify(this.fieldDef))
      Object.keys(response.prospect).forEach(key => {
        const field = fieldDef.steps[0].fields.find(f => f.key === key)
        if (field) {
          field.defaultValue = response.prospect[key]
        }
      })
      const sourceKey = fieldDef.steps[0].fields.find(f => f.key === 'source')
      sourceKey.defaultValue = 'PROSPECTING'
      const stateKey = fieldDef.steps[0].fields.find(f => f.key === 'state')
      stateKey.defaultValue = 'PROSPECT'
      this.setState({ fetchState: ApiHelper.State.READY, modalOpen: false }, () => {
        this.openModalForAdd(fieldDef, { createdBy: 'PROSPECT', prospectSource: formData.prospectSource })
      })
    }).catch(err => {
      const errorMessage = Helper.getErrorMsg(err);
      caller.setState({
        fetchState: ApiHelper.State.ERROR,
        errMsg: errorMessage,
        success: false,
        message: errorMessage,
        inProcess: false
      })
    })
  }

  async customSubmit(options) {
    const { formType, formData, caller } = options
    if ('seqEnrolmentNextStepIndex' in formData) { return UiHelper.customSubmitForSequences.call(this, options) }
    if (formData.isProspect) { return this.customSubmitForProspecting(options) }

    return caller.submit.call(caller, formData, true);
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 14
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
  line3: {
    fontSize: 12,
    // color: 'charcoal'
  },
  aboveTableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  statContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 767px)': {
      justifyContent: 'center',
    },
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  numericStatItem: {
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
      height: '100%',
    },
    width: '20%',
  },
  pieStatItem: {
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
    width: '42%',
  },
  barStatItem: {
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
    width: '35%',
  },
  numericStatContainer: {
    height: '100%',
    maxHeight: '100%',
    maxWidth: AppTheme.viewPort,
    textAlign: 'center',
    display: 'flex',
    border: '1px solid ' + AppTheme.borderColor,
    padding: 16,
    margin: 0,
    borderRadius: AppTheme.borderRadius,
    //justifyContent: 'left'
  },
  barChartContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
})