import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { createReactEditorJS } from 'react-editor-js'
import { Helper } from '../../common/helpers/Helper';
import { UiHelper } from '../../common/helpers/UiHelper';
import { ApiHelper } from '../../common/helpers/ApiHelper';
import { AppTheme } from '../../styles/AppTheme';
import { CustomRichContentEditorStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { RichContentEditor } from '../../common/components/RichContentEditor/RichContentEditor';
import { EDITOR_JS_TOOLS } from '../../common/components/RichContentEditor/Tools';
import SlixtaWidget from './SlixtaWidget';
import { AIAssistantDialog } from '../../common/components/RichContentEditor/AIAssistantDialog';

const ReactEditorJS = createReactEditorJS()

function countWords(str) {
  return str.split(/\s/).filter(s => s !== '').length;
}

function countBlogWords(blocks) {
  return blocks.reduce((p, block) => {
    if (block.data) {
      if(block.data.text) {
        return p + countWords(block.data.text);
      } else if (block.data.items) {
        return p + block.data.items.reduce((a, b) => {
          return a + countWords(b);
        }, 0);
      }
    }
    return p;
  }, 0);
}


export class CustomRichContentEditor extends RichContentEditor {

  constructor(props) {
    super(props)
    this.pageTitle = 'Blog content'
    this.pageKey = 'richContentEditor'
    this.orgId = this.props.match.params.orgId
    this.prodId = this.props.match.params.id
    this.EDITOR_JS_TOOLS = {}
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: UiHelper.baseRoute(this.orgId) },
      { title: Helper.getString('blogs'), to: UiHelper.baseRoute(this.orgId) + '/blogs' },
      { title: this.pageTitle },
    ]
    this.editorJS = {}
    this.state = {
      isOpen: false,
      isAiAssistantOpen: false,
      aiAssistantContext: {},
    }
    this.toFetch = [
      {
        key: 'item',
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['blogs', this.prodId], {
          orgId: this.orgId
        })
      },
      { method: 'GET', paths: ['items', 'cborg' + this.orgId + '-all'], queryParams: { groupId: 'cborg' + this.orgId } },
    ]
  }

  onFetchSuccess(results) {
    const item = this.getResult(results, 'item')
    this.conf = {
      previewPath: '/blogs/' + item.slug
    }
    this.pageTitle = item.title
    for (const key in EDITOR_JS_TOOLS) {
      this.EDITOR_JS_TOOLS[key] = EDITOR_JS_TOOLS[key]
      if (['header', 'paragraph', 'list'].includes(key)) {
        this.EDITOR_JS_TOOLS[key].config.launchAiAssistant = (context) => {
          this.setState(prevState => {
            return {
              ...prevState, isAiAssistantOpen: true, aiAssistantContext: context
            }
          })
        };
        this.EDITOR_JS_TOOLS[key].config.orgId = this.orgId;
        this.EDITOR_JS_TOOLS[key].config.ms = AppConfig.CONTENT_MS.ENDPOINT;
        this.EDITOR_JS_TOOLS[key].config.blogTitle = item.title;
        this.EDITOR_JS_TOOLS[key].config.blogSummary = item.descr;
      }
    }
    const cbs = Helper.dictToArray(results[1].items)
    this.EDITOR_JS_TOOLS.slixtaWidget = {
      class: SlixtaWidget,
      config: {
        cbs: cbs,
        ...this.conf,
        orgId: this.orgId
      }
    }

    const modifiedBlocks = []
    if (item.contentEjs && item.contentEjs.blocks) {
      for (const b of item.contentEjs.blocks) {
        if (b.type === 'slixtaWidget' && (!b.data || !b.data.cbId || !results[1].items[b.data.cbId])) {
          continue
        }
        modifiedBlocks.push(b)
      }
      item.contentEjs.blocks = modifiedBlocks
    }
    this.setState({
      item: item,
    })
  }

  async handleSave() {
    super.handleSave().then(() => this.fetchItems());
  }

  renderAboveTable() {
    const blogWordCount = this.state.item && this.state.item.contentEjs && this.state.item.contentEjs.blocks ? countBlogWords(this.state.item.contentEjs.blocks) : 0;
    return (
      <Fragment>
        {this.renderHeaderbar()}
        <div className={css(Styles.outerContainer)}>
          {this.state.item &&
            <div className={css(Styles.imgContainer)}>
              <img className={css(Styles.img)} src={this.state.item.image}></img>
              <h1 className={css(Styles.blogTitle)}>{this.state.item.title}</h1>
              <h2 className={css(Styles.blogSummary)}>{this.state.item.descr}</h2>
              <br/>
              <div className={css(Styles.blogStats)}>
                <span>Word count: <strong>{blogWordCount}</strong></span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>Last modified: <strong>{Helper.formatTimestamp(this.state.item.modifiedTs)}</strong></span>
              </div>
            </div>
          }
          {this.state.item &&
            <div className={css(Styles.container)}>
              <AIAssistantDialog
                title='AI Assistant: Select a task '
                isOpen={this.state.isAiAssistantOpen}
                onClosed={() => this.setState(prevState => ({...prevState, isAiAssistantOpen: false}))}
                context={this.state.aiAssistantContext}
              />
              <ReactEditorJS
                holder='editorjs'
                onReady={this.handleReady}
                onInitialize={this.handleInitialize}
                defaultValue={this.state.item.contentEjs}
                tools={this.EDITOR_JS_TOOLS} >
                <div id='editorjs'>
                </div>
              </ReactEditorJS>
            </div>
          }
        </div>
        {this.renderContent()}
      </Fragment >
    )
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomRichContentEditorStyles : StyleSheet.create({
  outerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    position: 'relative',
    display: 'flex',
    width: '80%',
    marginBottom: 100,
    minHeight: 500,
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  modalInnerContainer: {
    marginTop: 120,
    marginBottom: 20,
    padding: 10,
    width: '60%'
  },
  modalHeader: {
  },
  modalCloseIcon: {
    width: 28,
    height: 28
  },
  modalCloseButton: {
    position: 'absolute',
    top: 50,
    right: 50,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalOuterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imgContainer: {
    width: '80%',
    backgroundColor: 'white',
    borderBottom: '1px solid #F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 40
  },
  img: {
    width: '70%'
  },
  blogTitle: {
    fontSize: 20,
    width: '70%',
    textAlign: 'left',
    paddingTop: 10
  },
  blogSummary: {
    width: '70%',
    color: '#3E4462',
    opacity: 0.7,
    margin: 0,
    marginBottom: 10,
    fontSize: 16,
  },
  blogStats: {
    width: '70%',
    color: '#3E4462',
    opacity: 0.7,
    margin: 0,
    marginBottom: 10,
    fontSize: 12,
  },
  button: {
    color: AppTheme.buttonTextColor,
    boxShadow: 'none',
    marginLeft: 10
  },
  buttonLabel: {
    marginLeft: 4
  },
  progressIcon: {
    marginLeft: 10,
    color: 'white'
  },
  aiControls: {
    width: '70%',
    color: '#3E4462',
    opacity: 0.7,
    margin: 0,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14,
  },
})