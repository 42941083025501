
import { Helper } from '../../common/helpers/Helper';
import { CbDefs } from './CbDefs';

export class CbDefsGlobal extends CbDefs {
  constructor(props) {
    super(props, { pageTitle: Helper.getString('globalBlocks') })
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('blocks') },
    ]
  }
}