import { StyleSheet } from 'aphrodite';
import { AppConfig } from '../AppConfig';

const PRIMARY = AppConfig.TINT_COLOUR

export const AppTheme = {
  primaryColor: PRIMARY,
  secondaryColor: '#f5f5f5', // Better to give a color in contrast to primaryColor
  borderColor: '#E5E5E5',
  primaryBg: '#f5f5f5', // Better dont change
  secondaryBg: '#ffffff', // Better dont change - This comes over primaryBg
  buttonBg: PRIMARY, // Usually Primary colour
  buttonTextColor: '#ffffff',
  pagePadding: 5,
  viewPort: 1200,
  headerbarHeight:140 ,
  toolbarHeight: 140,
  drawerWidth: AppConfig.DRAWER_STYLE === 'TYPE1' ? 135 : 250,
  backgroundColor: '#F2F2F2',
  adminPagePadding: 60,
  borderRadius: 16
}


export const AppStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: AppTheme.primaryBg,
  },
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    // alignContent: 'center',
    // alignItems: 'center',
    backgroundColor: AppTheme.primaryBg,
    maxWidth: 1200,
    '@media (max-width: 767px)': {
      margin: '0px 12px'
    },
    '@media(min-width: 768px) and (max-width: 1023px)': {
      margin: '0px 12px'
    },
  },
  containerContentCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    maxWidth: 1200,
    '@media (max-width: 767px)': {
      margin: '0px 12px'
    },
    '@media(min-width: 768px) and (max-width: 1023px)': {
      margin: '0px 12px'
    },
  },
  entityContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 100,
    width: '100%',
  },
  entityTableLinkIcon: {
    padding: 3,
    fontSize: 28,
    color: AppTheme.primaryColor,
    cursor: 'pointer'
  },
  pageTitle: {
    fontSize: 32,
    paddingBottom: 24
  },
  pageSubTitle: {
    fontSize: 16,
    paddingBottom: 5
  }
});
