import React from 'react';

import { ApiHelper } from '../../helpers/ApiHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { Controller } from '../../Controller';

import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';
import { CustomBlogsStyles } from '../../../styles/CustomStyles';

export class Blogs extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('blogs')
    this.pageKey = 'blogs'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['blogs'], queryParams: { orgId: this.orgId, pb: 'fieldDef', limit: 20 } },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return <span className={css(Styles.spanValue)}>{current.__index + 1}</span> },
      Info: (current) => {
        return UiHelper.itemDetails(current)
      },
      Created: (current) => {
        return (
          <div>
            {/* <span className={css(Styles.spanValue)}>{current.title}</span>
            <br /> */}
            <span className={css(Styles.spanValue)}>{current.createdTs && Helper.formatTimestamp(current.createdTs)}</span>
          </div>
        )
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
    this.headerActions = [
      {
        label: 'Refresh',
        icon: 'refresh',
        onClick: () => this.fetchItems()
      },
      {
        label: 'New Blog',
        onClick: () => this.openModalForAdd()
      },
    ]
  }

  onFetchSuccess(results, options) {
    const { fetchNext } = options || {}
    const prodData = results[0]
    this.fieldDef = prodData.fieldDef
    this.formDefinition = prodData.fieldDef
    // UiHelper.populateOptions(this.formDefinition, results[1], 'brandId')

    // const leafCats = Helper.processCats(results[2].items)
    // UiHelper.populateOptions(this.formDefinition, { items: leafCats}, 'catIds')

    this.handleNextpage(results[0])
    this.setState({
      items: fetchNext ? this.state.items.concat(results[0].items) : results[0].items
    })
  }

  // renderAboveTable() {
  //   return (
  //     <SearchBar
  //       onSearch={this.onSearch.bind(this)}
  //       onList={this.onList.bind(this)}
  //       searchText={this.state.searchText}>
  //     </SearchBar>
  //   )
  // }

  onSearch = (searchText) => {
    console.log('onSearch:', searchText)
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      searchText: searchText,
    })
    ApiHelper.call({
      method: 'get', paths: ['miscs', 'search'],
      queryParams: { orgId: this.orgId, query: searchText, allvisible: true }
    }, (err, result) => {
      if (err) {
        console.log('Search Error', err)
      }
      this.setState({
        items: result.items,
        fetchState: ApiHelper.State.READY,
        nextPage: ''
      })
    })
  }

  onList = () => {
    this.fetchItems()
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['blogs'], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['blogs', current.id], {}),
    });
  }

  openModalForAdd(fieldDef) {
    const fields = []
    this.fieldDef.steps[0].fields.forEach(i => { if (i.key === 'title') fields.push(i) })
    super.openModalForAdd({ steps: [{ fields }] })
  }

  onActions = (current) => {
    const actions = []
    if (current.state === 'GENERATING') {
      return []
    }
    if(
      Controller.get().roleHelper().canEditOrg()
      || Controller.get().userMgr().hasOrgRole(this.orgId, 'BLOGGER')
      // || (current.creatorId === Controller.get().roleHelper().getAppUser().id)
    ) {
      actions.push(UiHelper.buttonOpenBlog(this, current))
      actions.push(UiHelper.buttonOpenBlogContent(this, current))
    }
    if(Controller.get().roleHelper().canEditOrg()) {
      actions.push(UiHelper.buttonDelete(this, current))
    }
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomBlogsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})
