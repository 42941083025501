import React, { Component } from 'react';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../../common/Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';

export class ProductsByCat extends BaseEntityPage {

  constructor(props) {
    super(props)
    console.log('ProductsByCat:', this.props.match.params)
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    // this.idl1 = this.props.match.params.idl1
    // this.idl2 = this.props.match.params.idl2
    // this.catId = this.props.match.params.idl3
    this.catId = this.props.match.params.catId
    this.pageTitle = Helper.getString('products')
    this.pageKey = 'productsByCat'
    this.noAdd = true
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('categories'), to: this.baseRoute() + '/cats' },
      // { title: 'L2', to: '/admin/orgs/' + this.orgId + '/cats/' + this.idl1 },
      // { title: 'L3', to: '/admin/orgs/' + this.orgId + '/cats/' + this.idl1 + '/' + this.idl2 },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['products'], queryParams: { orgId: this.orgId, catId: this.catId, limit: 100 } },
      { method: 'GET', paths: ['cats', this.catId], queryParams: { orgId: this.orgId } },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      'Slug / ID': (current) => {
        return UiHelper.itemDetails(current)
      },
      Title: (current) => current.title,
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
    }
  }

  onFetchSuccess(results, options) {
    const { fetchNext } = options || {}
    this.handleNextpage(results[0])
    this.setState({
      items: Helper.sortDictArray(fetchNext ? this.state.items.concat(results[0].items) : results[0].items),
      item: this.state.item || results[1]
    })
    this.pageTitle = this.state.item.title
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonOpenProduct(this, current))
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})