export class JcropHelper {
  constructor() { }

  static dettachJcrop(jcropInstance) {
    if (jcropInstance) {
      jcropInstance.destroy();
    }
  }

  static loadMarkers(jcropInstance, rois, modifiedHeight, modifiedWidth, actualHeight, actualWidth) {
    for (const r of rois) {
      const rect = window.Jcrop.Rect.fromPoints(
        this.transformBottomLeftCoordsForJcrop(r, modifiedHeight, modifiedWidth, actualHeight, actualWidth),
        this.transformTopRightCoordsForJcrop(r, modifiedHeight, modifiedWidth, actualHeight, actualWidth)
      );
      jcropInstance.newWidget(rect, { id: r._id })
    }
  }

  static findROI(id, rois) {
    for (const r of rois) {
      if (r._id === id) {
        return r;
      }
    }
  }

  static getBottomLeftCoorindates(widget, modifiedHeight, modifiedWidth, actualHeight, actualWidth) {
    return [
      Math.floor(widget.pos.x * actualHeight / modifiedHeight),
      Math.floor((modifiedHeight - (widget.pos.h + widget.pos.y)) * actualWidth / modifiedWidth)
    ];
  }

  static getTopRightCoorindates(widget, modifiedHeight, modifiedWidth, actualHeight, actualWidth) {
    return [
      Math.floor(widget.pos.x2 * actualHeight / modifiedHeight),
      Math.floor((modifiedHeight - widget.pos.y) * actualWidth / modifiedWidth)]
  }

  static transformBottomLeftCoordsForJcrop(coords, modifiedHeight, modifiedWidth, actualHeight, actualWidth) {
    return [
      Math.floor(coords.bottomLeftCoords[0] * modifiedHeight / actualHeight),
      Math.floor(modifiedHeight - (coords.topRightCoords[1] * modifiedWidth / actualWidth))
    ]
  }

  static transformTopRightCoordsForJcrop(coords, modifiedHeight, modifiedWidth, actualHeight, actualWidth) {
    return [
      Math.floor(coords.topRightCoords[0] * modifiedHeight / actualHeight),
      Math.floor(modifiedHeight - (coords.bottomLeftCoords[1] * modifiedWidth / actualWidth))
    ]
  }

}
