import React from 'react';

class KeywordsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: 'relevanceAscending',
    };

    this.sortingFunctions = {
      relevanceAscending: (a, b) => a.relevance < b.relevance ? -1 : 1,
      relevanceDescending: (a, b) => a.relevance > b.relevance ? -1 : 1,
      keywordAscending: (a, b) => a.keyword < b.keyword ? -1 : 1,
      keywordDescending: (a, b) => a.keyword > b.keyword ? -1 : 1,
      competitionAscending: (a, b) => a.competition_index != null ? ((a.competition_index < b.competition_index) ? -1 : 1) : 0,
      competitionDescending: (a, b) => a.competition_index != null ? ((a.competition_index > b.competition_index) ? -1 : 1) : 0,
      searchVolumeAscending: (a, b) => a.search_volume != null ? ((a.search_volume < b.search_volume) ? -1 : 1) : 0,
      searchVolumeDescending: (a, b) => a.search_volume != null ? ((a.search_volume > b.search_volume) ? -1 : 1) : 0,
      bidRangeAscending: (a, b) => a.low_top_of_page_bid != null ? ((a.low_top_of_page_bid < b.low_top_of_page_bid) ? -1 : 1) : 0,
      bidRangeDescending: (a, b) => a.low_top_of_page_bid != null ? ((a.low_top_of_page_bid > b.low_top_of_page_bid) ? -1 : 1) : 0,
    };
  }

  onSortByClick(type, event) {
    event.preventDefault();

    const sortingRules = {
      relevance: {
        default: 'relevanceAscending',
        relevanceAscending: 'relevanceDescending',
      },
      keyword: {
        default: 'keywordAscending',
        keywordAscending: 'keywordDescending',
      },
      competition: {
        default: 'competitionDescending',
        competitionDescending: 'competitionAscending',
      },
      searchVolume: {
        default: 'searchVolumeDescending',
        searchVolumeDescending: 'searchVolumeAscending',
      },
      bidRange: {
        default: 'bidRangeAscending',
        bidRangeAscending: 'bidRangeDescending',
      },
    };
    const sortBy = sortingRules[type][this.state.sortBy] || sortingRules[type].default;
    this.setState(prevState => ({...prevState, sortBy}));

    return false;
  }

  render() {
    return <div className='flex flex-col'>
      <div className='overflow-x-auto sm:mx-0.5 lg:mx-0.5'>
        <div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
          <div className='overflow-hidden'>
            <table className='min-w-full'>
              <thead className='bg-gray-200 border-b'>
                <tr>
                  <th scope='col' className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                    <input
                      type='checkbox'
                      value=''
                      checked={this.props.isAllKeywordsSelected}
                      onChange={this.props.onSelectAllChange}
                    />
                  </th>
                  <th scope='col' className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                    <div className='flex items-center'>
                        Keyword
                      <a href='#' onClick={this.onSortByClick.bind(this, 'keyword')}><svg xmlns='http://www.w3.org/2000/svg' className='w-3 h-3 ml-1' aria-hidden='true' fill='currentColor' viewBox='0 0 320 512'><path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/></svg></a>
                    </div>
                  </th>
                  {
                    this.props.mode === 'planner' &&
                      <React.Fragment>
                        <th scope='col' className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                          <div className='flex items-center'>
                            Relevance
                            <a href='#' onClick={this.onSortByClick.bind(this, 'relevance')}><svg xmlns='http://www.w3.org/2000/svg' className='w-3 h-3 ml-1' aria-hidden='true' fill='currentColor' viewBox='0 0 320 512'><path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/></svg></a>
                          </div>
                        </th>
                        <th scope='col' className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                          <div className='flex items-center'>
                            Competition
                            <a href='#' onClick={this.onSortByClick.bind(this, 'competition')}><svg xmlns='http://www.w3.org/2000/svg' className='w-3 h-3 ml-1' aria-hidden='true' fill='currentColor' viewBox='0 0 320 512'><path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/></svg></a>
                          </div>
                        </th>
                        <th scope='col' className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                          <div className='flex items-center'>
                            Search Volume
                            <a href='#' onClick={this.onSortByClick.bind(this, 'searchVolume')}><svg xmlns='http://www.w3.org/2000/svg' className='w-3 h-3 ml-1' aria-hidden='true' fill='currentColor' viewBox='0 0 320 512'><path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/></svg></a>
                          </div>
                        </th>
                        <th scope='col' className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                          <div className='flex items-center'>
                            Bid Range (USD)
                            <a href='#' onClick={this.onSortByClick.bind(this, 'bidRange')}><svg xmlns='http://www.w3.org/2000/svg' className='w-3 h-3 ml-1' aria-hidden='true' fill='currentColor' viewBox='0 0 320 512'><path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/></svg></a>
                          </div>
                        </th>
                      </React.Fragment>
                  }
                  {
                    this.props.mode === 'plan' &&
                      <React.Fragment>
                        <th scope='col' className='text-sm font-medium text-gray-900 px-6 text-left'>
                          <button className='w-4' onClick={this.props.onDeleteKeywordClick}>
                            <img className='w-4 h-4' src='images/delete.svg' alt='Delete Selected Keywords'/>
                          </button>
                        </th>
                      </React.Fragment>
                  }
                </tr>
              </thead>
              <tbody>
                {[...this.props.keywords].filter(k => this.props.excludeKeywords ? !this.props.excludeKeywords.some(e => k.keyword.toLowerCase().split(' ').includes(e.toLowerCase())) : true ).sort(this.sortingFunctions[this.state.sortBy]).map((k, index) => <KeywordsTableRow key={index} mode={this.props.mode} keyword={k} onSelectionChange={this.props.onSelectionChange}/>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  }
}

class KeywordsTableRow extends React.Component {
  render() {
    return <tr className='bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100'>
      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
        <input
          type='checkbox'
          value=''
          checked={this.props.keyword.selected}
          onChange={this.props.onSelectionChange.bind(null, this.props.keyword)}
        />
      </td>
      <td className='text-sm text-gray-900 font-light px-6 py-4 break-normal'>
        {this.props.keyword.keyword}
      </td>
      {
        this.props.mode === 'planner' &&
          <React.Fragment>
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              {this.props.keyword.relevance}
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              {this.props.keyword.competition_index}
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              {this.props.keyword.search_volume}
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              {this.props.keyword.low_top_of_page_bid}{`${this.props.keyword.low_top_of_page_bid ? ' - ' : ''}`}{this.props.keyword.high_top_of_page_bid}
            </td>
          </React.Fragment>
      }
      {
        this.props.mode === 'plan' &&
          <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
          </td>
      }
    </tr>;
  }
}

export default KeywordsTable;
