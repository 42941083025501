/* eslint-disable */

export const dataItem = {
  homePage: {
    mainTitle: 'Supercharge your Digital',
    subTitle: 'Marketing, Content, Commerce & First-party Data',
    subTitle2: 'Slixta breaks the data silos and unifies all your digital operations',
    imgHero: require('../assets/images/hero.svg'),
  },

  stats: {
    rowData: [
      {
        img: require('../assets/images/people.svg'),
        title: 'Today’s Traffic',
        value: 245,
        color: '#BA5066'
      },
      {
        img: require('../assets/images/bag.svg'),
        title: 'New Orders',
        value: 12,
        color: '#FFAA67'
      },
    ],
    colData: [
      {
        img: require('../assets/images/diagram.svg'),
        title: 'Total Site Visits',
        value: 4250,
        subValue: '+6.45%',
        color: '#FFAA67'
      },
      {
        img: require('../assets/images/received.svg'),
        title: 'Unique Visits',
        value: 472,
        subValue: '+23.4%',
        color: '#BA5066'
      },
      {
        img: require('../assets/images/slider-horizontal.svg'),
        title: 'Banner Clicks',
        value: 50,
        subValue: '-12%',
        color: '#AFB39A'
      }
    ]
  }
}

export const imageHotspotsFormField = {
  fieldDef: {
    formType: 'add',
    steps: [
      {
        fields: [
          {
            hint: 'Provide title',
            key: 'title',
            label: 'Title',
            placement: 'left',
            type: 'edit_text',
            'v_required': {
              err: 'Enter title', //TODO
              value: true
            },
            valid: true,
            value: ''
          },
          {
            hint: 'Enter link',
            key: 'link',
            label: 'Link',
            placement: 'right',
            type: 'edit_text',
            'v_required': {
              err: 'Enter link', //TODO
              value: true
            },
            valid: true,
            value: ''
          },
          {
            hint: 'Value of x',
            key: 'x',
            label: 'X',
            placement: 'left',
            type: 'edit_text',
            'v_required': {
              err: 'Enter title', //TODO
              value: false
            },
            valid: false,
            disabled: true
          },
          {
            hint: 'Value of y',
            key: 'y',
            label: 'Y',
            placement: 'right',
            type: 'edit_text',
            'v_required': {
              err: 'Enter title', //TODO
              value: false
            },
            valid: false,
            disabled: true
          }
        ]
      }
    ]
  }
}

export const cbSource = {
  "key": "cbSource",
  "type": "radio",
  "label": "Widget Type",
  "hint": "Select Widget Type",
  "options": [
    {
      "key": "SITE",
      "label": "Site Widget (Reuse)"
    }
  ],
  "defaultValue": "SITE",
  "v_required": {
    "value": true,
    "err": "Select Widget Type"
  },
  "placement": "left"
}

export const imageSpotFields1 = [
  {
    "key": "image",
    "label": "Image",
    "hint": "Provide Image",
    "v_required": {
      "value": true,
      "err": "Image is mandatory"
    },
    "placement": "left",
    "type": "edit_text",
    "imageUpload": true,
    "mode": "fit",
    "linkName": "imageLink",
    "v_min": {
      "value": 1,
      "err": "Invalid Image"
    },
    "v_max": {
      "value": 100,
      "err": "Invalid Image"
    },
    "imageWidth": 1200,
    "imageHeight": 1200
  },
  {
    "key": "theme",
    "type": "radio",
    "label": "Theme (hotspots colour)",
    "hint": "Select theme",
    "options": [
      {
        "key": "DARK",
        "label": "DARK"
      },
      {
        "key": "LIGHT",
        "label": "LIGHT"
      }
    ],
    "v_required": {
      "value": true,
      "err": "Select theme"
    },
    "defaultValue": "DARK",
    "placement": "right"
  }
]

export const imageSpotFields2 = [
  {
    "key": "title",
    "label": "Title",
    "hint": "Provide Title",
    "v_required": {
      "value": true,
      "err": "Title is mandatory"
    },
    "placement": "left",
    "type": "edit_text",
    "v_min": {
      "value": 1,
      "err": "Title is too short (1 - 200)"
    },
    "v_max": {
      "value": 200,
      "err": "Title is too long (1 - 200)"
    }
  },
  {
    "key": "subTitle",
    "label": "Subtitle",
    "hint": "Provide SubTitle",
    "v_required": {
      "value": false,
      "err": "SubTitle is mandatory"
    },
    "placement": "right",
    "type": "edit_text",
    "v_min": {
      "value": 1,
      "err": "SubTitle is too short (1 - 200)"
    },
    "v_max": {
      "value": 200,
      "err": "SubTitle is too long (1 - 200)"
    }
  },
  {
    "key": "navLink",
    "label": "Navigation link",
    "hint": "Provide navigation link",
    "v_required": {
      "value": false,
      "err": "Navigation link is mandatory"
    },
    "placement": "left",
    "type": "edit_text",
    "v_min": {
      "value": 1,
      "err": "Navigation link is too short (1 - 200)"
    },
    "v_max": {
      "value": 200,
      "err": "Navigation link is too long (1 - 200)"
    }
  },
  {
    "key": "price",
    "label": "Price",
    "hint": "Provide price",
    "v_required": {
      "value": false,
      "err": "Price is mandatory"
    },
    "placement": "right",
    "type": "edit_text",
    "v_min": {
      "value": 1,
      "err": "SubTitle is too short (1 - 200)"
    },
    "v_max": {
      "value": 200,
      "err": "SubTitle is too long (1 - 200)"
    }
  },
  {
    "key": "priceDiscounted",
    "label": "Discounted price",
    "hint": "Provide price",
    "v_required": {
      "value": false,
      "err": "Discounted price is mandatory"
    },
    "placement": "left",
    "type": "edit_text",
    "v_min": {
      "value": 1,
      "err": "Discounted price is too short (1 - 200)"
    },
    "v_max": {
      "value": 200,
      "err": "Discounted price is too long (1 - 200)"
    }
  },
  {
    "key": "addToCartLink",
    "label": "Link to buy",
    "hint": "Provide link to buy",
    "v_required": {
      "value": false,
      "err": "Link to buy link is mandatory"
    },
    "placement": "right",
    "type": "edit_text",
    "v_min": {
      "value": 1,
      "err": "Link to buy link is too short (1 - 200)"
    },
    "v_max": {
      "value": 200,
      "err": "Link to buy link is too long (1 - 200)"
    }
  },
  {
    "key": "marketPlace1",
    "label": "Market Place Link 1",
    "hint": "Provide Market Place Link 1",
    "v_required": {
      "value": false,
      "err": "Market Place Link 1 is mandatory"
    },
    "placement": "left",
    "type": "edit_text",
    "v_min": {
      "value": 1,
      "err": "Market Place Link 1 is too short (1 - 200)"
    },
    "v_max": {
      "value": 200,
      "err": "Market Place Link 1 is too long (1 - 200)"
    }
  },
  {
    "key": "marketPlace2",
    "label": "Market Place Link 2",
    "hint": "Provide Market Place Link 2",
    "v_required": {
      "value": false,
      "err": "Market Place Link 2 is mandatory"
    },
    "placement": "right",
    "type": "edit_text",
    "v_min": {
      "value": 1,
      "err": "Market Place Link 2 is too short (1 - 200)"
    },
    "v_max": {
      "value": 200,
      "err": "Market Place Link 2 is too long (1 - 200)"
    }
  },
  {
    "key": "marketPlace3",
    "label": "Market Place Link 3",
    "hint": "Provide Market Place Link 3",
    "v_required": {
      "value": false,
      "err": "Market Place Link 3 is mandatory"
    },
    "placement": "left",
    "type": "edit_text",
    "v_min": {
      "value": 1,
      "err": "Market Place Link 3 is too short (1 - 200)"
    },
    "v_max": {
      "value": 200,
      "err": "Market Place Link 3 is too long (1 - 200)"
    }
  }
]
