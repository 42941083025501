import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';

export class CrmSequenceSteps extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('crm-sequence-steps')
    this.campaignId = this.props.match.params.id
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('crm-sequences'), to: `${this.baseRoute()}/campaigns/sequences` },
    ]

    this.toFetch = [
      { key: 'campaign', ms: this.ms, method: 'GET', paths: ['campaigns', this.campaignId], queryParams: { orgId: this.orgId, pb: 'fieldDefCampaignDripSteps', limit: 1 } },
      { key: 'templates', ms: this.ms, method: 'GET', paths: ['crmemailtemplates'], queryParams: { orgId: this.orgId, includeShared: 'true', limit: 100 } },
    ]
    this.pageKey = 'crm-sequence-steps'
    this.tableHeadersMap = {
      Step: (current) => current.__index + 1,
      Action: (current) => {
        const emailTemplate = this.emailTemplates.find(t => t.id === current.stepEmailTemplateId)
        return <div>
          <span className={css(Styles.line1)}>{current.stepAction}</span>
          <br />
          {emailTemplate && <><span className={css(Styles.line2)}>Template: {emailTemplate.title}</span><br/></>}
        </div>
      },
      Delay: (current) => {
        return <div>
          {current.stepTimeDelayDays && <span className={css(Styles.line1)}>{current.stepTimeDelayDays} days</span>}
          {current.stepTimeDelayDays && current.stepTimeDelayMs && <br />}
          {current.stepTimeDelayMs && <span className={css(Styles.line1)}>{current.stepTimeDelayMs} ms</span>}
        </div>
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.entityAttrName = 'steps';
  }

  onFetchSuccess(results) {
    this.campaign = this.getResult(results, 'campaign')
    this.steps = Object.values(this.campaign.steps || {}).sort((a, b) => a.entityCreatedTs - b.entityCreatedTs)
    this.emailTemplates = this.getResult(results, 'templates').items

    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('crm-sequences'), to: `${this.baseRoute()}/campaigns/sequences` },
      { title: this.campaign.title },
      { title: Helper.getString('crm-sequence-steps') },
    ]

    this.fieldDef = this.formDefinition = this.campaign.fieldDefCampaignDripSteps;
    UiHelper.populateOptions(this.fieldDef, {items: this.emailTemplates}, 'stepEmailTemplateId')

    this.setState({
      items: this.steps,
    })
  }

  onAdd() {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['campaigns', this.campaignId], queryParams: {orgId: this.orgId} }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['campaigns', this.campaignId], queryParams: {orgId: this.orgId} }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['campaigns', this.campaignId], queryParams: {orgId: this.orgId} }),
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []

    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))

    return actions
  }
}

const Styles = StyleSheet.create({
  line1: {
    fontSize: 16
  },
  line2: {
    fontSize: 12,
    color: 'gray'
  },
})