import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { AppTheme } from '../styles/AppTheme';

export class PageTitleContainer extends Component {
  render() {
    return (
      <div className={css(Styles.bannerContainer, CommonStyles.pagePadding)}>
        <h1 className={css(Styles.title)}>{this.props.title}</h1>
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  bannerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 50,
    overflow: 'hidden',
    backgroundColor: AppTheme.primaryColor,
    marginTop: AppTheme.toolbarHeight,
    '@media(max-width: 767px)': {
    },
  },
  title: {
    fontSize: 72,
    color: AppTheme.secondaryBg,
    fontWeight: 600,
    margin: 0,
    textAlign: 'left',
    textTransform: 'capitalize',
    '@media(max-width: 767px)': {
      fontSize: 45,
    },
  }
})