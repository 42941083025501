import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment-timezone';
import AvatarEditor from 'react-avatar-editor';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Icon } from '@material-ui/core';
import { ApiHelper } from '../helpers/ApiHelper';
import { Helper } from '../helpers/Helper';
import { UploadHelper } from '../helpers/UploadHelper';
import { AppStyles, AppTheme } from '../../styles/AppTheme';
import  { CustomMultiImageUploaderStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';

const RESIZE_DEFAULT = 1200
const IMG_RENDER_SIZE = 100
const MAX_IMAGE_LIMIT = 4
const FILE_SIZE_MB = 10000000

export class MultiImageUploader extends Component {
  constructor(props) {
    super(props);
    this.widthExpected = this.props.width || RESIZE_DEFAULT
    this.heightExpected = this.props.height || RESIZE_DEFAULT
    //Size for canvas
    this.width = this.props.imageWidth || RESIZE_DEFAULT
    this.height = this.props.imageHeight || RESIZE_DEFAULT
    this.maxImageLimit = this.props.maxImageLimit ? this.props.maxImageLimit : MAX_IMAGE_LIMIT
    this.borderRadius = (this.props.borderRadius === 0) ? 0 : RESIZE_DEFAULT / 2
    this.images = {}
    this.state = {
      message: '',
      uploadInProgress: false,
      deleteInProgress: null,
      fetchState: ApiHelper.State.READY
    };
  }

  componentDidMount() { }

  setEditorRef(editor) {
    this.editor = editor
  }

  render() {
    if (this.props.mode === 'fit' && this.selectedImgHeight && this.selectedImgWidth) {
      if (this.selectedImgWidth < this.width) {
        this.width = this.selectedImgWidth
      }
      this.height = Math.round((this.selectedImgHeight / this.selectedImgWidth) * this.width)
      if(this.height > this.heightExpected) {
        this.height = this.heightExpected
        this.width = Math.round((this.selectedImgWidth / this.selectedImgHeight) * this.height)
      }
    }
    const apiSpec = this.props.apiSpecForImageUploader
    this.images = apiSpec && apiSpec.apiData && apiSpec.apiData.images ? apiSpec.apiData.images : this.images
    const ratio = this.width / this.height
    let height = 100
    let width = height * ratio

    if (width > 200) { // too wide
      width = 200
      height = width / ratio
    }

    const imagesLength = this.images ? Object.keys(this.images).length : 0

    const EditorStyles = StyleSheet.create({
      editor: {
        backgroundColor: 'white',
        height,
        width
      },
    })

    return (
      <div className={css(Styles.outerContainer)}>
        <div className={css(Styles.content)}>

          <h2 className={css(Styles.stepFormTitle)}>{this.props.title}</h2>

          <div className={css(Styles.container)}>
            {this.images &&
              Object.keys(this.images).sort((a, b) => { return a - b }).map((key, index) => {
                return (
                  <div className={css(Styles.imageContainer)} >
                    {this.state.deleteInProgress === this.images[key].id &&
                      <CircularProgress size={24} className={css(Styles.progressIcon)} />
                    }

                    {this.state.deleteInProgress !== this.images[key].id &&
                      <img src={this.images[key].imageUrl} className={css(Styles.imageContent)} />
                    }

                    {this.state.deleteInProgress !== this.images[key].id &&
                      <div className={css(Styles.closeIconContainer)}>
                        <i onClick={() => this.deleteImage(this.images[key])} className='material-icons-outlined' style={{ fontSize: 14 }}>delete</i>
                      </div>
                    }
                  </div>
                )
              })}

            <div className={css(Styles.imgContainer)}>
              <AvatarEditor
                ref={this.setEditorRef.bind(this)}
                className={css(EditorStyles.editor)}
                image={this.state.dataURL || this.currentUrl}
                width={this.width}
                height={this.height}
                border={1}
                borderRadius={this.borderRadius}
                color={[0, 0, 0, 0.5]} // RGBA
              //scale={1}
              //rotate={0}
              />
            </div>
            {(imagesLength < this.maxImageLimit || this.maxImageLimit === undefined) &&
              <div className={css(Styles.textContainer)}>
                <input
                  id='upload-button'
                  className={css(Styles.fileInput)}
                  onChange={this.onFileChange.bind(this)}
                  type='file'
                  style={{ display: 'none' }}
                  accept={this.props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'} />
                <label htmlFor='upload-button' className={css(Styles.inputLabel)}>
                  <div className={css(Styles.attachFile)}>
                    {this.state.uploadInProgress ?
                      <CircularProgress size={24} className={css(Styles.progressIcon)} />
                      :
                      <div className={css(Styles.addImageContainer)}>
                        <i className='material-icons-outlined' style={{ fontSize: 24 }}>add</i>
                      </div>
                    }
                  </div>
                </label>
              </div>
            }
          </div>

          {this.renderMessage()}

        </div>
      </div>
    )
  }

  renderMessage() {
    let messageClass = css(Styles.textUpload, Styles.text)
    if (this.state.fetchState === ApiHelper.State.ERROR) {
      messageClass = css(Styles.textError, Styles.text)
    } else if (this.state.fetchState === ApiHelper.State.READY) {
      messageClass = css(Styles.textSuccess, Styles.text)
    }
    return (<p className={messageClass}>{this.state.message}</p>)
  }

  deleteImage = (image) => {
    this.setState({
      deleteInProgress: image.id
    })
    if (this.images[image.id]) {
      delete this.images[image.id]
    }
    this.submit()
  }

  _getFileExt = () => {
    if (this.state.file.type === 'image/png') {
      return 'png'
    } else if (this.state.file.type === 'image/svg+xml') {
      return 'svg'
    } else {
      return 'jpg'
    }
  }

  onUpload() {
    if (!this.editor) {
      return this.onError('Unable to edit the image')
    }

    if (!this.state.file) {
      return this.onError('Choose a file')
    }

    const maxSize = this.props.fileSizeLimit ? this.props.fileSizeLimit : FILE_SIZE_MB;
    if (this.state.file.size > maxSize) {
      const errMsg = maxSize / 1000000 + Helper.getString('fileSizeExceedMsg')
      return this.onError(errMsg)
    }

    this.setState(
      {
        message: '',
        fetchState: ApiHelper.State.READY
      }
    )

    const ext = this._getFileExt()
    const options = {
      uploadLocation: this.props.uploadLocation,
      type: this.props.uploadType || 'media',
      ext: this.props.uploadExt || ext // toBlob gives png by default
    }
    if (options.ext === 'svg') {
      this._fetchUploadUrl(options, this.state.file)
    } else if(this.selectedImgWidth <= this.widthExpected && this.selectedImgHeight <= this.heightExpected && (ext === this.props.uploadExt || !this.props.uploadExt)) {
      this._fetchUploadUrl(options, this.state.file)
    } else {
      let format = 'image/png' // default format
      if (options.ext === 'jpg') {
        format = 'image/jpeg'
      } else if (options.ext === 'png') {
        format = 'image/png'
      }
      const scaledCanvas = this.editor.getImageScaledToCanvas()
      scaledCanvas.toBlob((blob) => {
        this._fetchUploadUrl(options, blob)
      }, format, 0.7)
    }

    // const scaledCanvas = this.editor.getImageScaledToCanvas()
    // scaledCanvas.toBlob((blob) => {
    //   const options = {
    //     type: this.props.uploadType || 'media',
    //     ext: this.props.uploadExt || 'jpg' // toBlob gives png by default
    //   }
    //   UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo) => {
    //     if (err) {
    //       return this.onError(err)
    //     }
    //     console.log('fetchUploadUrl:', err, signedUploadInfo)
    //     const uploadOptions = {
    //       pickerResult: blob,
    //       signedUploadInfo: signedUploadInfo,
    //       onEvent: (type, percent) => {
    //         console.log('progress:', type, percent)
    //         if (type === 'progress') {
    //           return this.onUploadProgress(percent)
    //         } else if (type === 'load') {
    //           return this.onUploadComplete(signedUploadInfo)
    //         } else if (type === 'error') {
    //           return this.onError('Upload failed. You can try again')
    //         }
    //       }
    //     }
    //     UploadHelper.uploadFormDataWithProgress(uploadOptions)
    //   })
    // }, 'image/jpeg', 0.7)
  }

  matchImgDimensions = () => {
    if (this.props.mode === 'exact') {
      if (this.width !== this.selectedImgWidth || this.height !== this.selectedImgHeight) {
        const errMsg = 'Image dimension should be exactly ' + this.width + ' (w) * ' + this.height + ' (h)'
        this.onError(errMsg)
        return false
      }
    }
    return true
  }

  _fetchUploadUrl = (options, file) => {
    UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo) => {
      if (err) {
        return this.onError(err)
      }
      console.log('fetchUploadUrl:', err, signedUploadInfo)
      const uploadOptions = {
        pickerResult: file,
        signedUploadInfo: signedUploadInfo,
        onEvent: (type, percent) => {
          console.log('progress:', type, percent)
          if (type === 'progress') {
            return this.onUploadProgress(percent)
          } else if (type === 'load') {
            return this.onUploadComplete(signedUploadInfo)
          } else if (type === 'error') {
            return this.onError(Helper.getString('fileUploadErr'))
          }
        }
      }
      UploadHelper.uploadFormDataWithProgress(uploadOptions)
    })
  }

  onFileChange(e) {
    const file = e.target.files[0]
    const acceptArray = this.props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'
    if (file) {
      if (acceptArray.split(', ').indexOf(file.type) > -1) {
        this.readFile(file)
      } else {
        this.onError(Helper.getString('invalidFileType'))
      }
    }
  }

  readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image;
      img.src = reader.result;
      img.onload = function () {
        this.selectedImgWidth = img.width
        this.selectedImgHeight = img.height
        if (!this.matchImgDimensions()) return
        this.setDataUrl(file, e)
      }.bind(this);
    }
    reader.readAsDataURL(file)
  }

  // onFileChangeOld(e) {
  //   const file = e.target.files[0]
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     if (this.props.mode === 'fit') {
  //       const img = new Image;
  //       img.src = reader.result;
  //       img.onload = function () {
  //         this.selectedImgWidth = img.width
  //         this.selectedImgHeight = img.height
  //         this.setDataUrl(file, e)
  //       }.bind(this);
  //     } else {
  //       this.setDataUrl(file, e)
  //     }
  //   }

  //   reader.readAsDataURL(file)
  // }

  setDataUrl = (file, e) => {
    this.setState(
      {
        file: file,
        dataURL: e.target.result,
        message: '',
        fetchState: ApiHelper.State.READY,
        uploadInProgress: true
      }, () => {
        setTimeout(() => { //NOTE: time out was given to allow re-render of Avatar Image, immediately calling onUpload had issues
          this.onUpload()
        }, 1000)
      }
    )
  }

  onUploadProgress(percent) {
    console.log('percent:', percent);
    this.setState({
      //message: 'Uploading: ' + percent + ' %',
      fetchState: ApiHelper.State.LOADING
    })
  }

  onUploadComplete(signedUploadInfo) {
    const key = moment().valueOf()
    this.images[key] = {
      id: key,
      imageUrl: signedUploadInfo.link,
      imageName: signedUploadInfo.fields.key
    }
    this.setState({
      //message: 'Upload successful',
      fetchState: ApiHelper.State.READY
    }, () => this.submit())
  }

  onError(err) {
    console.log('err:', err);
    this.setState({
      message: Helper.getErrorMsg(err),
      uploadInProgress: false,
      deleteInProgress: null,
      fetchState: ApiHelper.State.ERROR
    })
  }

  submit() {
    const apiSpec = this.props.apiSpecForImageUploader
    if (!apiSpec || !apiSpec.apiData || !apiSpec.apiMethod || !apiSpec.apiEndPoint) {
      this.setState({
        uploadInProgress: false,
        deleteInProgress: null
      })
      return
    }

    apiSpec.apiData.images = this.images

    ApiHelper.call(
      { method: apiSpec.apiMethod, endPoint: apiSpec.apiEndPoint, jsonBody: apiSpec.apiData },
      function (err, data) {
        if (err) {
          this.onError(err)
        }
        this.setState({
          uploadInProgress: false,
          deleteInProgress: null
        })
      }.bind(this));
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomMultiImageUploaderStyles : StyleSheet.create({
  outerContainer: {
    backgroundColor: 'white',
    height: '100%',
    borderRadius: AppTheme.pagePadding,
  },
  content: {
    padding: '16px 16px 16px 16px',
  },
  stepFormTitle: {
    fontSize: 18,
    lineHeight: '33px',
    fontWeight: 'bold',
    margin: 0,
    marginBottom: 4,
  },
  container: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    borderRadius: AppTheme.borderRadius,
    marginRight: 10,
    border: '1px dashed rgba(0,0,0,.5)',
    cursor: 'pointer',
    backgroundColor: 'whitesmoke',
    height: IMG_RENDER_SIZE,
    width: IMG_RENDER_SIZE
  },
  fileInput: {
    width: '100%'
  },
  attachFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  attachFileText: {
    margin: 0,
    cursor: 'pointer'
  },
  text: {
    marginBottom: 5,
    fontSize: 12,
    marginTop: 10,
    textAlign: 'left'
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: 'red',
  },
  textSuccess: {
    color: 'green',
  },
  imageContainer: {
    marginTop: 10,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid black',
    position: 'relative',
    height: IMG_RENDER_SIZE,
    width: IMG_RENDER_SIZE
  },
  imageContent: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  closeIconContainer: {
    position: 'absolute',
    top: 2,
    right: 2,
    backgroundColor: 'white',
    display: 'flex',
    borderRadius: 4,
    padding: 1,
    cursor: 'pointer',
  },
  imgContainer: {
    display: 'none'
  },
  addImageContainer: {
    color: 'black'
  },
  inputLabel: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  }
})
