import React from 'react';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppConfig } from '../../../AppConfig';
import { Helper } from '../../../common/helpers/Helper';

function extractLinesFromText(inText) {
  return inText.split(/[\n\r]/g)
}

function blockTextToHtmlText(inText) {
  return extractLinesFromText(inText).reduce((p, c) => p + `<span>${c}</span><br/>`, '');
}

export function blockTextToReact(inText, cssStyle) {
  return extractLinesFromText(inText).map(t => <React.Fragment><span className={cssStyle}>{t}</span><br/></React.Fragment>);
}

function getPlainTextForBlog(blocks) {
  return blocks.reduce((p, block) => p + block.title + '\n' + block.text + '\n', '');
}

function getHtmlTextForBlog(blocks) {
  return blocks.reduce((p, block) => {
    if (block.type === 'section') {
      return p + `<h2>${block.title}</h2>\n<p>${blockTextToHtmlText(block.text)}</p>\n`;
    } else if (block.type === 'paragraph') {
      return p + `<p>${blockTextToHtmlText(block.text)}</p>\n`;
    }
  }, '');
}

export function copyBlogToClipboard(blocks) {
  const plainText = getPlainTextForBlog(blocks);
  const htmlText = getHtmlTextForBlog(blocks);
  const clipboardItem = new window.ClipboardItem({
    'text/plain': new window.Blob(
      [plainText],
      { type: 'text/plain' }
    ),
    'text/html': new window.Blob(
      [htmlText],
      { type: 'text/html' }
    ),
  });

  window.navigator.clipboard.write([clipboardItem]);
}

export function countWords(str) {
  return str.split(/\s/).filter(s => s !== '').length;
}

export function countBlogWords(blocks) {
  return blocks.reduce((p, block) => {
    if (block.type === 'section') {
      return p + countWords(block.title) + countWords(block.text);
    } else if (block.type === 'paragraph') {
      return p + countWords(block.text);
    }
  }, 0);
}

export function addCountField(fields) {
  fields.push({
    hint: 'Count of blog titles to generate',
    key: 'count',
    label: 'Count',
    value: 1,
    placement: 'right',
    type: 'edit_text',
    // eslint-disable-next-line camelcase
    v_required: {value: true, err: 'Count required'},
  });
}

export function removeCountField(fields) {
  const index = fields.findIndex(i => i.key === 'count');
  if (index >= 0) {
    fields.splice(index, 1);
  }
}

export function removeField(fields, fieldName) {
  const index = fields.findIndex(i => i.key === fieldName);
  if (index >= 0) {
    fields.splice(index, 1);
  }
}

export function extractNumberedListToArray(text) {
  return text ? text.split('\n')
    .filter(line => /^\d+\.\s*/.test(line)) // Filter only list items
    .map(line => line.replace(/^\d+\.\s*/, '').replace(/["]+/g, '').replace(/[.]$/g, '').trim()) : [];
}

export function extractUniqueLines(text, withQuotes = true) {
  const quotes = withQuotes ? '"' : '';
  return text.split('\n').map(t => `${quotes}${t.slice(2)}${quotes}`);
}

function extractUniqueCsvItems(data) {
  const fieldSep = ',';
  const newLine = '\n';
  const nSep = '\x1D'; const nSepRe = new RegExp(nSep, 'g');
  const qSep = '\x1E'; const qSepRe = new RegExp(qSep, 'g');
  const cSep = '\x1F'; const cSepRe = new RegExp(cSep, 'g');
  const fieldRe = new RegExp('(^|[' + fieldSep + '\\n])"([^"]*(?:""[^"]*)*)"(?=($|[' + fieldSep + '\\n]))', 'g');
  return data
    .replace(/\r/g, '')
    .replace(/\n+$/, '')
    .replace(fieldRe, (match, p1, p2) => {
      return p1 + p2.replace(/\n/g, nSep).replace(/""/g, qSep).replace(/,/g, cSep)
    })
    .split(fieldSep)
    .map(cell => cell.replace(nSepRe, newLine).replace(qSepRe, '"').replace(cSepRe, ','))
    .map(item => item.trim().replace(/"/g, ''));
}

export function csvToLineBreak(text) {
  const lines = extractUniqueCsvItems(text).map(item => `- ${item}`);
  return lines.join('\n');
}

export function lineBreakToCsv(text) {
  return extractUniqueLines(text).join(', ');
}

export async function makeRequest(ms, paths, postData, queryParams = {}, method = 'POST', updateLoaderUi = null) {
  updateLoaderUi && updateLoaderUi(true);

  const apiMethod = method;
  const apiEndPoint = ApiHelper.makeUrlPath2(
    {
      ms: ms,
      paths,
      queryParams,
    }
  );

  const apiParams = {
    method: apiMethod,
    endPoint: apiEndPoint,
  };

  if (method === 'POST') {
    apiParams.jsonBody = postData;
  }

  console.log('makeRequest: ' + JSON.stringify(apiParams));

  return ApiHelper.callAwait(apiParams).catch(err => {
    console.log('makeRequest error: ' + err);
    const errorMessage = Helper.getErrorMsg(err)
    console.log(errorMessage);
  }).finally(() => {
    updateLoaderUi && updateLoaderUi(false);
  });
}


export function addBlogToTopic(orgId, topicId, blogId, topics) {
  const endPoint = ApiHelper.makeUrlPath2({ ms: AppConfig.CONTENT_MS.ENDPOINT, paths: ['contents', orgId], queryParams: {} });
  const topic = topics.find(t => t.id.toString() === topicId);
  if (!topic) {
    return;
  }
  const blogs = topic.blogs || [];
  blogs.push(blogId);
  return ApiHelper.callAwait({
    method: 'PUT',
    endPoint,
    jsonBody: { entityAction: 'edit', entityAttrName: 'topics', id: topicId, blogs },
  })
}

export async function generateText(orgId, ms, promptSequence, replacements, updateLoaderUi = null, extraPostData = {}, onJobPoll = null, mockResponse = '') {
  const postData = {
    promptSequence,
    replacements,
    mockResponse,
    isMocked: mockResponse !== '',
    action: 'GENERATE_TEXT',
    groupId: orgId,
    ...extraPostData,
  }
  console.log('Generate text params');
  console.log(postData);
  return makeRequest(ms, ['jobs'], postData)
    .then(async jobResponse => {
      updateLoaderUi && updateLoaderUi(true);
      const jobId = jobResponse.id;
      const startTimeMs = performance.now();
      const JOB_TIMEOUT_MS = 120000;
      const POLL_INTERVAL_MS = 5000;
      const jobPollerPromise = new Promise((resolve, reject) => {
        const intervalId = setInterval(async () => {
          try {
            jobResponse = await makeRequest(ms, ['jobs', jobId], {}, { groupId: orgId }, 'GET');
            console.log('jobResponse: ' + JSON.stringify(jobResponse));
            if (jobResponse.state === 'SUCCESS') {
              clearInterval(intervalId);
              resolve(jobResponse.result);
            } else if (jobResponse.state === 'ERROR') {
              clearInterval(intervalId);
              console.log(jobResponse.result);
              reject(jobResponse.result);
            } else if (onJobPoll) {
              const isSettled = await onJobPoll(jobResponse, intervalId, resolve, reject);
              if (isSettled) {
                return;
              }
            }
            const elapsedTimeMs = performance.now() - startTimeMs;
            if (elapsedTimeMs > JOB_TIMEOUT_MS) {
              clearInterval(intervalId);
              console.log('generateText job timed out')
              reject('generateText job timed out');
            }
          } catch (err) {
            //clearInterval(intervalId);
            console.log('Caught error in job poll')
            console.log(err);
            //reject(err);
          }
        }, POLL_INTERVAL_MS);
      });
      return await jobPollerPromise;
    })
    .finally(() => {
      updateLoaderUi && updateLoaderUi(false);
    });
}

export async function chatCompletion(orgId, ms, messages, updateLoaderUi = null) {
  const postData = {
    messages,
    action: 'CHAT_COMPLETION',
    groupId: orgId,
  }
  return makeRequest(ms, ['jobs'], postData)
    .then(async jobResponse => {
      updateLoaderUi && updateLoaderUi(true);
      const jobId = jobResponse.id;
      const startTimeMs = performance.now();
      const JOB_TIMEOUT_MS = 120000;
      const POLL_INTERVAL_MS = 2000;
      const jobPollerPromise = new Promise((resolve, reject) => {
        const intervalId = setInterval(async () => {
          try {
            jobResponse = await makeRequest(ms, ['jobs', jobId], {}, { groupId: orgId }, 'GET');
            console.log('jobResponse: ' + JSON.stringify(jobResponse));
            if (jobResponse.state === 'SUCCESS') {
              clearInterval(intervalId);
              resolve(jobResponse.result);
            } else if (jobResponse.state === 'ERROR') {
              clearInterval(intervalId);
              console.log(jobResponse.result);
              reject(jobResponse.result);
            }
            const elapsedTimeMs = performance.now() - startTimeMs;
            if (elapsedTimeMs > JOB_TIMEOUT_MS) {
              clearInterval(intervalId);
              console.log('chatCompletion job timed out')
              reject('chatCompletion job timed out');
            }
          } catch (err) {
            //clearInterval(intervalId);
            console.log('Caught error in job poll')
            console.log(err);
            //reject(err);
          }
        }, POLL_INTERVAL_MS);
      });
      return await jobPollerPromise;
    })
    .finally(() => {
      updateLoaderUi && updateLoaderUi(false);
    });
}

export function bulletsToList(bulletsText, inputText) {
  const inputTextList = inputText ? bulletsToList(inputText) : [];
  let currentParentKey = '';
  return bulletsText.split('\n').filter(t => t !== '').map(t => t.trim().replace(/[:]$/g, '')).reduce((p, c) => {
    if (c.startsWith('- ')) {
      const parentKey = c.slice(2);
      currentParentKey = parentKey;
    } else if (c.startsWith('-- ')) {
      const childKey = c.slice(3);
      const text = `${currentParentKey} > ${childKey}`;
      const listItem = {
        text,
        parent: currentParentKey,
        child: childKey,
        selected: inputTextList.map(t => t.text).includes(text),
      };
      p.push(listItem);
    }
    return p;
  }, []);
}

export function bulletsToTree(bulletsText) {
  let currentList = [];
  return bulletsText.split('\n').filter(t => t !== '').map(t => t.trim().replace(/[:]$/g, '')).reduce((p, c) => {
    if (c.startsWith('- ')) {
      const parentKey = c.slice(2);
      p[parentKey] = [];
      currentList = p[parentKey];
    } else if (c.startsWith('-- ')) {
      const childKey = c.slice(3);
      currentList.push(childKey);
    }
    return p;
  }, {});
}

export function treeToBullets(tree) {
  return Object.keys(tree).reduce((p, c) => {
    let bulletsText = p + `- ${c}` + '\n';
    if (tree[c].length > 0) {
      bulletsText = bulletsText + tree[c].map(i => `-- ${i}`).join('\n') + '\n';
    }
    return bulletsText;
  }, '');
}

export function listToBullets(list) {
  let currentParent = '';
  return list.reduce((p, c) => {
    let bulletsText = p;
    if (currentParent !== c.parent) {
      currentParent = c.parent;
      bulletsText = bulletsText + `- ${c.parent}` + '\n';
    }
    bulletsText = bulletsText + `-- ${c.child}` + '\n';
    return bulletsText;
  }, '');
}