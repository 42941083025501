import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { Helper } from '../helpers/Helper';
import { ApiHelper } from '../helpers/ApiHelper';
import { BaseEntityPage } from './BaseEntityPage';
import { UiHelper } from '../helpers/UiHelper';

export class Coupons extends BaseEntityPage {

  constructor(props) {
    super(props)
    this.pageTitle = Helper.getString('coupons')
    this.pageKey = 'coupons'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.groupId = 'coupon' + this.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['items'],
        { groupId: this.groupId, pb: 'fieldDef' }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.itemId}</span>
            <br />
            <span className={css(Styles.spanValue)}>{'Expires on ' + Helper.formatDate(current.expiryTs)}</span>
          </div>
        )
      },
      Status: (current) => UiHelper.itemStatusContainer(current)
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {}
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDef
    this.setState({
      items: Helper.dictToArray(results[0].items)
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.formDefinition.steps[0].fields.find(i => i.key === 'couponCode').disabled = false
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['items'], { groupId: this.groupId }),
    });
  }

  onEdit(current) {
    this.formDefinition.steps[0].fields.find(i => i.key === 'couponCode').disabled = true
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['items', current.id], { groupId: this.groupId }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['items', current.id], { groupId: this.groupId }),
    });
  }

  onActions(current) {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }

}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})