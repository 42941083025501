import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { JourneysBase } from './JourneysBase';

export class Journeys extends JourneysBase {

  constructor(props, options) {
    super(props, {
      ...options,
      pageTitle: Helper.getString('journeys')
    });
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['journeys'], queryParams: { orgId: this.orgId, loadExternal: true, pb: 'fieldDef,fieldDefMessagingJourney', limit: 100, iType: 'MESSAGING' } },
      { ms: this.ms, method: 'GET', paths: ['csegments'], queryParams: { orgId: this.orgId, limit: 100 } },
      { method: 'GET', paths: ['emailpages'], queryParams: { orgId: this.orgId } },
      { method: 'GET', paths: ['emailpages'], queryParams: { orgId: 'PRODUCT' } },
      { ms: this.ms, method: 'GET', paths: ['items', `${this.groupId}-all`], queryParams: { groupId: this.groupId } },
    ]
    this.pageKey = 'journeys'
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Info: (current) => {
        const emailTemplate = current.emailTemplateId ? this.emailPages.find(i => i.id === current.emailTemplateId) : {}
        const emailTemplateTitle = emailTemplate ? emailTemplate.title : ''
        const testSegment = current.testSegmentId ? this.segments.find(i => i.id === current.testSegmentId) : ''
        const delayUnits = current.testDelayHours === '1' ? ' hour' : ' hours'
        const emailTest = `${testSegment ? testSegment.title : ''}${current.testOperator ? ', ' + current.testOperator : ''}${current.testDelayHours ? ', ' + current.testDelayHours + delayUnits : ''}`
        return <div>
          <span className={css(Styles.spanValue)}>{current.title}</span><br/>
          <span className={css(Styles.line2)}>Template: <strong>{emailTemplateTitle}</strong></span><br/>
          <span className={css(Styles.line2)}>Entry event: <strong>{current.entryEventName}</strong></span><br/>
          <span className={css(Styles.line2)}>Max entries: <strong>{current.maxEntries}</strong></span><br/>
          <span className={css(Styles.line2)}>Test: <strong>{emailTest}</strong></span><br/>
          <span className={css(Styles.line2)}>{current.slug || current.id}</span>
        </div>
      },
      Channel: (current) => current.channel,
      Status: (current) => current.state,
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
  }

  onFetchSuccess(results) {
    this.fieldDef = this.formDefinition = results[0].fieldDef
    this.fieldDef.steps[0].fields.push(...results[0].fieldDefMessagingJourney.steps[0].fields)
    this.fieldDef.steps[0].fields.find(f => f.key === 'iType').defaultValue = 'MESSAGING'
    this.fieldDef.disableAlwaysFormFields = ['iType']

    this.apiData = {
      orgId: this.orgId
    }

    this.segments = results[1].items.filter(i => i.state === 'LIVE')
    UiHelper.populateOptions(this.fieldDef, {items: this.segments}, 'testSegmentId')

    this.emailPages = [
      ...results[2].items,
      ...results[3].items.filter(i => i.state === 'LIVE').map(i => ({...i, title: `[${i.title}]`})),
    ]
    UiHelper.populateOptions(this.fieldDef, {items: this.emailPages}, 'emailTemplateId')

    this.crmContactAttrs = results[4]
    this.userEvents = ((this.crmContactAttrs && this.crmContactAttrs.userevents) || '').split(',').map(i => i.trim()).map(i => ({id: i, title: i}))
    UiHelper.populateOptions(this.fieldDef, {items: this.userEvents}, 'entryEventName')

    this.setState({
      items: results[0].items.sort((a, b) => b.createdTs - a.createdTs)
    })
  }

  openModalForAdd() {
    super.openModalForAdd({
      steps: [{
        fields: this.fieldDef.steps[0].fields
      }],
      disableAlwaysFormFields: ['iType'],
    });
  }
  onAdd() {
    this.apiData = {
      orgId: this.orgId,
    }
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['journeys'], queryParams: {} }),
    });
  }

  openModalForEdit(current) {
    this.apiData.updateAction = ''
    super.openModalForEdit(current, {
      steps: [{
        fields: this.fieldDef.steps[0].fields
      }],
      disableAlwaysFormFields: current.state !== 'DRAFT' ? ['iType', 'entryEventName', 'channel', 'testSegmentId', 'emailTemplateId', 'testOperator', 'testDelayHours', 'maxEntries'] : ['iType'],
    })
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 16
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
})