import ReactDOM from 'react-dom'
import React, { useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import { AppTheme } from '../../styles/AppTheme';
import { Helper } from '../../common/helpers/Helper';
import { Fragment } from 'react';
import { AppConfig } from '../../AppConfig';

const YTB_SVG = () => <svg xmlns='http://www.w3.org/2000/svg' height='20' width='30' viewBox='-35.20005 -41.33325 305.0671 247.9995'><path d='M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85' fill='red' /><path d='M93.333 117.559l61.333-34.89-61.333-34.894z' fill='#fff' /></svg>

const YoutubeEmbedComponent = (props) => {

  const [videoUrl, setVideoUrl] = useState(false)
  const [orgYtbUrl, setOrgYtbUrl] = useState(props.url || '')
  const [validUrl, setValidUrl] = useState(true)

  const videoEmbedUrlHelper = (ytLink) => {
    const videoId = Helper.getYoutubeVideoId(ytLink)
    if (!videoId) return
    return `https://www.youtube.com/embed/${videoId}`
  }

  useEffect(() => {
    if (orgYtbUrl) {
      const embedUrl = videoEmbedUrlHelper(orgYtbUrl)
      if (!embedUrl) {
        setValidUrl(false)
        return
      }
      setValidUrl(true)
      setVideoUrl(orgYtbUrl)
    }
  }, []);

  const blurChange = () => {
    const value = orgYtbUrl
    const embedUrl = videoEmbedUrlHelper(orgYtbUrl)
    if (!embedUrl) {
      setValidUrl(false)
      return
    }
    props.onBlurChange({ embedUrl, url: value })
    setValidUrl(true)
    setVideoUrl(orgYtbUrl)
  }


  return videoUrl ?
    <div className={css(Styles.renderContainer)}>
      <div className={css(Styles.messageContainer)}>
        Video <YTB_SVG />
      </div>
      <span className={css(Styles.renderUrl)}>{videoUrl}</span>
    </div> :
    <Fragment>
      <div className={css(Styles.container)}>
        <TextField
          variant={'standard'}
          className={css(Styles.inputContainer)}
          onChange={(e) => setOrgYtbUrl(e.target.value)}
          value={orgYtbUrl}
          onBlur={blurChange}
          placeholder='Enter youtube video link'
          InputProps={{
            disableUnderline: true,
            classes: {
              root: css(Styles.textInputStyles),
              input: css(Styles.formInputStyle, Styles.textInput),
            }
          }}
        />
        <div className={css(Styles.button)} onClick={blurChange}>
          ADD
        </div>
      </div>
      {!validUrl && <span className={css(Styles.errMsg)}>{Helper.getString('youtubeInvalidLink')}</span>}
    </Fragment>
}


export default class YoutubeEmbed {

  constructor({ data, api, config }) {
    // provided by EditorJS
    this.api = api
    this.config = config
    this.data = data
    this.container = undefined
    this._CSS = {
      block: this.api.styles.block,
      react: 'react-component-ytb-embed',
    }
  }

  static get toolbox() {
    return {
      title: 'Youtube Video',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="30" width="40" viewBox="-35.20005 -41.33325 305.0671 247.9995"><path d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85" fill="red"/><path d="M93.333 117.559l61.333-34.89-61.333-34.894z" fill="#fff"/></svg>'
    }
  }

  onBlurChange(data) {
    this.data = data
  }

  render() {
    if (!this.container) {
      this.container = document.createElement('div')

      ReactDOM.render(
        <YoutubeEmbedComponent
          onBlurChange={this.onBlurChange.bind(this)}
          {...this.data}
          {...this.config} />,
        this.container,
      )
    }
    return this.container
  }

  rendered() {
    console.log('YoutubeEmbed is rendered')
  }

  moved() {
    console.log('YoutubeEmbed was moved')
  }

  removed() {
    console.log('YoutubeEmbed was removed')
    ReactDOM.unmountComponentAtNode(this.container)
  }

  save(element) {
    // console.log(this.data, 'YTB_DATA');
    // if (!this.data.embedUrl) {
    //   throw new Error(Helper.getString('youtubeInvalidLink'))
    // }
    return {
      ...this.data
    }
  }

}
const Styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    gap: 10,
    margin: '5px 0px'
  },
  inputContainer: {
    width: '100%',
  },
  textInputStyles: {
    padding: '10px 15px',
    textAlign: 'left',
    color: '#222222',
    fontSize: 16,
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    fontFamily: AppConfig.GFONTNAME
  },
  iframe: {
    width: '90%',
    aspectRatio: '16/9'
  },
  button: {
    background: '#0246E5',
    padding: 5,
    fontSize: 16,
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    minWidth: 80,
    borderRadius: 16,
    userSelect: 'none',
    cursor: 'pointer'
  },
  renderContainer: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    padding: '11px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    gap: 10,
    color: '#3e4462',
    margin: '10px 0'
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  errMsg: {
    color: 'red',
    fontSize: 14
  }
})