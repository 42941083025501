import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../helpers/ApiHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../../components/BaseEntityPage';
import { AppStyles } from '../../../styles/AppTheme';
import { UiHelper } from '../../helpers/UiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { ImageUploaderWithAssetPicker } from '../../../components/ImageUploaderWithAssetPicker';

export class Banners extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('banners')
    this.pageKey = 'banners'
    this.orgId = this.props.match.params.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.groupId = 'banner' + this.orgId
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['items'], { groupId: this.groupId, pb: 'fieldDef' }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Order: (current) => current.order,
      Info: (current) => UiHelper.itemTitleImage(current),
      Link: (current) => current.link
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId,
      groupId: this.groupId
    }
    this.customFormComponents = {
      imageUpload: {
        props: { orgId: this.orgId },
        comp: ImageUploaderWithAssetPicker
      }
    }
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDef;
    this.setState({
      items: results[0].items,
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['items'], { groupId: this.groupId }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['items', current.id], { groupId: this.groupId }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['items', current.id], { groupId: this.groupId }),
    });
  }

  onActions(current) {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})