import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppStyles } from '../../../styles/AppTheme';
import { JourneysBase } from './JourneysBase';

const SEGMENTATION_FIELDS = ['popupUrlFilter', 'popupShowAfterPopups', 'popupMinPageviews', 'popupMinPageviewTimeS', 'popupTargetPlatforms', 'popupDisplayFrequency']
const TRIGGER_FIELDS = ['popupScrollTrigger', 'popupExitTrigger', 'popupTimerTrigger', 'popupInactivityTrigger', 'popupEventTrigger', 'popupClickTrigger', 'popupShowAfterS', 'popupHideAfterS', 'popupClickTriggerDefaultAction']
const STOP_CONDITION_FIELDS = ['popupStopAfterCta', 'popupStopAfterClose', 'popupMaxDisplayCount']
const POPUP_FIELDS = ['popupIframeUrl', 'popupSize', 'popupPosition', 'popupSizeCustomWidth', 'popupSizeCustomHeight', 'popupAspectRatio', 'popupPadding', 'popupIntroEffect', 'popupBackgroundMask', 'popupClickOutToClose', 'popupCloseButton', 'popupEscapeKey', 'popupBackgroundScroll', 'popupCTAQuerySelector', 'popupNextPopupOnCta', 'popupBorderStyle', 'popupTheme', 'popupSizeDesktop', 'popupSizeMobile', 'popupPositionDesktop', 'popupPositionMobile', 'popupSizeCustomWidth', 'popupSizeCustomWidthDesktop', 'popupSizeCustomHeightDesktop', 'popupSizeCustomWidthMobile', 'popupSizeCustomHeightMobile', 'popupAspectRatioDesktop', 'popupAspectRatioMobile', 'popupPaddingDesktop', 'popupPaddingMobile', 'popupIntroEffectDesktop', 'popupIntroEffectMobile', 'popupBorderStyleDesktop', 'popupBorderStyleMobile']
const NON_INFO_FIELDS = SEGMENTATION_FIELDS.concat(TRIGGER_FIELDS).concat(STOP_CONDITION_FIELDS).concat(POPUP_FIELDS)

function getDisabledFields(current, fieldDef) {
  return current.state === 'COMPLETED' ? fieldDef.steps[0].fields.map(f => f.key).filter(f => f !== 'title') : []
}

function getPopupTitle(popupId, popups) {
  const preToast = popups.find(p => p.id === popupId)
  return preToast ? preToast.title : ''
}

function getPopupTitles(popupIds, popups) {
  return Array.isArray(popupIds) ? popups.filter(p => popupIds.includes(p.id)).map(p => p.title) : []
}

export class Popups extends JourneysBase {

  constructor(props, options) {
    super(props, {
      ...options,
      pageTitle: Helper.getString('popups')
    });
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['journeys'], queryParams: { orgId: this.orgId, loadExternal: false, pb: 'fieldDef,fieldDefPopupJourney', limit: 100, iType: 'POPUP' } },
      { ms: this.ms, method: 'GET', paths: ['items', `${this.groupId}-all`], queryParams: { groupId: this.groupId } },
    ]
    this.pageKey = 'popups'
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Info: (current) => {
        return <div>
          <span className={css(Styles.spanValue)}>{current.title}</span><br/>
          <span className={css(Styles.line2)}>{current.slug || current.id}</span>
        </div>
      },
      /*Segmentation: (current) => {
        const showAfterPopups = getPopupTitles(current.popupShowAfterPopups, this.popups).join(', ')
        return <div>
          {current.popupUrlFilter && <><span className={css(Styles.line2)}>URL filter: <strong>{current.popupUrlFilter}</strong></span><br/></>}
          {showAfterPopups && <><span className={css(Styles.line2)}>Show after popups: <strong>{showAfterPopups}</strong></span><br/></>}
          {current.popupMinPageviews && <><span className={css(Styles.line2)}>Min Pageviews: <strong>{current.popupMinPageviews}</strong></span><br/></>}
          {current.popupMinPageviewTimeS && <><span className={css(Styles.line2)}>Min Pageview Time: <strong>{current.popupMinPageviewTimeS + 's'}</strong></span><br/></>}
          {current.popupTargetPlatforms && <><span className={css(Styles.line2)}>Target platforms: <strong>{current.popupTargetPlatforms.join(', ')}</strong></span><br/></>}
          <span className={css(Styles.line2)}>Display Frequency: <strong>{current.popupDisplayFrequency}</strong></span><br/>
        </div>
      },
      Triggers: (current) => {
        return <div>
          {current.popupScrollTrigger !== 'DISABLED' && <><span className={css(Styles.line2)}>Scroll: <strong>{current.popupScrollTrigger + '%'}</strong></span><br/></>}
          {current.popupExitTrigger !== 'DISABLED' && <><span className={css(Styles.line2)}>Exit: <strong>{current.popupExitTrigger}</strong></span><br/></>}
          {current.popupTimerTrigger && <><span className={css(Styles.line2)}>Timer: <strong>{current.popupTimerTrigger + 's'}</strong></span><br/></>}
          {current.popupInactivityTrigger && <><span className={css(Styles.line2)}>Inactivity: <strong>{current.popupInactivityTrigger + 's'}</strong></span><br/></>}
          {current.popupEventTrigger && <><span className={css(Styles.line2)}>Event: <strong>{current.popupEventTrigger}</strong></span><br/></>}
          {current.popupClickTrigger && <><span className={css(Styles.line2)}>Click: <strong>{current.popupClickTrigger}</strong></span><br/></>}
          {current.popupShowAfterS && <><span className={css(Styles.line2)}>Trigger delay: <strong>{current.popupShowAfterS ? current.popupShowAfterS + 's' : 'DISABLED'}</strong></span><br/></>}
          {current.popupHideAfterS && <><span className={css(Styles.line2)}>Auto hide: <strong>{current.popupHideAfterS ? current.popupHideAfterS + 's' : 'DISABLED'}</strong></span><br/></>}
          {current.popupClickTriggerDefaultAction && <><span className={css(Styles.line2)}>Click default action: <strong>{current.popupClickTriggerDefaultAction}</strong></span><br/></>}
        </div>
      },
      'Stop Conditions': (current) => {
        return <div>
          <span className={css(Styles.line2)}>Stop after CTA: <strong>{current.popupStopAfterCta}</strong></span><br/>
          {current.popupStopAfterClose && <><span className={css(Styles.line2)}>Stop after Close: <strong>{current.popupStopAfterClose}</strong></span><br/></>}
          {current.popupMaxDisplayCount && <><span className={css(Styles.line2)}>Max Display Count: <strong>{current.popupMaxDisplayCount}</strong></span><br/></>}
        </div>
      },
      Popup: (current) => {
        const popupNextPopupOnCtaTitle = getPopupTitle(current.popupNextPopupOnCta, this.popups)
        return <div>
          {current.popupIframeUrl && <><span className={css(Styles.line2)}>Iframe URL: <strong>{current.popupIframeUrl}</strong></span><br/></>}
          {current.popupSize && <><span className={css(Styles.line2)}>Size: <strong>{current.popupSize}</strong></span><br/></>}
          {current.popupPosition && <><span className={css(Styles.line2)}>Position: <strong>{current.popupPosition}</strong></span><br/></>}
          {current.popupAspectRatio && <><span className={css(Styles.line2)}>Aspect Ratio: <strong>{current.popupAspectRatio}</strong></span><br/></>}
          {current.popupBackgroundMask && <><span className={css(Styles.line2)}>Background mask: <strong>{current.popupBackgroundMask}</strong></span><br/></>}
          {current.popupClickOutToClose && <><span className={css(Styles.line2)}>Click out to close: <strong>{current.popupClickOutToClose}</strong></span><br/></>}
          {current.popupCloseButton && <><span className={css(Styles.line2)}>Close button: <strong>{current.popupCloseButton}</strong></span><br/></>}
          {current.popupEscapeKey && <><span className={css(Styles.line2)}>Escape key: <strong>{current.popupEscapeKey}</strong></span><br/></>}
          {current.popupBackgroundScroll && <><span className={css(Styles.line2)}>Background scroll: <strong>{current.popupBackgroundScroll}</strong></span><br/></>}
          {current.popupCTAQuerySelector && <><span className={css(Styles.line2)}>CTA query selector: <strong>{current.popupCTAQuerySelector}</strong></span><br/></>}
          {popupNextPopupOnCtaTitle && <><span className={css(Styles.line2)}>Next Popup on CTA: <strong>{popupNextPopupOnCtaTitle}</strong></span><br/></>}
          {current.popupBorderStyle && <><span className={css(Styles.line2)}>Border Style: <strong>{current.popupBorderStyle}</strong></span><br/></>}
          {current.popupTheme && <><span className={css(Styles.line2)}>Theme: <strong>{current.popupTheme}</strong></span><br/></>}
        </div>
      },
      */
      Status: (current) => current.state,
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
  }

  onFetchSuccess(results) {
    this.fieldDef = this.formDefinition = results[0].fieldDef
    this.fieldDef.steps[0].fields.push(...results[0].fieldDefPopupJourney.steps[0].fields)
    this.fieldDef.steps[0].fields.find(f => f.key === 'iType').defaultValue = 'POPUP'
    this.fieldDef.disableAlwaysFormFields = ['iType']
    this.apiData = {
      orgId: this.orgId
    }

    this.popups = results[0].items
    UiHelper.populateOptions(this.fieldDef, {items: this.popups}, 'popupNextPopupOnCta')
    UiHelper.populateOptions(this.fieldDef, {items: this.popups}, 'popupShowAfterPopups')

    this.crmContactAttrs = results[1]
    this.userEvents = ((this.crmContactAttrs && this.crmContactAttrs.userevents) || '').split(',').map(i => i.trim()).map(i => ({id: i, title: i}))
    UiHelper.populateOptions(this.fieldDef, {items: this.userEvents}, 'popupEventTrigger')

    this.setState({
      items: results[0].items.sort((a, b) => b.createdTs - a.createdTs)
    })
  }

  openModalForAdd() {
    super.openModalForAdd({
      steps: [{ fields: this.fieldDef.steps[0].fields.filter(f => !NON_INFO_FIELDS.includes(f.key)) }],
      disableAlwaysFormFields: ['iType'],
    });
  }
  onAdd() {
    this.apiData = {
      orgId: this.orgId,
      ...NON_INFO_FIELDS.reduce((p, c) => {
        const field = this.fieldDef.steps[0].fields.find(f => f.key === c)
        if (field && field.v_required && field.v_required.value === true && field.defaultValue) {
          p[c] = field.defaultValue
        }
        return p
      }, {})
    }
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['journeys'], queryParams: {} }),
    });
  }
  openModalForEdit(current) {
    this.apiData = {
      orgId: this.orgId
    }
    super.openModalForEdit(current, {
      steps: [{ fields: this.fieldDef.steps[0].fields.filter(f => !NON_INFO_FIELDS.includes(f.key)) }],
      disableAlwaysFormFields: getDisabledFields(current, this.fieldDef),
    });
  }
  openModalForFields(current, modalFields) {
    this.apiData = {
      orgId: this.orgId
    }
    super.openModalForEdit(current, {
      steps: [{ fields: this.fieldDef.steps[0].fields.filter(f => modalFields.includes(f.key)).map((f, i) => ({...f, placement: i % 2 === 0 ? 'left' : 'right'})) }],
      disableAlwaysFormFields: getDisabledFields(current, this.fieldDef),
    });
  }
  onActions(current) {
    const actions = super.onActions(current)
    const popupActions = []
    popupActions.push(
      <Tooltip title='Edit Segmentation' placement='top-start' key={current.id + 'edit-segment'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => this.openModalForFields(current, SEGMENTATION_FIELDS)}>segment</i>
        </span>
      </Tooltip>
    )
    popupActions.push(
      <Tooltip title='Edit Triggers' placement='top-start' key={current.id + 'edit-triggers'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => this.openModalForFields(current, TRIGGER_FIELDS)}>bolt</i>
        </span>
      </Tooltip>
    )
    popupActions.push(
      <Tooltip title='Edit Stop Conditions' placement='top-start' key={current.id + 'edit-stop-conditions'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => this.openModalForFields(current, STOP_CONDITION_FIELDS)}>front_hand</i>
        </span>
      </Tooltip>
    )
    popupActions.push(
      <Tooltip title='Edit Popup' placement='top-start' key={current.id + 'edit-popup'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => this.openModalForFields(current, POPUP_FIELDS)}>mms</i>
        </span>
      </Tooltip>
    )
    popupActions.push(
      <Tooltip title='Create a copy' placement='top-start' key={current.id + 'create-copy'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => UiHelper.openModalForCreateCopy.call(this, current, 'JOURNEY')}>copy</i>
        </span>
      </Tooltip>
    )
    if (current.state !== 'DRAFT') {
      popupActions.push(
        <Link to={this.baseRoute() + '/campaigns/popups/' + current.id + '/results'} key={current.id + '-results'}>
          <Tooltip title='Results' placement='top-start'>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined'>trending_up</i>
            </span>
          </Tooltip>
        </Link>
      )
    }
    actions.splice(1, 0, ...popupActions);
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 16
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
})