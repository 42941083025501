import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppStyles } from '../../styles/AppTheme';
import Dialog from '@material-ui/core/Dialog';
import { MultiImageUploader } from './MultiImageUploader';
import  { CustomMultiImageUploaderDialogStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';

export class MultiImageUploaderDialog extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  getFormfield = () => {
    if (!this.props.formDefinition ||
      !this.props.formDefinition.steps ||
      !this.props.formDefinition.steps[0].fields) {
      return {}
    }
    for (const field of this.props.formDefinition.steps[0].fields) {
      if (field.type === 'edit_text' && field.imageUpload) {//retained for backward compatibility
        return field
      }
      if (field.subType === 'image') {
        return field
      }
    }
    return {}
  }

  render() {
    const formfield = this.getFormfield()
    return (
      <Dialog
        aria-labelledby={'image-uploader'}
        fullWidth={true}
        fullScreen={window.innerWidth && window.innerWidth < 480 ? true : false}
        open={this.props.imageModelOpen}
        maxWidth='sm'
        classes={{
          root: css(Styles.root),
          paper: css(Styles.paper),
        }}
      >
        <div className={css([Styles.modalHeader, AppStyles.modalHeader])}>
          <div className={css(Styles.modalTitle)}>{this.props.title}</div>
          <button className={css(Styles.modalCloseButton)} onClick={this.props.closeImageUploader}>
            <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
              <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
            </svg>
          </button>
        </div>

        <div className={css(Styles.modalContent)}>
          <MultiImageUploader
            apiSpecForImageUploader={this.props.apiSpecForImageUploader}
            uploadLocation={this.props.uploadLocation}
            onUploadSuccess={this.props.handleUploadSuccess}
            {...formfield}
          />
        </div>

      </Dialog>
    )
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomMultiImageUploaderDialogStyles : StyleSheet.create({
  modalContent: {
    paddingLeft: 10,
    paddingRight: 10
  },
  modalHeader: {
    backgroundColor: 'whitesmoke',
    padding: 10
  },
  modalTitle: {
    color: 'black',
    fontSize: 20,
  },
  modalCloseButton: {
    position: 'absolute',
    top: 15,
    right: 5,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalCloseIcon: {
    color: 'grey',
    width: 18,
    height: 18
  }
})
