/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
export const StringTableBlog = [
  {
    "key": "blogtags",
    "en": "Blog Tags"
  },
  {
    "key": "bloglists",
    "en": "Blog Collections"
  },
  {
    "key": "blogs",
    "en": "Blogs"
  },
  {
    "key": "blog",
    "en": "Blog"
  }
]
